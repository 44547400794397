import {useRef, useState} from 'react'
import {selectTheme} from '../../store'
import {TooltipIcon} from '../../asset/image'
import {EmotionJSX} from '@emotion/react/types/jsx-namespace'

export const Tooltip = (props: {
  content: string | EmotionJSX.Element
  tooltipWidth?: string
  position?: string
  pseudoPosition?: string
  textAlign?: 'start' | 'end' | 'center'
  css?: {[key: string]: string | number}
  translate?: string
}) => {
  const {color} = selectTheme()
  const {tooltipWidth, position, pseudoPosition, content, textAlign, translate} = props
  const [displayTooltip, setDisplayTooltip] = useState(false)
  const [tooltipSize, setTooltipSize] = useState({
    width: 0,
    height: 0,
  })
  const [tooltipPosition, setTooltipPosition] = useState({
    x: 0,
    y: 0,
  })
  const containerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)

  const handleMouseEnter = () => {
    const cRef = containerRef.current
    if (cRef) {
      const {left, top} = cRef.getBoundingClientRect()
      setTooltipPosition({
        x: left + window.scrollX,
        y: top + window.scrollY,
      })
    }
    const tRef = tooltipRef.current
    if (tRef) {
      const {width, height} = tRef.getBoundingClientRect()
      setTooltipSize({width, height})
    }
    setDisplayTooltip(true)
  }

  return (
    <div
      ref={containerRef}
      onMouseOver={handleMouseEnter}
      onMouseOut={() => setDisplayTooltip(false)}
      css={{
        position: 'relative',
        width: '14px',
        minWidth: '14px',
        height: '14px',
        minHeight: '14px',
        background: color.white,
        cursor: 'pointer',
      }}
    >
      <img width={14} src={TooltipIcon} />
      <div
        ref={tooltipRef}
        css={{
          // display: displayTooltip ? 'block' : 'none',
          pointerEvents: displayTooltip ? 'auto' : 'none',
          position: 'fixed',
          top: tooltipPosition.y - tooltipSize.height - 10,
          left: tooltipPosition.x - tooltipSize.width / 2 + 7,
          minWidth: '220px',
          width: tooltipWidth ?? '220px',
          background: color.surface.dark,
          borderRadius: '5px',
          color: color.white,
          padding: '8px 12px',
          textAlign: textAlign ?? 'center',
          mixBlendMode: 'normal',
          zIndex: '999',
          opacity: displayTooltip ? 1 : 0,
          transition: 'opacity .5s ease',
          boxShadow: '0px 4px 10px 1px #68686840',
          transform: `translate(${translate ?? '0'}, 0)`,
          ':after': {
            position: 'absolute',
            top: position ? 'auto' : '100%',
            bottom: position ? '100%' : 'auto',
            left: pseudoPosition ? pseudoPosition : translate ? `calc(50% - ${translate})` : '50%',
            transform: 'translateX(-50%)',
            content: '""',
            borderTop: position === 'up' ? 'none' : '10px solid black',
            borderBottom: position === 'up' ? '10px solid black' : 'none',
            borderRight: '5px solid transparent',
            borderLeft: '5px solid transparent',
          },
        }}
      >
        {content}
      </div>
    </div>
  )
}
