import {cloneElement, Children, ReactElement} from 'react'
import {v4 as uuid} from 'uuid'
import {Label, RequiredTag, InputWarning} from '.'
import {RIF} from '../../lib'
import {selectTheme} from '../../store'

export interface LabelBoxProps {
  label: string
  id?: string
  warningMsg?: string
  leftCharacters?: number
  required?: boolean
  children: ReactElement
}

export const LabelBox = (props: LabelBoxProps) => {
  const {color} = selectTheme()
  const {leftCharacters, required, warningMsg, label} = props
  const id = props.id || uuid()
  const children = cloneElement(Children.only(props.children), {id})
  return (
    <div>
      <div css={{display: 'flex', alignItems: 'center', paddingBottom: '8px', justifyContent: 'space-between'}}>
        <Label htmlFor={id} css={{display: 'flex', alignItems: 'center', marginBottom: '0px'}}>
          <span css={{padding: '4px 0'}}>{label}</span>
          {RIF(required, <RequiredTag />)}
          {RIF(warningMsg, <InputWarning message={warningMsg} />)}
        </Label>
        {RIF(
          leftCharacters || leftCharacters === 0,
          <div css={{fontSize: '12px', color: color.grey_600}}>{leftCharacters} characters remaining</div>,
        )}
      </div>
      {children}
    </div>
  )
}
