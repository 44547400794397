import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {IParticipant, ITag, ProjectCollaboratorRole, WorkspaceCollaboratorRole} from '../../shared/db'
import {createDispatchActions, selectAdherenceData, selectTheme} from '../../store'
import {collaboratorTypeHasRole, RIF, select, useCollaboratorTypeInProject, useCurrentProjectState} from '../../lib'
import {
  CheckAllIcon,
  CheckboxDefaultIcon,
  CheckSuccessIcon,
  NotificationDefaultIcon,
  RefreshIcon,
  TagIcon,
  WarningIcon,
} from '../../asset/image'
import {
  Checkbox,
  NotificationButton,
  PopupDeleteParticipant,
  ParticipantPopup,
  PopupRegenerateInviteCode,
  ParticipantTagsContainer,
  PopupAddTag,
  PopupConfirmDeleteTag,
  PopupEditTag,
  Tooltip,
} from '..'
import {debounce} from 'lodash'
import {isAfter} from 'date-fns'

export enum ParticipantPopupOperationType {
  EditInsignia = 'editInsignia',
  EditNote = 'editNote',
  Delete = 'delete',
  RegenerateInviteCode = 'regenerateInviteCode',
}

export const ParticipantTable = (props: {
  selectedList: IParticipant[]
  setSelectedList: Dispatch<SetStateAction<IParticipant[]>>
  displayParticipantList: IParticipant[]
  setDisplayPopupSendNotification: Dispatch<SetStateAction<boolean>>
  projectTagList: ITag[]
}) => {
  const {projectId} = useCurrentProjectState()
  const {color} = selectTheme()
  const {selectedList, setSelectedList, displayParticipantList, setDisplayPopupSendNotification, projectTagList} = props
  const collaboratorType = useCollaboratorTypeInProject()
  const canUpdateParticipant = collaboratorTypeHasRole({
    collaboratorType: collaboratorType,
    projectRole: ProjectCollaboratorRole.ParticipantUpdate,
    workspaceRole: WorkspaceCollaboratorRole.ParticipantUpdate,
  })

  // the index of hovered entry
  const [hover, setHover] = useState<number | null>(null)

  // state that are used to determine which popup should be shown
  const [targetParticipant, setTargetParticipant] = useState<IParticipant | null>(null)
  const [operationType, setOperationType] = useState<ParticipantPopupOperationType | null>(null)
  const [regenerateCode, setRegenerateCode] = useState<Record<string, string>>({})

  // state that are used to determine whether to show the box shadow of the scrollbar
  const [showLeftBoxShadowOfScrollbar, setShowLeftBoxShadowOfScrollbar] = useState(false)
  const [showRightBoxShadowOfScrollbar, setShowRightBoxShadowOfScrollbar] = useState(true)
  const scrollRef = useRef<HTMLDivElement>(null)

  // state that are use to determine the width of the tag column
  const [tagColumnWidth, setTagColumnWidth] = useState(144)
  const getTagWidth = (txt: string) => {
    const el = document.createElement('canvas')
    const ctx = el.getContext('2d')
    if (ctx) {
      ctx.font = '10px DM Sans, sans-serif'
      const textWidth = ctx.measureText(txt).width
      const paddingWidth = 12
      const maxWidthOfTag = 100
      return Math.min(maxWidthOfTag, textWidth + paddingWidth)
    }
    return 0
  }
  useEffect(() => {
    if (!displayParticipantList.length) return
    const tagWidthTable = Object.fromEntries(
      projectTagList.map((t) => {
        return [t.id, getTagWidth(t.value)]
      }),
    )
    displayParticipantList.forEach((p) => {
      if (p.tagList) {
        const tagColumnPaddingWidth = 24
        const tagGapWidth = 4
        let width = tagColumnPaddingWidth + (p.tagList.length - 1) * tagGapWidth
        p.tagList?.forEach((t) => {
          width += tagWidthTable[t.id]
        })
        setTagColumnWidth((prev) => Math.min(400, Math.max(prev, width)))
      }
    })
  }, [displayParticipantList])
  return (
    <div
      style={{
        display: 'flex',
        marginTop: '20px',
        marginBottom: '20px',
        background: color.white,
        borderRadius: '5px 5px 0px 0px',
        border: `1px solid ${color.grey_100}`,
      }}
    >
      {RIF(
        operationType === ParticipantPopupOperationType.EditInsignia,
        <ParticipantPopup
          {...{
            closeAction: () => setOperationType(null),
            method: 'Edit',
            projectId,
            participant: targetParticipant,
            setOperationType,
          }}
        />,
      )}
      {RIF(
        operationType === ParticipantPopupOperationType.Delete,
        <PopupDeleteParticipant
          {...{
            setOperationType,
            participant: targetParticipant,
          }}
        />,
      )}
      {RIF(
        operationType === ParticipantPopupOperationType.EditNote,
        <ParticipantPopup
          {...{
            closeAction: () => setOperationType(null),
            method: 'Edit',
            projectId,
            participant: targetParticipant,
            setOperationType,
          }}
        />,
      )}
      {RIF(
        operationType === ParticipantPopupOperationType.RegenerateInviteCode,
        <PopupRegenerateInviteCode
          {...{
            setOperationType,
            participant: targetParticipant,
            setRegenerateCode,
          }}
        />,
      )}
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* header */}
        <ParticipantTableFixedPartEntry
          {...{
            index: 0,
            hover,
            setHover,
            selectedList,
            setSelectedList,
            canUpdateParticipant,
            setOperationType,
            setTargetParticipant,
            displayParticipantList,
          }}
        />
        {/* content */}
        {displayParticipantList.map((participant, index) => (
          <ParticipantTableFixedPartEntry
            {...{
              index: index + 1,
              hover,
              setHover,
              selectedList,
              setSelectedList,
              canUpdateParticipant,
              setOperationType,
              setTargetParticipant,
              displayParticipantList,
              participant,
              setDisplayPopupSendNotification,
            }}
          />
        ))}
      </div>
      <div
        ref={scrollRef}
        onScroll={(e) => {
          const target = e.target as HTMLDivElement
          setShowLeftBoxShadowOfScrollbar(target.scrollLeft > 0)
          setShowRightBoxShadowOfScrollbar(target.scrollLeft < target.scrollWidth - target.clientWidth)
        }}
        css={{
          flex: 1,
          overflowX: 'auto',
          overflowY: 'visible',
          position: 'relative',
          display: 'inline-flex',
          flexDirection: 'column',
          boxShadow: `${showLeftBoxShadowOfScrollbar ? 'inset 10px 0 10px -5px rgba(0, 0, 0, 0.1), ' : ''}${
            showRightBoxShadowOfScrollbar ? 'inset -10px 0 10px -5px rgba(0, 0, 0, 0.1)' : ''
          }`,
        }}
      >
        {/* header */}
        <ParticipantTableScrollablePartEntry
          {...{
            index: 0,
            hover,
            setHover,
            canUpdateParticipant,
            displayParticipantList,
            tagColumnWidth,
            projectTagList,
            setOperationType,
            setTargetParticipant,
            regenerateCode,
          }}
        />
        {/* content */}
        {displayParticipantList.map((participant, index) => (
          <ParticipantTableScrollablePartEntry
            {...{
              index: index + 1,
              hover,
              setHover,
              canUpdateParticipant,
              displayParticipantList,
              tagColumnWidth,
              participant,
              projectTagList,
              setOperationType,
              setTargetParticipant,
              regenerateCode,
            }}
          />
        ))}
      </div>
    </div>
  )
}

const ParticipantTableScrollablePartEntry = (props: {
  index: number
  hover: number | null
  setHover: Dispatch<SetStateAction<number | null>>
  canUpdateParticipant: boolean
  displayParticipantList: IParticipant[]
  tagColumnWidth: number
  participant?: IParticipant
  projectId?: string
  projectTagList: ITag[]
  setOperationType: Dispatch<SetStateAction<ParticipantPopupOperationType | null>>
  setTargetParticipant: Dispatch<SetStateAction<IParticipant | null>>
  regenerateCode: Record<string, string>
}) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const {
    index,
    hover,
    setHover,
    canUpdateParticipant,
    displayParticipantList,
    tagColumnWidth,
    participant,
    projectTagList,
    setOperationType,
    setTargetParticipant,
    regenerateCode,
  } = props
  const {doREQUEST_PROJECT_TAG_DELETE}: any = createDispatchActions()
  const isTheLastRow = index === 0 ? displayParticipantList.length === 0 : index === displayParticipantList.length

  // Tag
  const tagRef = useRef<HTMLDivElement>(null)
  const addTagPopupRef = useRef<HTMLDivElement>(null)
  const editTagPopupRef = useRef<HTMLDivElement>(null)
  const [renderAddTagPopup, setRenderAddTagPopup] = useState(false)
  const [renderDeleteTagPopup, setRenderDeleteTagPopup] = useState(false)
  const [editingTagId, setEditingTagId] = useState<string | null>(null)
  const [addTagPopupPosition, setAddTagPopupPosition] = useState({
    x: 0,
    y: 0,
  })
  const [editTagPopupPosition, setEditTagPopupPosition] = useState({
    x: 0,
    y: 0,
  })
  const handleRenderTagPopup = () => {
    const tRef = tagRef.current
    if (tRef) {
      const {left, top} = tRef.getBoundingClientRect()
      setEditTagPopupPosition({
        x: left + 232,
        y: top,
      })
      setAddTagPopupPosition({
        x: left,
        y: top,
      })
    }
    setRenderAddTagPopup(true)
  }

  const handleClickDeleteTag = debounce(
    () => {
      const selectedTag = projectTagList?.find(({id}) => id === editingTagId)
      if (selectedTag) {
        const {projectId, id} = selectedTag
        doREQUEST_PROJECT_TAG_DELETE({
          payload: {
            projectId,
            tagIds: [id],
          },
        })
        setRenderDeleteTagPopup(false)
        setEditingTagId(null)
      }
    },
    1000,
    {leading: true, trailing: false},
  )

  // adjust the position of the add tag popup so that it won't go out of the screen
  useEffect(() => {
    const aRef = addTagPopupRef.current
    if (aRef && renderAddTagPopup) {
      const height = aRef.clientHeight
      if (height + addTagPopupPosition.y > window.innerHeight) {
        setAddTagPopupPosition((prev) => ({
          ...prev,
          y: window.innerHeight - height - 20,
        }))
      }
    }
  }, [renderAddTagPopup])

  // adjust the position of the edit tag popup so that it won't go out of the screen
  useEffect(() => {
    const eRef = editTagPopupRef.current
    if (eRef && editingTagId) {
      const height = eRef.clientHeight
      if (height + editTagPopupPosition.y > window.innerHeight) {
        setEditTagPopupPosition((prev) => ({
          ...prev,
          y: window.innerHeight - height - 20,
        }))
      }
    }
  }, [editingTagId])

  // adjust popup position when scrolling
  useEffect(() => {
    const handleScroll = () => {
      const tRef = tagRef.current
      const aRef = addTagPopupRef.current
      if (tRef && aRef) {
        const height = aRef.clientHeight
        const {top} = tRef.getBoundingClientRect()
        if (height + top > window.innerHeight) {
          setAddTagPopupPosition((prev) => ({
            ...prev,
            y: window.innerHeight - height - 20,
          }))
        } else {
          setAddTagPopupPosition((prev) => ({
            ...prev,
            y: top,
          }))
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const adherence = participant && selectAdherenceData()?.[participant?.projectId]
  const adherenceParticipant = adherence?.adherenceList?.find(
    (item: {participantId: string}) => item.participantId === participant?.id,
  )
  const participantDeviceType = adherenceParticipant?.status?.deviceType
  const participantDeviceFirmware = adherenceParticipant?.status?.deviceFirmware
  const participantMobileType = adherenceParticipant?.status?.mobileType
  const participantAppVersion = adherenceParticipant?.status?.appVersion

  // invite code
  const credentials = participant?.credentials
  const loginCode = credentials?.loginCode
  const loginCodeExpired = credentials && isAfter(new Date(), new Date(credentials?.expiresUnixTimestamp))

  const handleEditNote = () => {
    if (canUpdateParticipant && participant) {
      setTargetParticipant(participant)
      setOperationType(ParticipantPopupOperationType.EditNote)
    }
  }

  return (
    <>
      {RIF(
        renderDeleteTagPopup,
        <PopupConfirmDeleteTag onClose={() => setRenderDeleteTagPopup(false)} onDelete={handleClickDeleteTag} />,
      )}
      <div
        onMouseEnter={() => setHover(index)}
        onMouseLeave={() => setHover(null)}
        css={{
          display: 'inline-flex',
          flexWrap: 'nowrap',
          minWidth: '1360px',
          height: '44px',
          borderBottom: isTheLastRow ? 'none' : `1px solid ${color.grey_100}`,
          background: hover === index ? ' #9C9EAE0D' : 'transparent',
        }}
      >
        {/* Tag */}
        <div
          ref={tagRef}
          css={{
            display: 'flex',
            minWidth: tagColumnWidth,
            width: tagColumnWidth,
            borderRight: `1px solid ${color.border.light}`,
            position: 'relative',
            transition: 'min-width 0.5s ease-in-out',
          }}
        >
          {RIF(
            index === 0,
            <div
              css={{
                height: '100%',
                padding: '0 12px',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                position: 'absolute',
              }}
            >
              <span>Tag</span>
              <Tooltip content='You can attach tags to participants for grouping' />
            </div>,
          )}
          {RIF(
            index > 0 && participant?.tagList?.length,
            <ParticipantTagsContainer tags={participant?.tagList ?? []} onClick={handleRenderTagPopup} />,
          )}
          {RIF(
            index > 0 && !participant?.tagList?.length,
            <div
              onClick={handleRenderTagPopup}
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                height: '100%',
                padding: '0 12px',
              }}
            >
              <img src={TagIcon} height='16' />
              <span css={{color: color.textIcon.secondary, fontSize: fontSize.h7, fontWeight: fontWeight.thick}}>
                Add tag
              </span>
            </div>,
          )}
          {RIF(
            renderAddTagPopup,
            <div
              ref={addTagPopupRef}
              css={{
                zIndex: 99,
                position: 'fixed',
                top: addTagPopupPosition.y,
                left: addTagPopupPosition.x,
              }}
            >
              <PopupAddTag
                {...{
                  participant,
                  projectTagList: projectTagList ?? [],
                  isEditingTag: !!editingTagId,
                  onClose: setRenderAddTagPopup,
                  onEditTag: ({id}) => {
                    if (editingTagId !== id) {
                      setEditingTagId(id)
                    } else {
                      setEditingTagId(null)
                    }
                  },
                  style: {
                    width: '232px',
                    height: 'auto',
                  },
                }}
              />
            </div>,
          )}
          {RIF(
            editingTagId,
            <div
              ref={editTagPopupRef}
              css={{
                zIndex: 99,
                position: 'fixed',
                top: editTagPopupPosition.y,
                left: editTagPopupPosition.x,
              }}
            >
              <PopupEditTag
                key={editingTagId}
                {...{
                  tag: projectTagList?.find(({id}) => id === editingTagId) ?? null,
                  onClose: () => setEditingTagId(null),
                  onDeleteTag: () => {
                    setRenderDeleteTagPopup(true)
                  },
                }}
              />
            </div>,
          )}
        </div>
        {/* Activate Status */}
        <div
          css={{
            width: '104px',
            minWidth: '104px',
            borderRight: `1px solid ${color.border.light}`,
            display: 'flex',
            gap: index === 0 ? '4px' : '8px',
            alignItems: 'center',
            padding: '0 12px',
            // backgroundColor: color.surface.default,
            backgroundColor: 'transparent',
          }}
        >
          {RIF(
            index === 0,
            <>
              <span>Status</span>
              <Tooltip content='Participants have joined when they have entered their invite code.' />
            </>,
          )}
          {RIF(
            index > 0,
            participant?.activated ? (
              <>
                <img src={CheckSuccessIcon} height='16' />
                <span css={{color: color.textIcon.success, fontWeight: fontWeight.bold, fontSize: fontSize.h7}}>
                  Joined
                </span>
              </>
            ) : (
              <span css={{color: color.textIcon.secondary, fontSize: fontSize.h7}}>Not joined yet</span>
            ),
          )}
        </div>
        {/* Invite Code */}
        <div
          css={{
            width: '200px',
            minWidth: '200px',
            borderRight: `1px solid ${color.border.light}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: index > 0 ? 'space-between' : 'flex-start',
            gap: '4px',
            padding: '0 12px',
            backgroundColor: 'transparent',
            // backgroundColor: color.surface.default,
            position: 'relative',
          }}
        >
          {RIF(
            index === 0,
            <>
              <span>Invite Code</span>
              <Tooltip content='Participants will enter code on their app to join study' />
            </>,
          )}
          {RIF(
            index > 0,
            <>
              {/* if had regenerateCode from pop then show it */}
              {participant?.id && regenerateCode[participant.id] ? (
                <span
                  css={{
                    fontWeight: fontWeight.thick,
                  }}
                >
                  {regenerateCode[participant.id]}
                </span>
              ) : loginCodeExpired ? (
                <span css={{color: color.textIcon.secondary}}>Expired</span>
              ) : !participant?.credentials?.loginCode ? (
                <span css={{color: color.textIcon.secondary}}>Used</span>
              ) : (
                <span
                  css={{
                    fontWeight: fontWeight.thick,
                  }}
                >
                  {loginCode}
                </span>
              )}
              {RIF(
                canUpdateParticipant,
                <button
                  onClick={() => {
                    if (participant) {
                      setTargetParticipant(participant)
                      setOperationType(ParticipantPopupOperationType.RegenerateInviteCode)
                    }
                  }}
                  css={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: 'none',
                    backgroundColor: 'transparent',
                  }}
                >
                  <img src={RefreshIcon} height='16' />
                  <span css={{color: color.textIcon.link, fontWeight: fontWeight.thick, fontSize: fontSize.h7}}>
                    Regenerate
                  </span>
                </button>,
              )}
            </>,
          )}
        </div>
        {/* Notification Status */}
        <div
          css={{
            width: '120px',
            minWidth: '120px',
            borderRight: `1px solid ${color.border.light}`,
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            padding: '0 12px',
          }}
        >
          {RIF(
            index === 0,
            <>
              <span>Notification</span>
              <Tooltip content='This section shows the notification settings on the participant’s phone. If notifications are turned off, they won’t receive alerts on their phone. However, they can still view the notification messages by opening the Labfront app.' />
            </>,
          )}
          {RIF(
            index > 0,
            participant?.pushDeviceId ? (
              <span css={{color: color.textIcon.secondary}}>ON</span>
            ) : (
              <>
                <img src={WarningIcon} width={16} />
                <span css={{color: color.textIcon.secondary}}>OFF</span>
              </>
            ),
          )}
        </div>
        {/* Mobile Type */}
        <div
          css={{
            width: '104px',
            minWidth: '104px',
            borderRight: `1px solid ${color.border.light}`,
            display: 'flex',
            alignItems: 'center',
            padding: '0 12px',
          }}
        >
          {index === 0 ? (
            'Mobile Type'
          ) : (
            <span css={{color: color.textIcon.secondary, wordBreak: 'break-word'}}>
              {participantMobileType ? participantMobileType.toUpperCase() : '--'}
            </span>
          )}
        </div>
        {/* App Version */}
        <div
          css={{
            width: '144px',
            minWidth: '144px',
            borderRight: `1px solid ${color.border.light}`,
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            padding: '0 12px',
          }}
        >
          {index === 0 ? (
            <>
              <span>App Version</span>
              <Tooltip
                content={
                  <p css={{color: color.white}}>
                    To check the latest version of iOS, go&nbsp;
                    <a
                      target='_blank'
                      css={{color: color.textIcon.link, textDecoration: 'underline'}}
                      href='https://apps.apple.com/us/app/labfront-companion/id1626813782'
                    >
                      here
                    </a>
                    &nbsp;(Check the What's New section where it has version history). To check the latest version of
                    Android, go&nbsp;
                    <a
                      target='_blank'
                      css={{color: color.textIcon.link, textDecoration: 'underline'}}
                      href='https://play.google.com/store/apps/details?id=com.labfront.production'
                    >
                      here
                    </a>
                    &nbsp;(Check the About this App section).
                  </p>
                }
              />
            </>
          ) : (
            <span css={{color: color.textIcon.secondary, wordBreak: 'break-word'}}>
              {participantAppVersion ? participantAppVersion : '--'}
            </span>
          )}
        </div>
        {/* Device Type */}
        <div
          css={{
            width: '144px',
            minWidth: '144px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 12px',
            borderRight: `1px solid ${color.border.light}`,
          }}
        >
          {index === 0 ? (
            <p>Device Type</p>
          ) : (
            <span css={{color: color.textIcon.secondary, wordBreak: 'break-word'}}>
              {participantDeviceType ? `${participantDeviceType} (${participantDeviceFirmware ?? '--'})` : '--'}
            </span>
          )}
        </div>
        {/* Note */}
        <button
          onClick={handleEditNote}
          css={{
            minWidth: '144px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 12px',
            border: 'none',
            backgroundColor: 'transparent',
          }}
        >
          {index === 0 ? (
            <p>Note</p>
          ) : participant?.note ? (
            <p
              css={{
                fontWeight: fontWeight.bold,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {participant?.note}
            </p>
          ) : (
            <span
              css={{
                color: color.textIcon.link,
                fontWeight: fontWeight.thick,
              }}
            >
              Add Note
            </span>
          )}
        </button>
      </div>
    </>
  )
}

const ParticipantTableFixedPartEntry = (props: {
  index: number
  hover: number | null
  setHover: Dispatch<SetStateAction<number | null>>
  selectedList: IParticipant[]
  setSelectedList: Dispatch<SetStateAction<IParticipant[]>>
  canUpdateParticipant: boolean
  displayParticipantList: IParticipant[]
  participant?: IParticipant
  setOperationType: Dispatch<SetStateAction<ParticipantPopupOperationType | null>>
  setTargetParticipant: Dispatch<SetStateAction<IParticipant | null>>
  setDisplayPopupSendNotification?: Dispatch<SetStateAction<boolean>>
}) => {
  const {color} = selectTheme()
  const {
    index,
    hover,
    setHover,
    selectedList,
    setSelectedList,
    canUpdateParticipant,
    displayParticipantList,
    participant,
    setOperationType,
    setTargetParticipant,
    setDisplayPopupSendNotification,
  } = props

  const isTheLastRow = index === 0 ? displayParticipantList.length === 0 : index === displayParticipantList.length

  const allSelected = selectedList.length === displayParticipantList.length
  const handleSelectAll = () => {
    if (selectedList.length === displayParticipantList.length) {
      setSelectedList([])
    } else {
      setSelectedList(displayParticipantList)
    }
  }
  const isSelected = selectedList.some((item) => item.id === participant?.id)
  const handleSelectParticipant = () => {
    if (isSelected) {
      setSelectedList(selectedList.filter((item) => item.id !== participant?.id))
    } else {
      setSelectedList([...selectedList, participant as IParticipant])
    }
  }

  const handleEditInsignia = () => {
    if (canUpdateParticipant && participant) {
      setTargetParticipant(participant)
      setOperationType(ParticipantPopupOperationType.EditInsignia)
    }
  }

  return (
    <div
      onMouseEnter={() => setHover(index)}
      onMouseLeave={() => setHover(null)}
      css={{
        display: 'flex',
        minHeight: '44px',
        borderBottom: isTheLastRow ? 'none' : `1px solid ${color.grey_100}`,
        background: hover === index ? ' #9C9EAE0D' : 'transparent',
      }}
    >
      {/* Index */}
      <div
        css={{
          width: '36px',
          borderRight: `2px solid ${color.border.light}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {index > 0 ? `${index < 9 ? 0 : ''}${index}` : '#'}
      </div>
      {/* Checkbox */}
      <div
        css={{
          width: '36px',
          borderRight: `1px solid ${color.border.light}`,
          display: canUpdateParticipant ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {RIF(
          index === 0,
          <button
            css={{
              width: '16px',
              height: '16px',
              cursor: 'pointer',
              border: 'none',
              backgroundColor: 'transparent',
              backgroundImage: `url(${allSelected ? CheckAllIcon : CheckboxDefaultIcon})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            onClick={handleSelectAll}
          />,
        )}
        {RIF(
          index > 0,
          <Checkbox name={participant?.insignia} onChange={handleSelectParticipant} value={isSelected} />,
        )}
      </div>
      {/* Insignia  */}
      <button
        onClick={handleEditInsignia}
        css={{
          cursor: index > 0 ? 'pointer' : 'default',
          display: 'flex',
          alignItems: 'center',
          width: '144px',
          padding: '0 12px',
          border: 'none',
          borderRight: `1px solid ${color.border.light}`,
          backgroundColor: 'transparent',
        }}
      >
        <span
          css={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'left',
          }}
        >
          {index === 0 ? (
            'ID'
          ) : participant?.insignia ? (
            participant?.insignia
          ) : (
            <span css={{color: color.textIcon.secondary}}>--</span>
          )}
        </span>
      </button>
      {/* Notification Button */}
      <div
        css={{
          width: '40px',
          borderRight: `1px solid ${color.border.light}`,
          display: canUpdateParticipant ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {RIF(index === 0, <img src={NotificationDefaultIcon} width='20' />)}
        {RIF(
          index > 0,
          participant?.activated ? (
            <NotificationButton
              enable={!!participant?.pushDeviceId}
              onClick={() => {
                setSelectedList([participant])
                if (setDisplayPopupSendNotification) setDisplayPopupSendNotification(true)
              }}
            />
          ) : (
            <span css={{color: color.textIcon.secondary}}>--</span>
          ),
        )}
      </div>
    </div>
  )
}
