import {useState, MutableRefObject, useEffect} from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {RIF, t, _} from '../../lib'
import {selectTheme, selectQuestionnaires, createDispatchActions} from '../../store'
import {DragHandle, ButtonText, QuestionnaireInput, QuestionnaireInputSize, QuestionnaireTextarea} from '..'

import {Questionnaire, Section, ContentListItemCategory} from '../../model'

interface QuestionnaireSectionProps {
  key: string
  id: string
  index: number
  isLast: boolean
  ref: MutableRefObject<undefined>
}

export const QuestionnaireSection = (props: QuestionnaireSectionProps) => {
  const {pad, color, fontWeight} = selectTheme()
  const {id, index, isLast} = props
  const {
    doQUESTIONNAIRES_SET,
    doQUESTIONNAIRES_EDITING_STATUS_SET,
    doQUESTIONNAIRES_QUESTION_SET,
    doQUESTIONNAIRES_SECTION_SET,
    doQUESTIONNAIRES_SECTION_DELETE,
  }: any = createDispatchActions()
  const questionnairesState = selectQuestionnaires()
  const {editingQuestionnaireId, editingBlock, viewOnly} = questionnairesState.editing
  const [hovering, setHovering] = useState(false)
  const [editingQuestionnaire, setEditingQuestionnaire] = useState<Questionnaire | undefined>()

  useEffect(() => {
    if (!questionnairesState) return
    const findEditingQuestionnaireResult = _.find(questionnairesState.questionnaireList, ['id', editingQuestionnaireId])
    if (_.isEqual(findEditingQuestionnaireResult, editingQuestionnaire)) return
    setEditingQuestionnaire(findEditingQuestionnaireResult)
  }, [questionnairesState.questionnaireList])

  useEffect(() => {
    if (!editingQuestionnaire) return
    const findEditingQuestionnaireResult = _.find(questionnairesState.questionnaireList, ['id', editingQuestionnaireId])
    if (_.isEqual(findEditingQuestionnaireResult, editingQuestionnaire)) return
    doQUESTIONNAIRES_SET(editingQuestionnaire)
  }, [editingQuestionnaire])

  const [editingSection, setEditingSection] = useState<Section | undefined>()
  useEffect(() => {
    if (!id) return
    const findSectionResult = _.find(questionnairesState.sectionList, ['id', id])
    if (!findSectionResult) return
    setEditingSection(findSectionResult)
  }, [id])

  useEffect(() => {
    if (!editingSection) return
    doQUESTIONNAIRES_SECTION_SET(editingSection)
  }, [editingSection])

  const generateNewQuestion = () => {
    return {
      sectionId: id,
      id: t.uuid(),
      label: t.uuid(),
      description: 'New Question',
      type: 'multi_select',
      required: false,
      inputConfig: {
        options: [{name: 'New Option'}],
      },
    }
  }

  const generateNewSection = () => {
    return {
      questionnaireId: editingQuestionnaireId || undefined,
      id: t.uuid(),
      legend: 'New Section Title',
      description: 'Description goes here',
      questionList: [],
    }
  }

  const addSection = () => {
    const newSection = generateNewSection()
    const editingQuestionnaireCopy = JSON.parse(JSON.stringify(editingQuestionnaire))
    editingQuestionnaireCopy.sectionList.push(newSection.id)
    editingQuestionnaireCopy.contentList.splice(index + 1, 0, {id: newSection.id, category: 'section'})
    setEditingQuestionnaire(editingQuestionnaireCopy)
    doQUESTIONNAIRES_SECTION_SET(newSection)
    doQUESTIONNAIRES_EDITING_STATUS_SET({editingBlock: newSection.id})
  }

  const duplicateSection = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    // duplicate the section
    const editingSectionCopy = JSON.parse(JSON.stringify(editingSection))
    editingSectionCopy.id = t.uuid()

    const editingQuestionnaireCopy = JSON.parse(JSON.stringify(editingQuestionnaire))
    editingQuestionnaireCopy.sectionList.push(editingSectionCopy.id)
    editingQuestionnaireCopy.contentList.splice(index + 1, 0, {id: editingSectionCopy.id, category: 'section'})
    setEditingQuestionnaire(editingQuestionnaireCopy)
    doQUESTIONNAIRES_SECTION_SET(editingSectionCopy)
    doQUESTIONNAIRES_EDITING_STATUS_SET({editingBlock: editingSectionCopy.id})
  }

  const deleteSection = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const editingQuestionnaireCopy = JSON.parse(JSON.stringify(editingQuestionnaire))
    if (index !== 0 && editingQuestionnaireCopy.contentList.length > 1) {
      editingQuestionnaireCopy.contentList.splice(index, 1)
      setEditingQuestionnaire(editingQuestionnaireCopy)
      doQUESTIONNAIRES_SECTION_DELETE({id})
    }
  }

  const addQuestion = (itemId: string) => {
    const newQuestion = generateNewQuestion()
    const editingQuestionnaireCopy = JSON.parse(JSON.stringify(editingQuestionnaire))
    const index = 1 + _.findIndex(editingQuestionnaireCopy.contentList, ['id', itemId])
    editingQuestionnaireCopy.contentList.splice(index, 0, {
      id: newQuestion.id,
      category: ContentListItemCategory.Question,
      sectionId: id,
    })
    setEditingQuestionnaire(editingQuestionnaireCopy)
    doQUESTIONNAIRES_QUESTION_SET(newQuestion)
    doQUESTIONNAIRES_EDITING_STATUS_SET({editingBlock: newQuestion.id})
  }

  const handleChangeOfSectionLegend = (value: string) => {
    const editingSectionCopy = JSON.parse(JSON.stringify(editingSection))
    editingSectionCopy.legend = value
    setEditingSection(editingSectionCopy)
  }

  const handleChangeOfSectionDescription = (value: string) => {
    const editingSectionCopy = JSON.parse(JSON.stringify(editingSection))
    editingSectionCopy.description = value
    setEditingSection(editingSectionCopy)
  }

  const handleClick = () => {
    if (editingBlock !== id && !viewOnly) doQUESTIONNAIRES_EDITING_STATUS_SET({editingBlock: id})
  }

  const handleMouseEnter = () => {
    setHovering(true)
  }

  const handleMouseLeave = () => {
    setHovering(false)
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} css={{marginBottom: pad.large}}>
            {RIF(
              index,
              <div
                css={{
                  width: '57.4rem',
                  marginBottom: pad.slightlyLarger,
                  marginTop: '38px',
                  borderTop: `1px solid ${color.grey_400}`,
                }}
              />,
            )}
            <div
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              css={{
                width: '57.4rem',
                borderRadius: '5px',
                border: editingBlock === id ? `1px solid ${color.primary}` : 'none',
                padding: `${pad.large} ${pad.xl}`,
                background: color.white,
                position: 'relative',
                boxShadow: '0px 4px 12px rgba(213, 213, 213, 0.25)',
                cursor: viewOnly ? 'auto' : 'pointer',
                marginBottom: pad.mediumSmall,
                ':hover': {
                  background: viewOnly ? color.white : color.grey_20,
                },
              }}
            >
              {RIF(
                !viewOnly,
                <DragHandle
                  {...provided.dragHandleProps}
                  isDragging={snapshot.isDragging}
                  isShowing={editingBlock === id || hovering || snapshot.isDragging}
                  css={{
                    position: 'absolute',
                    top: '0',
                    left: '-24px',
                  }}
                />,
              )}
              {RIF(
                editingBlock !== id,
                <div>
                  <div css={{fontWeight: fontWeight.bold, marginBottom: '10px'}}>{editingSection?.legend}</div>
                  <div>{editingSection?.description}</div>
                </div>,
              )}
              {RIF(
                editingBlock === id,
                <div>
                  <div
                    css={{
                      marginBottom: pad.mediumSmall,
                    }}
                  >
                    <QuestionnaireInput
                      size={QuestionnaireInputSize.big}
                      placeholder='New Section Title'
                      initialValue='New Section Title'
                      onChangeAction={handleChangeOfSectionLegend}
                      defaultValue={editingSection?.legend}
                      maxLength={50}
                    />
                  </div>
                  <QuestionnaireTextarea
                    defaultValue={editingSection?.description}
                    onChangeAction={handleChangeOfSectionDescription}
                    placeholder='Description goes here'
                    initialValue='Description goes here'
                    maxLength={250}
                    rows={4}
                  />
                  <div
                    css={{
                      height: '55px',
                      display: 'flex',
                      alignItems: 'center',
                      borderTop: `1px solid ${color.grey_160}`,
                      marginBottom: `-${pad.large}`,
                    }}
                  >
                    <ButtonText onClick={duplicateSection} children='Duplicate' />
                    {RIF(
                      editingQuestionnaire?.sectionList && editingQuestionnaire.sectionList.length > 1,
                      <ButtonText
                        onClick={deleteSection}
                        css={{marginLeft: '30px'}}
                        children={<span css={{color: color.warning}}>Delete</span>}
                      />,
                    )}
                  </div>
                </div>,
              )}
            </div>
            {RIF(
              editingBlock === id || (!editingBlock && isLast && !viewOnly),
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ButtonText onClick={() => addQuestion(id)} css={{marginRight: pad.xl}} children='+New Question' />
                <ButtonText onClick={addSection} children='+New Section' />
              </div>,
            )}
          </div>
        )
      }}
    </Draggable>
  )
}
