import {selectTheme} from '../../../store/state/theme'
import {defaultSvgProps, SvgProps} from '../type/props'

export const ChevronDownIcon = (props?: SvgProps) => {
  const {color} = selectTheme()
  const {width, height, fill, flexShrink} = defaultSvgProps(props, {
    width: 16,
    height: 16,
    fill: color.textIcon.secondary,
    flexShrink: 0,
  })

  return (
    <svg
      width={width}
      height={height}
      style={{flexShrink}}
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.77003 3.82716L1.47143 0.528564L0.528625 1.47137L4.77003 5.71278L9.01143 1.47137L8.06863 0.528564L4.77003 3.82716Z'
        fill={fill}
      />
    </svg>
  )
}
