import {useState, useEffect} from 'react'
import {Controller} from 'react-hook-form'
import {selectTheme, selectWorkspaces} from '../../store'
import {v, RIF, useForm, useCurrentWorkspaceState} from '../../lib'
import {InstructionIcon} from '../../asset/image'
import {Textarea, LabelBox} from '..'

const schema = v.object({
  participantInstructions: v.string().max(250).optional(),
})

export const InstructionBlock = (props: any) => {
  const {pad, color, fontWeight, fontSize} = selectTheme()
  const workspacesState = selectWorkspaces()
  const {workspaceId} = useCurrentWorkspaceState()
  const role = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'

  const {setInstructionValueFilled, instructionValue, setInstructionValue, isSummaryReview} = props

  const [instructionWordCount, setInstructionWordCount] = useState(0)
  const wordCountMaximum: any = {
    instruction: 250,
  }
  const handleWordCount = (e: any, type: string, wordCountSetter: any) => {
    e.target.value = e.target.value.slice(0, wordCountMaximum[type])
    wordCountSetter(e.target.value.length)
  }

  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({schema})

  useEffect(() => {
    if (watch('participantInstructions') !== undefined) {
      setInstructionValue(watch('participantInstructions'))
    }
    if (instructionValue) setInstructionValueFilled(true)
    if (instructionValue === '') setInstructionValueFilled(false)
    setInstructionWordCount(instructionValue?.length)
  }, [watch('participantInstructions'), instructionValue])

  return (
    <div
      css={{
        display: 'flex',
        background: color.white,
        borderRadius: '5px;',
        width: '55vw',
        boxShadow: '0px 4px 12px 0px #D4D4D440',
        marginBottom: pad.large,
      }}
    >
      {/* left */}
      <div
        css={{
          padding: pad.large,
          borderRight: `1px solid ${color.grey_100}`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '26%',
        }}
      >
        <div>
          <div
            css={{
              width: '40px',
              height: '40px',
              background: color.background,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: pad.large,
            }}
          >
            <img src={InstructionIcon} width='24px' />
          </div>
          <p
            css={{
              fontSize: '18px',
              fontWeight: fontWeight.thick,
              marginBottom: pad.medium,
            }}
          >
            Instructions
          </p>
          {RIF(
            !isSummaryReview,
            <p css={{lineHeight: '19px', fontWeight: fontWeight.regular}}>
              Give instructions for onboarding participants on how to get started and important things to remember.
            </p>,
          )}
        </div>

        {/* {RIF(!isSummaryReview,
          <div css={{
            width: '100%',
            height: '149px',
            background: color.background,
            borderRadius: '5px',
          }}></div>)} */}
      </div>

      {/* right */}
      <div css={{padding: pad.xl, width: '74%'}}>
        {RIF(
          !isSummaryReview,
          <p css={{fontSize: '14px', fontWeight: fontWeight.medium, marginBottom: pad.xl}}>
            Information for onboarding participants:
          </p>,
        )}

        {RIF(
          !isSummaryReview,
          <LabelBox
            {...{
              label: 'Instructions',
              required: true,
              leftCharacters: wordCountMaximum.instruction - instructionWordCount,
            }}
          >
            <Controller
              control={control}
              name='participantInstructions'
              render={({field: {name, value, onChange}}) => (
                <Textarea
                  {...{
                    name: name,
                    value: instructionValue,
                    onChange: onChange,
                    onInput: (e) => handleWordCount(e, 'instruction', setInstructionWordCount),
                  }}
                  maxLength={250}
                  rows={4}
                  readOnly={role === 'viewer'}
                  placeholder='Please write instructions for onboarding participants'
                />
              )}
            />
          </LabelBox>,
        )}

        {RIF(isSummaryReview, <div css={{margin: pad.medium}}>{instructionValue}</div>)}

        {RIF(
          !isSummaryReview,
          <>
            <p css={{fontSize: fontSize.h7, color: color.grey_400, marginBottom: pad.widthPadding, marginTop: pad.xl}}>
              Example 1: Please read all of the provided documents thoroughly and wear your device at all times.
            </p>
            <p css={{fontSize: fontSize.h7, color: color.grey_400}}>
              Example 2: Please remember to charge your device and sync it regularly by opening the app.
            </p>
          </>,
        )}
      </div>
    </div>
  )
}
