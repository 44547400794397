import {Nullish} from 'utility-types'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {Dispatch, MouseEventHandler, SetStateAction, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  createDispatchActions,
  selectMethod,
  selectProjectData,
  selectTempTaskData,
  selectTheme,
  useSelectSettings,
} from '../../store'
import {CalendarBlueIcon, ParticipantTaskIcon, PlusBlueIcon} from '../../asset/image'
import {collaboratorTypeHasRole, RIF, sortBy, t, useCollaboratorTypeInProject, isInBetaType, darken} from '../../lib'
import {
  EditingTask,
  getTaskContentName,
  TaskContentName,
  TaskStateType,
  TaskTempStateType,
  TaskType,
  ProjectScheduleSettingType,
  ProjectScheduleConfigHelper,
} from '../../model'
import {
  AddTaskPage,
  ButtonReverse,
  DropdownMenu,
  EditProjectSchedulePopup,
  HiddenTaskTag,
  PopupConfirmDeleteTask,
  PricingPage,
  Step,
  TaskTag,
} from '..'
import {BetaType, IProject, ProjectCollaboratorRole, WorkspaceCollaboratorRole} from '../../shared/db'

export interface ParticipantTaskBlockProps {
  setTasksFilled: Dispatch<SetStateAction<boolean>>
}

interface ParticipantTaskDropdownItem {
  taskType: TaskContentName
  text: string
}

interface ParticipantTaskDropdownProps {
  items: ParticipantTaskDropdownItem[]
  text: string
  dropped: boolean
  onClickItem: (item: ParticipantTaskDropdownItem) => void
  onMouseOver: MouseEventHandler<HTMLDivElement>
  onMouseOut: MouseEventHandler<HTMLDivElement>
}

const ParticipantTaskDropdown = (props: ParticipantTaskDropdownProps) => {
  const {text: buttonText, items, onMouseOver, onMouseOut, dropped, onClickItem} = props
  const {color, pad} = selectTheme()
  return (
    <div
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      css={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <ButtonReverse
        btnPadding='medium'
        children={
          <span
            css={{
              display: 'flex',
              alignItems: 'center',
              color: color.primary,
            }}
          >
            <img
              alt='Add Task'
              src={PlusBlueIcon}
              width='18'
              css={{
                marginRight: pad.xs,
              }}
            />
            {buttonText}
          </span>
        }
        css={{marginRight: pad.small, marginBottom: '10px'}}
      />
      <DropdownMenu
        dropped={dropped}
        parentCSS={{
          marginTop: '40px',
        }}
        options={items.map((item) => ({
          text: item.text,
          onClick: () => onClickItem(item),
        }))}
      />
    </div>
  )
}

export const ParticipantTaskBlock = (props: ParticipantTaskBlockProps) => {
  const {pad, color, fontWeight, fontSize} = selectTheme()

  const {
    doMETHOD_SET,
    doREQUEST_TASK_DELETE,
    doTEMP_TASK_DATA_UPDATE_TASK,
    doTEMP_TASK_DATA_DELETE_TASK,
    doTEMP_TASK_DATA_TASK_LIST_UPDATE,
    doREQUEST_METHOD_TASK_INDEX_ORDER_UPDATE,
  }: any = createDispatchActions()

  const {setTasksFilled} = props

  const settings = useSelectSettings()
  const isInternalUser = isInBetaType({betaType: BetaType.Internal, settings})

  const {projectId} = useParams()
  const project = selectProjectData()[projectId as string] as IProject | Nullish
  const scheduleConfig = project?.scheduleConfig
  const methodState = selectMethod()
  const tempTaskData = selectTempTaskData()
  const isEditingLiveProject = project?.status === 'live'

  const taskList: TaskStateType[] =
    methodState?.taskList
      ?.filter((item: TaskStateType) => item.type !== TaskType.GarminDevice && item.enabled)
      .sort(sortBy('index')) ?? []
  const hiddenTaskList: TaskStateType[] =
    methodState?.taskList
      ?.filter((item: TaskStateType) => item.type !== TaskType.GarminDevice && !item.enabled)
      .sort(sortBy('index')) ?? []
  const tempTaskList: TaskTempStateType[] =
    tempTaskData?.taskList?.filter((item: TaskTempStateType) => item.type !== TaskType.GarminDevice) ?? []

  const hasTask = taskList.length > 0 || tempTaskList.length > 0 || hiddenTaskList.length > 0

  const [hasScheduleEdits, setHasScheduleEdits] = useState(false)

  const isLegacy = ((hasTask || isEditingLiveProject || hasScheduleEdits) && !scheduleConfig) || !isInternalUser

  const projectScheduleSettingType = isLegacy
    ? ProjectScheduleSettingType.Legacy
    : scheduleConfig
    ? ProjectScheduleConfigHelper.parseType(scheduleConfig)
    : null

  const collaboratorType = useCollaboratorTypeInProject()

  const hasProjectUpdateRole = collaboratorTypeHasRole({
    collaboratorType,
    workspaceRole: WorkspaceCollaboratorRole.ProjectUpdate,
    projectRole: ProjectCollaboratorRole.ProjectUpdate,
  })
  const canAddProjectSchedule =
    !isEditingLiveProject && !hasTask && hasProjectUpdateRole && projectScheduleSettingType === null
  const canEditProjectSchedule = !isEditingLiveProject && !hasTask && hasProjectUpdateRole

  const hasTaskCreateRole = collaboratorTypeHasRole({
    collaboratorType,
    workspaceRole: WorkspaceCollaboratorRole.TaskCreate,
    projectRole: ProjectCollaboratorRole.TaskCreate,
  })
  const canAddScheduleTask = hasTaskCreateRole && projectScheduleSettingType === ProjectScheduleSettingType.Schedule
  const canAddEventTrackerTask =
    hasTaskCreateRole && projectScheduleSettingType === ProjectScheduleSettingType.EventTracker
  const canAddLegacyTask = hasTaskCreateRole && projectScheduleSettingType === ProjectScheduleSettingType.Legacy

  const movesenseDeviceEnable = methodState?.movesenseDeviceEnable || false
  const tempMovesenseDeviceEnable = tempTaskData?.movesenseDeviceEnable || false
  const garminStreamEnable = methodState?.garminStreamEnable || false
  const tempGarminStreamEnable = tempTaskData?.garminStreamEnable || false

  const [isAddingTask, setIsAddingTask] = useState(false)
  const [removingTaskId, setRemovingTaskId] = useState('')
  const [removingTaskName, setRemovingTaskName] = useState('')
  const [showHiddenTask, setShowHiddenTask] = useState(false)
  const [displayChangePlanPage, setDisplayChangePlanPage] = useState(false)
  const [editingTask, setEditingTask] = useState<EditingTask>({})
  const [taskType, setTaskType] = useState<TaskContentName>('todo')
  const [displayEditProjectSchedulePopUp, setDisplayEditProjectSchedulePopUp] = useState(false)
  const [showScheduleTaskDropdown, setShowScheduleTaskDropdown] = useState(false)
  const [showEventTrackerTaskDropdown, setShowEventTrackerTaskDropdown] = useState(false)
  const [showLegacyTaskDropdown, setShowLegacyTaskDropdown] = useState(false)

  const createTaskDropdownItems = (): ParticipantTaskDropdownItem[] => {
    const items: ParticipantTaskDropdownItem[] = [
      {taskType: 'todo', text: 'Todo Task'},
      {taskType: 'questionnaire', text: 'Questionnaire Task'},
      {taskType: 'timer', text: 'Timer Task'},
    ]

    const shouldAddGarminStream = isEditingLiveProject
      ? tempGarminStreamEnable || garminStreamEnable
      : garminStreamEnable
    if (shouldAddGarminStream) {
      items.push({taskType: 'stopwatchGarminStream', text: 'Garmin Stream Task'})
    }

    const shouldAddMovesenseStream = isEditingLiveProject
      ? tempMovesenseDeviceEnable || movesenseDeviceEnable
      : movesenseDeviceEnable
    if (shouldAddMovesenseStream) {
      items.push({taskType: 'stopwatchMovesenseStream', text: 'Movesense Stream Task'})
    }

    return items
  }
  const scheduleTaskDropdownOptions = createTaskDropdownItems()
  const eventTrackerTaskDropdownOptions = createTaskDropdownItems()
  const legacyTaskDropdownOptions = createTaskDropdownItems()

  useEffect(() => {
    const validTasks = isEditingLiveProject ? tempTaskList.filter((item) => item.enabled) : taskList
    let isTaskFilled = validTasks.length > 0
    if (!isTaskFilled) {
      setTasksFilled(false)
      return
    }
    if (projectScheduleSettingType === ProjectScheduleSettingType.Schedule) {
      isTaskFilled = validTasks.every((item) => item.scheduleList?.length > 0)
    }
    setTasksFilled(isTaskFilled)
  }, [methodState.taskList, tempTaskData.taskList])

  const onDragEnd = (result: any) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    if (!isEditingLiveProject) {
      const newMethodState = JSON.parse(JSON.stringify(methodState))
      const newTaskList = JSON.parse(JSON.stringify(taskList))
      newTaskList.splice(destination.index, 0, newTaskList.splice(source.index, 1)[0])
      const taskIdList = newTaskList.map((task: any, index: number) => {
        task.index = index
        return task.id
      })
      doREQUEST_METHOD_TASK_INDEX_ORDER_UPDATE({
        payload: {
          methodId: methodState.id,
          taskIdList,
        },
      })
      doMETHOD_SET({...newMethodState, taskList: newTaskList})
    } else {
      const taskList: any[] = JSON.parse(JSON.stringify(tempTaskList))
      const enabledTaskList = taskList.filter((item) => item.enabled)
      const hiddenTaskList = taskList.filter((item) => !item.enabled)
      enabledTaskList.splice(destination.index, 0, enabledTaskList.splice(source.index, 1)[0])
      const newTaskList = enabledTaskList.concat(hiddenTaskList)
      doTEMP_TASK_DATA_TASK_LIST_UPDATE({
        taskList: newTaskList,
      })
    }
  }

  const deleteTask = () => {
    if (!isEditingLiveProject) {
      doREQUEST_TASK_DELETE({
        payload: {
          taskId: removingTaskId,
        },
      })
    } else {
      const removingTask = {...tempTaskList.find((task) => task.id === removingTaskId)}
      // hide task if it's already exist in backend
      if (removingTask.existed) {
        removingTask.enabled = false
        removingTask.actionType = 'hide'
        doTEMP_TASK_DATA_UPDATE_TASK(removingTask)
      } else {
        // or if not, remove it
        doTEMP_TASK_DATA_DELETE_TASK({
          taskId: removingTaskId,
        })
      }
    }
    closeConfirmRemovingTaskPopup()
  }

  const closeConfirmRemovingTaskPopup = () => {
    setRemovingTaskId('')
    setRemovingTaskName('')
  }

  return (
    <div
      css={{
        display: 'flex',
        background: color.white,
        borderRadius: '5px;',
        width: '55vw',
        boxShadow: '0px 4px 12px 0px #D4D4D440',
        marginBottom: pad.large,
      }}
    >
      <div
        css={{
          padding: pad.large,
          borderRight: `1px solid ${color.grey_100}`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '26%',
        }}
      >
        <div>
          <div
            css={{
              width: '40px',
              height: '40px',
              background: color.background,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: pad.large,
            }}
          >
            <img alt='Participant Task' src={ParticipantTaskIcon} width='24px' />
          </div>
          <p
            css={{
              fontSize: '18px',
              fontWeight: fontWeight.thick,
              marginBottom: pad.medium,
            }}
          >
            Participant Tasks
          </p>
          <p css={{lineHeight: '19px', fontWeight: fontWeight.regular}}>
            Create tasks for your participants to complete throughout your study.
          </p>
        </div>
      </div>
      <div
        css={{
          padding: pad.xl,
          width: '74%',
        }}
      >
        {RIF(
          isInternalUser,
          <>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '14px',
              }}
            >
              <div css={{marginRight: '8px'}}>
                <Step text='STEP 1' />
              </div>
              <p
                css={{
                  fontSize: '14px',
                  fontWeight: fontWeight.medium,
                }}
              >
                Setup project schedule
              </p>
            </div>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '32px',
              }}
            >
              {RIF(
                !projectScheduleSettingType,
                <ButtonReverse
                  data-testid='setup_project_schedule_btn'
                  btnPadding='medium'
                  onClick={() => setDisplayEditProjectSchedulePopUp(true)}
                  disabled={!canAddProjectSchedule}
                  children={
                    <span
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        color: color.primary,
                      }}
                    >
                      <img
                        alt='Setup Project Schedule'
                        src={CalendarBlueIcon}
                        width='18'
                        css={{
                          marginRight: pad.xs,
                        }}
                      />
                      Setup Project Schedule
                    </span>
                  }
                  css={{marginRight: pad.small, marginBottom: '10px'}}
                />,
              )}
              {RIF(
                projectScheduleSettingType,
                <div
                  css={{
                    width: '100%',
                    padding: '16px',
                    borderRadius: '5px',
                    background: color.surface.grey.background,
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '16px',
                    }}
                  >
                    <div
                      css={{
                        fontWeight: fontWeight.thick,
                        fontSize: fontSize.h6,
                        color: color.black,
                      }}
                    >
                      Project Schedule
                    </div>
                    <button
                      type='button'
                      disabled={!canEditProjectSchedule}
                      onClick={() => setDisplayEditProjectSchedulePopUp(true)}
                      css={{
                        backgroundColor: color.surface.grey.background,
                        ':hover': {
                          background: canEditProjectSchedule
                            ? darken(color.surface.grey.background, 10)
                            : color.surface.grey.background,
                        },
                        color: canEditProjectSchedule ? color.grey_600 : color.state.disabled,
                        fontWeight: fontWeight.bold,
                        fontSize: fontSize.h7,
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: canEditProjectSchedule ? color.grey_160 : color.state.disabled,
                        borderRadius: '3px',
                        padding: '2px 8px',
                      }}
                    >
                      Edit Project Schedule
                    </button>
                  </div>
                  <div css={{wordBreak: 'break-word'}}>
                    {RIF(
                      projectScheduleSettingType === ProjectScheduleSettingType.Schedule,
                      <>
                        <span>This project operates on a </span>
                        <span
                          css={{
                            color: color.primary,
                            fontWeight: fontWeight.thick,
                          }}
                        >
                          {scheduleConfig?.frequency} basis.{' '}
                        </span>
                      </>,
                    )}
                    {RIF(
                      projectScheduleSettingType === ProjectScheduleSettingType.EventTracker,
                      <span>You are using event trackers. </span>,
                    )}
                    {RIF(
                      projectScheduleSettingType === ProjectScheduleSettingType.Legacy,
                      <span>You are using an older schedule setting. </span>,
                    )}
                    <span>Please remove all tasks if you wish to change your project schedule.</span>
                  </div>
                </div>,
              )}
            </div>
          </>,
        )}
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '14px',
          }}
        >
          {RIF(
            isInternalUser,
            <div css={{marginRight: '8px'}}>
              <Step text='STEP 2' />
            </div>,
          )}
          <p
            css={{
              fontSize: '14px',
              fontWeight: fontWeight.medium,
            }}
          >
            Create tasks for participants to complete
          </p>
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '6px',
          }}
        >
          {RIF(
            canAddScheduleTask,
            <ParticipantTaskDropdown
              {...{
                items: scheduleTaskDropdownOptions,
                text: 'Add Scheduled Task',
                dropped: showScheduleTaskDropdown,
                onClickItem: ({taskType}) => {
                  setShowScheduleTaskDropdown(false)
                  setIsAddingTask(true)
                  setTaskType(taskType)
                },
                onMouseOver: () => setShowScheduleTaskDropdown(true),
                onMouseOut: () => setShowScheduleTaskDropdown(false),
              }}
            />,
          )}
          {RIF(
            canAddEventTrackerTask,
            <ParticipantTaskDropdown
              {...{
                items: eventTrackerTaskDropdownOptions,
                text: 'Add Event Tracker',
                dropped: showEventTrackerTaskDropdown,
                onClickItem: ({taskType}) => {
                  setShowEventTrackerTaskDropdown(false)
                  setIsAddingTask(true)
                  setTaskType(taskType)
                },
                onMouseOver: () => setShowEventTrackerTaskDropdown(true),
                onMouseOut: () => setShowEventTrackerTaskDropdown(false),
              }}
            />,
          )}
          {RIF(
            canAddLegacyTask,
            <ParticipantTaskDropdown
              {...{
                items: legacyTaskDropdownOptions,
                text: 'Add Task',
                dropped: showLegacyTaskDropdown,
                onClickItem: ({taskType}) => {
                  setShowLegacyTaskDropdown(false)
                  setIsAddingTask(true)
                  setTaskType(taskType)
                },
                onMouseOver: () => setShowLegacyTaskDropdown(true),
                onMouseOut: () => setShowLegacyTaskDropdown(false),
              }}
            />,
          )}
        </div>
        {RIF(
          !isEditingLiveProject,
          <>
            {RIF(!taskList?.length, <EmptyTaskBlock />)}
            {RIF(
              !!taskList?.length,
              <>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='taskList'>
                    {(provided) => {
                      return (
                        <>
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            css={{
                              minHeight: '296px',
                              width: '100%',
                              marginTop: pad.large,
                              marginBottom: '24px',
                            }}
                          >
                            {taskList.map((task: TaskStateType, i: number) => {
                              const onEditing = () => {
                                setIsAddingTask(true)
                                setEditingTask({
                                  ...task,
                                  index: i,
                                })
                                setTaskType(getTaskContentName(task.type))
                              }
                              return (
                                <TaskTag
                                  {...{
                                    task,
                                    setRemovingTaskId,
                                    setRemovingTaskName,
                                    index: i,
                                    key: task.id,
                                    onEditing: onEditing,
                                  }}
                                />
                              )
                            })}
                            {provided.placeholder}
                          </div>
                        </>
                      )
                    }}
                  </Droppable>
                </DragDropContext>
              </>,
            )}
          </>,
        )}
        {RIF(
          isEditingLiveProject,
          <>
            {RIF(tempTaskList.filter((item) => item.enabled).length === 0, <EmptyTaskBlock />)}
            {RIF(
              tempTaskList.filter((item) => item.enabled).length > 0,
              <>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='taskList'>
                    {(provided) => {
                      return (
                        <>
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            css={{
                              minHeight: '296px',
                              width: '100%',
                              marginTop: pad.large,
                            }}
                          >
                            {tempTaskList
                              .filter((item) => item.enabled)
                              .map((task: TaskTempStateType, i: number) => {
                                const onEditing = () => {
                                  setIsAddingTask(true)
                                  setEditingTask({
                                    ...task,
                                    index: i,
                                  })
                                  setTaskType(getTaskContentName(task.type))
                                }
                                return (
                                  <TaskTag
                                    {...{
                                      task,
                                      setRemovingTaskId,
                                      setRemovingTaskName,
                                      index: i,
                                      key: task.id,
                                      onEditing: onEditing,
                                    }}
                                  />
                                )
                              })}
                            {provided.placeholder}
                          </div>
                        </>
                      )
                    }}
                  </Droppable>
                </DragDropContext>
              </>,
            )}
            {RIF(
              tempTaskList.filter((item) => !item.enabled).length > 0,
              <div css={{width: '100%', textAlign: 'end', marginTop: '16px'}}>
                <a
                  css={{
                    color: color.grey_600,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    textDecorationColor: color.grey_600,
                    ':hover': {
                      color: color.black,
                      textDecorationColor: color.black,
                    },
                  }}
                  onClick={() => {
                    setShowHiddenTask((prev) => !prev)
                  }}
                >
                  {showHiddenTask ? 'Hide' : 'See'} Hidden tasks
                </a>
              </div>,
            )}
            {RIF(
              showHiddenTask && !!tempTaskList.filter((item) => !item.enabled).length,
              <div
                css={{
                  width: '100%',
                  borderRadius: '8px',
                  border: `1px dashed ${color.grey_300}`,
                  padding: '32px',
                  marginTop: '24px',
                }}
              >
                <p
                  css={{
                    fontWeight: fontWeight.medium,
                    marginBottom: '24px',
                  }}
                >
                  The following tasks are hidden from participant app:
                </p>
                {tempTaskList
                  .filter((item) => !item.enabled)
                  .map((task: TaskTempStateType, i: number) => {
                    const onEditing = () => {
                      setIsAddingTask(true)
                      setEditingTask({
                        ...task,
                        index: i,
                      })
                      setTaskType(getTaskContentName(task.type))
                    }
                    return (
                      <HiddenTaskTag
                        {...{
                          task,
                          key: task.id,
                          onEditing: onEditing,
                        }}
                      />
                    )
                  })}
              </div>,
            )}
          </>,
        )}
      </div>
      {RIF(isAddingTask, () => (
        <AddTaskPage
          {...{
            isEditing: editingTask,
            taskType,
            closePopup: () => {
              setIsAddingTask(false)
              setEditingTask({})
            },
            setDisplayChangePlanPage,
            projectScheduleSettingType: t.unwrap(
              projectScheduleSettingType,
              'taskScheduleSettingType must not be empty when adding task',
            ),
            projectScheduleConfig: scheduleConfig,
          }}
        />
      ))}
      {RIF(
        displayChangePlanPage,
        <PricingPage
          {...{
            setDisplayChangePlanPage,
          }}
        />,
      )}
      {RIF(
        !!removingTaskId,
        <PopupConfirmDeleteTask
          {...{
            deleteAction: deleteTask,
            closeAction: closeConfirmRemovingTaskPopup,
            taskName: removingTaskName,
          }}
        />,
      )}
      {RIF(
        displayEditProjectSchedulePopUp,
        <EditProjectSchedulePopup
          {...{
            projectId: projectId as string,
            projectScheduleSettingType: projectScheduleSettingType,
            scheduleConfig,
            onClose: () => setDisplayEditProjectSchedulePopUp(false),
            onSaveComplete: () => {
              setDisplayEditProjectSchedulePopUp(false)
              setHasScheduleEdits(true)
            },
          }}
        />,
      )}
    </div>
  )
}

const EmptyTaskBlock = () => {
  const {color, pad, fontSize} = selectTheme()
  return (
    <div
      css={{
        borderRadius: '5px',
        border: `1px dashed ${color.grey_400}`,
        height: '296px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: pad.large,
      }}
    >
      <p
        css={{
          fontSize: fontSize.h4,
          color: color.grey_400,
        }}
      >
        You don't have any tasks set up yet
      </p>
    </div>
  )
}
