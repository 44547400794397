import {selectTheme} from '../../../store/state/theme'
import {defaultSvgProps, SvgProps} from '../type/props'

export const CheckIcon = (props?: SvgProps) => {
  const {color} = selectTheme()
  const {width, height, fill, flexShrink} = defaultSvgProps(props, {
    width: 14,
    height: 14,
    fill: color.textIcon.title,
    flexShrink: 0,
  })

  return (
    <svg
      width={width}
      height={height}
      style={{flexShrink}}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.00004 0.333313C3.32004 0.333313 0.333374 3.31998 0.333374 6.99998C0.333374 10.68 3.32004 13.6666 7.00004 13.6666C10.68 13.6666 13.6667 10.68 13.6667 6.99998C13.6667 3.31998 10.68 0.333313 7.00004 0.333313ZM5.66671 10.3333L2.33337 6.99998L3.27337 6.05998L5.66671 8.44665L10.7267 3.38665L11.6667 4.33331L5.66671 10.3333Z'
        fill={fill}
      />
    </svg>
  )
}
