import {Dispatch, SetStateAction} from 'react'

import {ITag} from '../../shared/db'
import {selectTheme} from '../../store'
import {Checkbox, Tag} from '../atoms'
import {handleEsc} from '../../lib'

export interface PopupParticipantTagFilterProps {
  tagList: ITag[]
  checked: (tag: ITag) => boolean
  onChange: (tag: ITag, value: boolean) => void
  onClose: Dispatch<SetStateAction<boolean>>
}

const CheckboxTagElement = (tag: ITag, checked: boolean, onChange: (checked: boolean) => void) => {
  return (
    <div
      key={tag.id}
      style={{
        width: '100%',
        height: '100%',
        padding: '4px 0px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 8,
        display: 'inline-flex',
      }}
    >
      <Checkbox value={checked} onChange={onChange} />
      <Tag tag={tag} />
    </div>
  )
}

export const PopupParticipantTagFilter = (props: PopupParticipantTagFilterProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {tagList, checked, onChange, onClose} = props

  handleEsc(() => onClose(false))

  return (
    <div
      style={{
        position: 'absolute',
        left: '100%',
        top: 0,
        zIndex: 99,
        width: '100%',
        height: 'auto',
        padding: '16px 12px',
        background: color.white,
        boxShadow: '0px 4px 10px 1px rgba(104.12, 104.12, 104.12, 0.25)',
        borderRadius: 5,
        border: `1px solid ${color.grey_160}`,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 8,
        display: 'inline-flex',
      }}
    >
      <span
        style={{
          color: color.grey_600,
          fontSize: fontSize.h7,
          fontWeight: fontWeight.medium,
          wordWrap: 'break-word',
        }}
      >
        Filter participants by tags
      </span>
      {tagList.map((tag) => CheckboxTagElement(tag, checked(tag), (checked) => onChange(tag, checked)))}
    </div>
  )
}
