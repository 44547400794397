import {selectTheme} from '../../../store/state/theme'
import {defaultSvgProps, SvgProps} from '../type/props'

export const CalendarIcon = (props?: SvgProps) => {
  const {color} = selectTheme()
  const {width, height, fill, flexShrink} = defaultSvgProps(props, {
    width: 16,
    height: 16,
    fill: color.textIcon.title,
    flexShrink: 0,
  })

  return (
    <svg
      width={width}
      height={height}
      style={{flexShrink}}
      viewBox='0 0 12 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.6667 1.66671H10V0.333374H8.66667V1.66671H3.33333V0.333374H2V1.66671H1.33333C0.593333 1.66671 0.00666666 2.26671 0.00666666 3.00004L0 12.3334C0 13.0667 0.593333 13.6667 1.33333 13.6667H10.6667C11.4 13.6667 12 13.0667 12 12.3334V3.00004C12 2.26671 11.4 1.66671 10.6667 1.66671ZM10.6667 12.3334H1.33333V5.66671H10.6667V12.3334ZM10.6667 4.33337H1.33333V3.00004H10.6667V4.33337ZM6 7.66671H9.33333V11H6V7.66671Z'
        fill={fill}
      />
    </svg>
  )
}
