import {createDispatchActions} from '../../store'
import {LabelBox, Textarea, PopupTemplate, PopupSize, Input} from '..'
import {RequestResult} from '../../lib'
import {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from 'react'

export const PopupSendNotification = (props: {
  sendNotificationParticipantIdList: string[]
  setNotificationInfo: Dispatch<
    SetStateAction<{
      message: {msgTitle: string; msgContent: string}
      response: any
    }>
  >
  setDisplayPopupSendNotification: Dispatch<SetStateAction<boolean>>
  setDisplayNotificationDiscardPop: Dispatch<SetStateAction<boolean>>
  setDisplayNotificationInfoPop: Dispatch<SetStateAction<boolean>>
}) => {
  const {
    sendNotificationParticipantIdList,
    setNotificationInfo,
    setDisplayNotificationDiscardPop,
    setDisplayNotificationInfoPop,
    setDisplayPopupSendNotification,
  } = props
  const {doREQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION}: any = createDispatchActions()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  const [messageTitle, setMessageTitle] = useState('')
  const [messageContent, setMessageContent] = useState('')
  const handleClosePopup = () => {
    if (messageTitle.length > 0 || messageContent.length > 0) {
      return setDisplayNotificationDiscardPop(true)
    }
    setDisplayPopupSendNotification(false)
  }
  const handleTitleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessageTitle(e.target.value)
  }
  const handleContentInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessageContent(e.target.value)
  }

  const handleSubmitSendingPushNotification = async () => {
    doREQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION({
      setRequestResult,
      payload: {
        title: messageTitle,
        content: messageContent,
        participantIdList: sendNotificationParticipantIdList,
      },
    })
  }

  useEffect(() => {
    if (requestResult?.success) {
      setNotificationInfo({
        message: {
          msgTitle: messageTitle,
          msgContent: messageContent,
        },
        response: requestResult.result.payload,
      })
      setDisplayPopupSendNotification(false)
      setDisplayNotificationInfoPop(true)
    }
  }, [requestResult])

  return (
    <PopupTemplate
      {...{
        title: `Send message to ${sendNotificationParticipantIdList.length} participant(s)`,
        description:
          'The selected participants will see the message in the Labfront app and get a push-notification on their lock screen if they have messages turned on in their phone settings.',
        background: true,
        popupSize: PopupSize.Medium,
        handleClosePopup,
        mainFunction: handleSubmitSendingPushNotification,
        buttonContent: 'Send',
      }}
    >
      <>
        <LabelBox
          {...{
            label: 'Title',
            required: true,
            leftCharacters: 250 - messageTitle.length,
          }}
        >
          <Input
            {...{
              value: messageTitle,
              onChange: handleTitleInput,
            }}
            maxLength={250}
            placeholder='Please write a short and concise title here'
            style={{marginBottom: '30px'}}
          />
        </LabelBox>
        <LabelBox
          {...{
            label: 'Content',
            required: true,
            leftCharacters: 1000 - messageContent.length,
          }}
        >
          <Textarea
            {...{
              value: messageContent,
              onChange: handleContentInput,
            }}
            maxLength={1000}
            placeholder='Please write the message content here'
            rows={4}
          />
        </LabelBox>
      </>
    </PopupTemplate>
  )
}
