import {useState, useEffect} from 'react'
import {Controller} from 'react-hook-form'

import {selectTheme, selectWorkspaces} from '../../store'

import {calculateInputCSS} from '../../lib/calculate_input_css'

import {v, useTranslation, RIF, useForm, useCurrentWorkspaceState} from '../../lib'

import {ContactsIcon} from '../../asset/image'
import {Textarea, LabelBox} from '..'

const schema = v.object({
  contactDescription: v.string().max(250).optional(),
})

export const ContactBlock = (props: any) => {
  const {pad, color, fontWeight, fontSize} = selectTheme()
  const workspacesState = selectWorkspaces()
  const {workspaceId} = useCurrentWorkspaceState()
  const role = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'

  const [contactWordCount, setContactWordCount] = useState(0)
  const wordCountMaximum: any = {
    contact: 250,
  }
  const handleWordCount = (e: any, type: string, wordCountSetter: any) => {
    e.target.value = e.target.value.slice(0, wordCountMaximum[type])
    wordCountSetter(e.target.value.length)
  }

  const {setContactValueFilled, contactValue, setContactsValue, isSummaryReview} = props

  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({schema})

  useEffect(() => {
    if (watch('contactDescription') !== undefined) {
      setContactsValue(watch('contactDescription'))
    }
    if (contactValue) setContactValueFilled(true)
    if (contactValue === '') setContactValueFilled(false)
    setContactWordCount(contactValue?.length)
  }, [watch('contactDescription'), contactValue])

  return (
    <div
      css={{
        display: 'flex',
        background: color.white,
        borderRadius: '5px;',
        width: '55vw',
        boxShadow: '0px 4px 12px 0px #D4D4D440',
        marginBottom: pad.large,
      }}
    >
      {/* left */}
      <div
        css={{
          padding: pad.large,
          borderRight: `1px solid ${color.grey_100}`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '26%',
        }}
      >
        <div>
          <div
            css={{
              width: '40px',
              height: '40px',
              background: color.background,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: pad.large,
            }}
          >
            <img src={ContactsIcon} width='24px' />
          </div>
          <p
            css={{
              fontSize: '18px',
              fontWeight: fontWeight.thick,
              marginBottom: pad.medium,
            }}
          >
            Contact
          </p>
          {RIF(
            !isSummaryReview,
            <p css={{lineHeight: '19px', fontWeight: fontWeight.regular}}>
              Provide contact information for your participants to get in touch during the project.
            </p>,
          )}
        </div>

        {/* {RIF(!isSummaryReview,
          <div css={{
            width: '100%',
            height: '149px',
            background: color.background,
            borderRadius: '5px',
          }}></div>)} */}
      </div>

      {/* right */}
      <div css={{padding: pad.xl, width: '74%'}}>
        {RIF(
          !isSummaryReview,
          <>
            <p css={{fontSize: '14px', fontWeight: fontWeight.medium, marginBottom: pad.xl}}>
              Information for your participants to get in touch:
            </p>
            <LabelBox
              {...{
                label: 'Contact Information',
                required: true,
                leftCharacters: wordCountMaximum.contact - contactWordCount,
              }}
            >
              <Controller
                control={control}
                name='contactDescription'
                render={({field: {name, value, onChange}}) => (
                  <Textarea
                    {...{
                      name: name,
                      value: contactValue,
                      onChange: onChange,
                      onInput: (e) => handleWordCount(e, 'contact', setContactWordCount),
                    }}
                    maxLength={250}
                    readOnly={role === 'viewer'}
                    rows={4}
                    placeholder='Please write contact information for your participants'
                  />
                )}
              />
            </LabelBox>
          </>,
        )}

        {RIF(isSummaryReview, <div css={{margin: pad.medium}}>{contactValue}</div>)}

        {RIF(
          !isSummaryReview,
          <p css={{fontSize: fontSize.h7, color: color.grey_400, marginBottom: '52px', marginTop: pad.xl}}>
            Example 1: If you have any questions or encounter issues during the project, please contact
            alix@bestuniversity.edu
          </p>,
        )}
      </div>
    </div>
  )
}
