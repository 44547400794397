import {selectTheme} from '../../../store/state/theme'
import {defaultSvgProps, SvgProps} from '../type/props'

export const TimeIcon = (props?: SvgProps) => {
  const {color} = selectTheme()
  const {width, height, fill, flexShrink} = defaultSvgProps(props, {
    width: 16,
    height: 16,
    fill: color.textIcon.title,
    flexShrink: 0,
  })

  return (
    <svg
      width={width}
      height={height}
      style={{flexShrink}}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.9935 0.333374C3.3135 0.333374 0.333496 3.32004 0.333496 7.00004C0.333496 10.68 3.3135 13.6667 6.9935 13.6667C10.6802 13.6667 13.6668 10.68 13.6668 7.00004C13.6668 3.32004 10.6802 0.333374 6.9935 0.333374ZM7.00016 12.3334C4.0535 12.3334 1.66683 9.94671 1.66683 7.00004C1.66683 4.05337 4.0535 1.66671 7.00016 1.66671C9.94683 1.66671 12.3335 4.05337 12.3335 7.00004C12.3335 9.94671 9.94683 12.3334 7.00016 12.3334ZM7.3335 3.66671H6.3335V7.66671L9.8335 9.76671L10.3335 8.94671L7.3335 7.16671V3.66671Z'
        fill={fill}
      />
    </svg>
  )
}
