import {RIF} from '../../lib'
import {RadioButtonIcon} from '../../lib/svg/icon/radio'
import {selectTheme} from '../../store'

export interface TaskScheduleTypeSelectionCardProps {
  title: string
  description: string
  selected: boolean
  onSelection: () => void
}

export const TaskScheduleTypeSelectionCard = (props: TaskScheduleTypeSelectionCardProps) => {
  const {title, description, selected, onSelection} = props

  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <div
      onClick={() => onSelection()}
      css={{
        flex: 1,
        borderColor: selected ? color.border.black : color.border.default,
        borderStyle: 'solid',
        borderWidth: 2,
        borderRadius: 5,
        padding: 16,
        backgroundColor: selected ? color.grey_20 : color.white,
        transition: 'all 0.2s ease-in-out',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: color.grey_20,
          borderColor: color.border.black,
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <div
            css={{
              fontSize: fontSize.h6,
              fontWeight: fontWeight.bold,
              marginBottom: 8,
            }}
          >
            {title}
          </div>
          {RIF(
            selected,
            <div css={{flexShrink: 0}}>
              <RadioButtonIcon foregroundColor={color.border.black} />
            </div>,
          )}
        </div>
        <span
          css={{
            fontSize: fontSize.h7,
            fontWeight: fontWeight.medium,
            wordBreak: 'break-word',
          }}
        >
          {description}
        </span>
      </div>
    </div>
  )
}
