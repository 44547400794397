import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {ScheduleTime} from '../../shared/db'
import {ScheduleFormValidationErrorMessages, ScheduleFormValidator, ScheduleTimeHelper} from '../../model/schedule'
import {selectTheme} from '../../store'
import {
  DefaultTaskScheduleSettingsTextStyle,
  TaskScheduleSettingsDurationInput,
  TaskScheduleSettingsFrequencyInput,
  TaskScheduleSettingsTimeInput,
} from './task_schedule_settings_input'
import {maybeSoftTry} from '../../lib'
import {useMount} from 'react-use'
import {registerName} from '../../lib/form'
import {isNil} from 'lodash'

interface TaskScheduleSettingsRecurringFullyRandomizedEMAForm {
  emaOcurrences: number
  startTime: string
  startTimeHours: number
  startTimeMinutes: number
  endTime: string
  endTimeHours: number
  endTimeMinutes: number
  emaExpirationSeconds: number
  emaExpirationHours: number
  emaExpirationMinutes: number
  emaMinimumGapSeconds: number
  emaMinimumGapHours: number
  emaMinimumGapMinutes: number
}

export type TaskScheduleSettingsRecurringFullyRandomizedEMAOutput = Omit<
  TaskScheduleSettingsRecurringFullyRandomizedEMAForm,
  | 'startTimeHours'
  | 'startTimeMinutes'
  | 'endTimeHours'
  | 'endTimeMinutes'
  | 'emaExpirationHours'
  | 'emaExpirationMinutes'
  | 'emaMinimumGapHours'
  | 'emaMinimumGapMinutes'
>

export interface TaskScheduleSettingsRecurringFullyRandomizedEMAProps {
  values: Partial<TaskScheduleSettingsRecurringFullyRandomizedEMAOutput>
  onValidated: (form: TaskScheduleSettingsRecurringFullyRandomizedEMAOutput) => void
  onError: () => void
}

export const TaskScheduleSettingsRecurringFullyRandomizedEMA = (
  props: TaskScheduleSettingsRecurringFullyRandomizedEMAProps,
) => {
  const {values, onValidated, onError} = props

  const {color, fontSize, fontWeight} = selectTheme()

  const textStyle = DefaultTaskScheduleSettingsTextStyle({color, fontSize, fontWeight})

  const defaultStartTime = values.startTime ?? '05:00'
  const defaultStartTimeParsed = maybeSoftTry(() => ScheduleTime.parse(defaultStartTime))

  const defaultEndTime = values.endTime ?? '23:59'
  const defaultEndTimeParsed = maybeSoftTry(() => ScheduleTime.parse(defaultEndTime))

  const defaultEmaExpirationSeconds = values.emaExpirationSeconds ?? 1800
  const defaultEmaMinimumGapSeconds = values.emaMinimumGapSeconds ?? 1800

  const {
    register,
    formState: {isValidating, isValid, errors},
    trigger,
    setValue,
    getValues,
  } = useForm<TaskScheduleSettingsRecurringFullyRandomizedEMAForm>({
    mode: 'onChange',
    defaultValues: {
      startTime: defaultStartTime,
      startTimeHours: defaultStartTimeParsed?.hours ?? 5,
      startTimeMinutes: defaultStartTimeParsed?.minutes ?? 0,
      endTime: defaultEndTime,
      endTimeHours: defaultEndTimeParsed?.hours ?? 23,
      endTimeMinutes: defaultEndTimeParsed?.minutes ?? 59,
      emaOcurrences: values.emaOcurrences ?? 4,
      emaExpirationSeconds: defaultEmaExpirationSeconds,
      emaExpirationHours: ScheduleTimeHelper.getHoursFromTotalSeconds(defaultEmaExpirationSeconds),
      emaExpirationMinutes: ScheduleTimeHelper.getMinutesFromTotalSeconds(defaultEmaExpirationSeconds),
      emaMinimumGapSeconds: defaultEmaMinimumGapSeconds,
      emaMinimumGapHours: ScheduleTimeHelper.getHoursFromTotalSeconds(defaultEmaMinimumGapSeconds),
      emaMinimumGapMinutes: ScheduleTimeHelper.getMinutesFromTotalSeconds(defaultEmaMinimumGapSeconds),
    },
  })

  const [latestErrorMessage, setLatestErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    for (const error of Object.values(errors)) {
      if (error?.message) {
        setLatestErrorMessage(error.message)
        onError()
        return
      }
    }
    setLatestErrorMessage(null)
  }, [
    errors.emaOcurrences,
    errors.startTime,
    errors.startTimeHours,
    errors.startTimeMinutes,
    errors.endTime,
    errors.endTimeHours,
    errors.endTimeMinutes,
    errors.emaExpirationSeconds,
    errors.emaExpirationHours,
    errors.emaExpirationMinutes,
    errors.emaMinimumGapSeconds,
    errors.emaMinimumGapHours,
    errors.emaMinimumGapMinutes,
  ])

  useMount(() => {
    trigger()
  })

  useEffect(() => {
    if (isValid && !isValidating) {
      const form = getValues()
      onValidated(form)
    }
  }, [isValid, isValidating])

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <div>
        <span css={{...textStyle}}>The task will appear randomly</span>
        <TaskScheduleSettingsFrequencyInput
          register={registerName(register, 'emaOcurrences')}
          failedValidation={!!errors.emaOcurrences}
          {...{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Ocurrences'),
            },
            min: {
              value: 1,
              message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('Ocurrences'),
            },
          }}
        />
        <span css={{...textStyle}}>between</span>
        <input
          type='hidden'
          {...register('startTime', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Time'),
            },
            pattern: {
              value: ScheduleTime.REGEX,
              message: ScheduleFormValidationErrorMessages.timeInvalid('Start Time'),
            },
            validate: {
              validTimeInterval: (value) => {
                const {success} = ScheduleFormValidator.validateTimeInterval({
                  startTime: value,
                  endTime: getValues('endTime'),
                })
                return success ? true : ScheduleFormValidationErrorMessages.shouldBeLessThan('Start Time', 'End Time')
              },
            },
            deps: ['endTime'],
          })}
        />
        <TaskScheduleSettingsTimeInput
          registerHours={registerName(register, 'startTimeHours')}
          registerMinutes={registerName(register, 'startTimeMinutes')}
          failedValidation={!!errors.startTimeHours || !!errors.startTimeMinutes || !!errors.startTime}
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Time Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Start Time Hours'),
            },
            max: {
              value: 23,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Start Time Hours'),
            },
            onChange: (e) => {
              const startTimeHours = parseInt(e.target.value)
              setValue('startTimeHours', startTimeHours)
              const startTimeMinutes = getValues('startTimeMinutes')
              if (!isNil(startTimeMinutes)) {
                setValue('startTime', ScheduleTimeHelper.format(startTimeHours, startTimeMinutes))
              }
              trigger(['startTimeHours', 'startTimeMinutes', 'startTime', 'endTime', 'emaMinimumGapSeconds'])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Time Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Start Time Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Start Time Minutes'),
            },
            onChange: (e) => {
              const startTimeMinutes = parseInt(e.target.value)
              setValue('startTimeMinutes', startTimeMinutes)
              const startTimeHours = getValues('startTimeHours')
              if (!isNil(startTimeHours)) {
                setValue('startTime', ScheduleTimeHelper.format(startTimeHours, startTimeMinutes))
              }
              trigger(['startTimeMinutes', 'startTimeHours', 'startTime', 'endTime', 'emaMinimumGapSeconds'])
            },
          }}
        />
        <span css={{...textStyle}}>to</span>
        <input
          type='hidden'
          {...register('endTime', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('End Time'),
            },
            pattern: {
              value: ScheduleTime.REGEX,
              message: ScheduleFormValidationErrorMessages.timeInvalid('End Time'),
            },
            validate: {
              validTimeInterval: (value) => {
                const {success} = ScheduleFormValidator.validateTimeInterval({
                  startTime: getValues('startTime'),
                  endTime: value,
                })
                return success
                  ? true
                  : ScheduleFormValidationErrorMessages.shouldBeGreaterThan('End Time', 'Start Time')
              },
            },
            deps: ['startTime'],
          })}
        />
        <TaskScheduleSettingsTimeInput
          registerHours={registerName(register, 'endTimeHours')}
          registerMinutes={registerName(register, 'endTimeMinutes')}
          failedValidation={!!errors.endTimeHours || !!errors.endTimeMinutes || !!errors.endTime}
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('End Time Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('End Time Hours'),
            },
            max: {
              value: 23,
              message: ScheduleFormValidationErrorMessages.hourInvalid('End Time Hours'),
            },
            onChange: (e) => {
              const endTimeHours = parseInt(e.target.value)
              setValue('endTimeHours', endTimeHours)
              const endTimeMinutes = getValues('endTimeMinutes')
              if (!isNil(endTimeMinutes)) {
                setValue('endTime', ScheduleTimeHelper.format(endTimeHours, endTimeMinutes))
              }
              trigger(['endTimeHours', 'endTimeMinutes', 'endTime', 'startTime', 'emaMinimumGapSeconds'])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('End Time Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('End Time Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('End Time Minutes'),
            },
            onChange: (e) => {
              const endTimeMinutes = parseInt(e.target.value)
              setValue('endTimeMinutes', endTimeMinutes)
              const endTimeHours = getValues('endTimeHours')
              if (!isNil(endTimeHours)) {
                setValue('endTime', ScheduleTimeHelper.format(endTimeHours, endTimeMinutes))
              }
              trigger(['endTimeMinutes', 'endTimeHours', 'endTime', 'startTime', 'emaMinimumGapSeconds'])
            },
          }}
        />
      </div>
      <div>
        <span css={{...textStyle}}>With a minimum interval between tasks of</span>
        <input
          type='hidden'
          {...register('emaMinimumGapSeconds', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Minimum Interval'),
            },
            min: {
              value: 1,
              message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('Minimum Interval'),
            },
            validate: {
              validateEmaMinimumGap: (value) => {
                const {success} = ScheduleFormValidator.validateFullyRandomizedEmaMinimumGap({
                  startTime: getValues('startTime'),
                  endTime: getValues('endTime'),
                  emaOcurrences: getValues('emaOcurrences'),
                  emaMinimumGapSeconds: value,
                })
                return success ? true : ScheduleFormValidationErrorMessages.impossibleEmaMinimumGap('Minimum Interval')
              },
            },
            deps: ['startTime', 'endTime', 'emaOcurrences'],
          })}
        />
        <TaskScheduleSettingsDurationInput
          registerHours={registerName(register, 'emaMinimumGapHours')}
          registerMinutes={registerName(register, 'emaMinimumGapMinutes')}
          failedValidation={
            !!errors.emaMinimumGapHours || !!errors.emaMinimumGapMinutes || !!errors.emaMinimumGapSeconds
          }
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Minimum Interval Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Minimum Interval Hours'),
            },
            onChange: (e) => {
              const emaMinimumGapHours = parseInt(e.target.value)
              setValue('emaMinimumGapHours', emaMinimumGapHours)
              const emaMinimumGapMinutes = getValues('emaMinimumGapMinutes')
              if (!isNil(emaMinimumGapMinutes)) {
                setValue(
                  'emaMinimumGapSeconds',
                  ScheduleTimeHelper.getTotalSecondsFromHoursAndMinutes(emaMinimumGapHours, emaMinimumGapMinutes),
                )
              }
              trigger(['emaMinimumGapHours', 'emaMinimumGapMinutes', 'emaMinimumGapSeconds'])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Minimum Interval Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Minimum Interval Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Minimum Interval Minutes'),
            },
            onChange: (e) => {
              const emaMinimumGapMinutes = parseInt(e.target.value)
              setValue('emaMinimumGapMinutes', emaMinimumGapMinutes)
              const emaMinimumGapHours = getValues('emaMinimumGapHours')
              if (!isNil(emaMinimumGapHours)) {
                setValue(
                  'emaMinimumGapSeconds',
                  ScheduleTimeHelper.getTotalSecondsFromHoursAndMinutes(emaMinimumGapHours, emaMinimumGapMinutes),
                )
              }
              trigger(['emaMinimumGapMinutes', 'emaMinimumGapHours', 'emaMinimumGapSeconds'])
            },
          }}
        />
      </div>
      <div
        css={{
          height: '1px',
          backgroundColor: color.border.default,
          width: '100%',
        }}
      />
      <div>
        <span css={{...textStyle}}>The task will remains visible for</span>
        <input
          type='hidden'
          {...register('emaExpirationSeconds', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Expiration'),
            },
            min: {
              value: 1,
              message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('Expiration'),
            },
          })}
        />
        <TaskScheduleSettingsDurationInput
          registerHours={registerName(register, 'emaExpirationHours')}
          registerMinutes={registerName(register, 'emaExpirationMinutes')}
          failedValidation={
            !!errors.emaExpirationHours || !!errors.emaExpirationMinutes || !!errors.emaExpirationSeconds
          }
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Expiration Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Expiration Hours'),
            },
            onChange: (e) => {
              const emaExpirationHours = parseInt(e.target.value)
              setValue('emaExpirationHours', emaExpirationHours)
              const emaExpirationMinutes = getValues('emaExpirationMinutes')
              if (!isNil(emaExpirationMinutes)) {
                setValue(
                  'emaExpirationSeconds',
                  ScheduleTimeHelper.getTotalSecondsFromHoursAndMinutes(emaExpirationHours, emaExpirationMinutes),
                )
              }
              trigger(['emaExpirationHours', 'emaExpirationMinutes', 'emaExpirationSeconds'])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Expiration Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Expiration Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Expiration Minutes'),
            },
            onChange: (e) => {
              const emaExpirationMinutes = parseInt(e.target.value)
              setValue('emaExpirationMinutes', emaExpirationMinutes)
              const emaExpirationHours = getValues('emaExpirationHours')
              if (!isNil(emaExpirationHours)) {
                setValue(
                  'emaExpirationSeconds',
                  ScheduleTimeHelper.getTotalSecondsFromHoursAndMinutes(emaExpirationHours, emaExpirationMinutes),
                )
              }
              trigger(['emaExpirationMinutes', 'emaExpirationHours', 'emaExpirationSeconds'])
            },
          }}
        />
      </div>
      {latestErrorMessage && <p css={{...textStyle, color: color.warning}}>{latestErrorMessage}</p>}
    </div>
  )
}
