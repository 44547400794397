import {selectTheme} from '../../../store/state/theme'
import {defaultSvgProps, SvgProps} from '../type/props'

export const AddIcon = (props?: SvgProps) => {
  const {color} = selectTheme()
  const {width, height, fill, flexShrink} = defaultSvgProps(props, {
    width: 12,
    height: 12,
    fill: color.white,
    flexShrink: 0,
  })

  return (
    <svg
      width={width}
      height={height}
      style={{flexShrink}}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M11.25 6.75H6.75V11.25H5.25V6.75H0.75V5.25H5.25V0.75H6.75V5.25H11.25V6.75Z' fill={fill} />
    </svg>
  )
}
