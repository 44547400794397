import {useEffect, useState} from 'react'
import {
  ScheduleFormValidationErrorMessages,
  ScheduleFormValidator,
  ScheduleFormValueHelper,
  ScheduleTimeHelper,
} from '../../model/schedule'
import {ITaskSchedule, ScheduleTime} from '../../shared/db'
import {useForm} from 'react-hook-form'
import {selectTheme} from '../../store'
import {
  DefaultTaskScheduleSettingsTextStyle,
  TaskScheduleSettingsDurationInput,
  TaskScheduleSettingsTimeInput,
} from './task_schedule_settings_input'
import {maybeSoftTry} from '../../lib'
import {useMount} from 'react-use'
import {registerName} from '../../lib/form'
import {isNil} from 'lodash'

interface TaskScheduleSettingsRecurringSemiRandomizedEMAForm {
  startTime: string
  startTimeHours: number
  startTimeMinutes: number
  endTime: string
  endTimeHours: number
  endTimeMinutes: number
  emaIntervalSeconds: number
  emaIntervalHours: number
  emaIntervalMinutes: number
  emaFlexibilityWindowSeconds: number
  emaFlexibilityWindowHours: number
  emaFlexibilityWindowMinutes: number
  emaExpirationSeconds: number
  emaExpirationHours: number
  emaExpirationMinutes: number
}

type TaskScheduleSettingsRecurringSemiRandomizedEMAFormOutput = Omit<
  TaskScheduleSettingsRecurringSemiRandomizedEMAForm,
  | 'startTimeHours'
  | 'startTimeMinutes'
  | 'endTimeHours'
  | 'endTimeMinutes'
  | 'emaIntervalHours'
  | 'emaIntervalMinutes'
  | 'emaFlexibilityWindowSeconds'
  | 'emaFlexibilityWindowHours'
  | 'emaFlexibilityWindowMinutes'
  | 'emaExpirationHours'
  | 'emaExpirationMinutes'
> &
  Required<Pick<ITaskSchedule, 'emaMinimumGapSeconds'>>

export interface TaskScheduleSettingsRecurringSemiRandomizedEMAProps {
  values: Partial<TaskScheduleSettingsRecurringSemiRandomizedEMAFormOutput>
  onValidated: (form: TaskScheduleSettingsRecurringSemiRandomizedEMAFormOutput) => void
  onError: () => void
}

export const TaskScheduleSettingsRecurringSemiRandomizedEMA = (
  props: TaskScheduleSettingsRecurringSemiRandomizedEMAProps,
) => {
  const {values, onValidated, onError} = props

  const {color, fontSize, fontWeight} = selectTheme()

  const textStyle = DefaultTaskScheduleSettingsTextStyle({color, fontSize, fontWeight})

  const defaultStartTime = values.startTime ?? '05:00'
  const defaultStartTimeParsed = maybeSoftTry(() => ScheduleTime.parse(defaultStartTime))

  const defaultEndTime = values.endTime ?? '23:59'
  const defaultEndTimeParsed = maybeSoftTry(() => ScheduleTime.parse(defaultEndTime))

  const defaultEmaIntervalSeconds = values.emaIntervalSeconds ?? 7200
  const defaultEmaExpirationSeconds = values.emaExpirationSeconds ?? 1800
  const defaultEmaFlexibilityWindowSeconds = (() => {
    const {emaIntervalSeconds, emaMinimumGapSeconds} = values
    if (emaIntervalSeconds && emaMinimumGapSeconds) {
      return ScheduleFormValueHelper.emaMinimumGapSecondsToEmaFlexibilityWindowSeconds({
        emaIntervalSeconds,
        emaMinimumGapSeconds,
      })
    }
    return 1800
  })()

  const {
    register,
    formState: {isValidating, isValid, errors},
    trigger,
    setValue,
    getValues,
  } = useForm<TaskScheduleSettingsRecurringSemiRandomizedEMAForm>({
    mode: 'onChange',
    defaultValues: {
      startTime: defaultStartTime,
      startTimeHours: defaultStartTimeParsed?.hours ?? 5,
      startTimeMinutes: defaultStartTimeParsed?.minutes ?? 0,
      endTime: defaultEndTime,
      endTimeHours: defaultEndTimeParsed?.hours ?? 23,
      endTimeMinutes: defaultEndTimeParsed?.minutes ?? 59,
      emaIntervalSeconds: defaultEmaIntervalSeconds,
      emaIntervalHours: ScheduleTimeHelper.getHoursFromTotalSeconds(defaultEmaIntervalSeconds),
      emaIntervalMinutes: ScheduleTimeHelper.getMinutesFromTotalSeconds(defaultEmaIntervalSeconds),
      emaFlexibilityWindowSeconds: defaultEmaFlexibilityWindowSeconds,
      emaFlexibilityWindowHours: ScheduleTimeHelper.getHoursFromTotalSeconds(defaultEmaFlexibilityWindowSeconds),
      emaFlexibilityWindowMinutes: ScheduleTimeHelper.getMinutesFromTotalSeconds(defaultEmaFlexibilityWindowSeconds),
      emaExpirationSeconds: defaultEmaExpirationSeconds,
      emaExpirationHours: ScheduleTimeHelper.getHoursFromTotalSeconds(defaultEmaExpirationSeconds),
      emaExpirationMinutes: ScheduleTimeHelper.getMinutesFromTotalSeconds(defaultEmaExpirationSeconds),
    },
  })

  const [latestErrorMessage, setLatestErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    for (const error of Object.values(errors)) {
      if (error?.message) {
        onError()
        setLatestErrorMessage(error.message)
        return
      }
    }
    setLatestErrorMessage(null)
  }, [
    errors.startTime,
    errors.startTimeHours,
    errors.startTimeMinutes,
    errors.endTime,
    errors.endTimeHours,
    errors.endTimeMinutes,
    errors.emaIntervalSeconds,
    errors.emaIntervalHours,
    errors.emaIntervalMinutes,
    errors.emaFlexibilityWindowSeconds,
    errors.emaFlexibilityWindowHours,
    errors.emaFlexibilityWindowMinutes,
    errors.emaExpirationSeconds,
    errors.emaExpirationHours,
    errors.emaExpirationMinutes,
  ])

  useMount(() => {
    trigger()
  })

  useEffect(() => {
    if (isValid && !isValidating) {
      const form = getValues()
      onValidated({
        ...form,
        emaMinimumGapSeconds: ScheduleFormValueHelper.emaFlexibilityWindowSecondsToEmaMinimumGapSeconds({
          emaFlexibilityWindowSeconds: form.emaFlexibilityWindowSeconds,
          emaIntervalSeconds: form.emaIntervalSeconds,
        }),
      })
    }
  }, [isValid, isValidating])

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <div>
        <span css={{...textStyle}}>The task will appear approximately every</span>
        <input
          type='hidden'
          {...register('emaIntervalSeconds', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Interval'),
            },
            min: {
              value: 1,
              message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('Interval'),
            },
            validate: {
              validateEmaIntervalSeconds: (value) => {
                const [startTime, endTime] = getValues(['startTime', 'endTime'])
                const {success} = ScheduleFormValidator.validateEmaIntervalSeconds({
                  startTime,
                  endTime,
                  emaIntervalSeconds: value,
                })
                return success ? true : ScheduleFormValidationErrorMessages.impossibleEmaInterval('Interval')
              },
            },
            deps: ['startTime', 'endTime'],
          })}
        />
        <TaskScheduleSettingsDurationInput
          registerHours={registerName(register, 'emaIntervalHours')}
          registerMinutes={registerName(register, 'emaIntervalMinutes')}
          failedValidation={!!errors.emaIntervalHours || !!errors.emaIntervalMinutes || !!errors.emaIntervalSeconds}
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Interval Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Interval Hours'),
            },
            onChange: (e) => {
              const emaIntervalHours = parseInt(e.target.value)
              setValue('emaIntervalHours', emaIntervalHours)
              const emaIntervalMinutes = getValues('emaIntervalMinutes')
              if (!isNil(emaIntervalMinutes)) {
                setValue(
                  'emaIntervalSeconds',
                  ScheduleTimeHelper.getTotalSecondsFromHoursAndMinutes(emaIntervalHours, emaIntervalMinutes),
                )
              }
              trigger([
                'emaIntervalHours',
                'emaIntervalMinutes',
                'emaIntervalSeconds',
                'startTime',
                'endTime',
                'emaFlexibilityWindowSeconds',
              ])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Interval Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Interval Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Interval Minutes'),
            },
            onChange: (e) => {
              const emaIntervalMinutes = parseInt(e.target.value)
              setValue('emaIntervalMinutes', emaIntervalMinutes)
              const emaIntervalHours = getValues('emaIntervalHours')
              if (!isNil(emaIntervalHours)) {
                setValue(
                  'emaIntervalSeconds',
                  ScheduleTimeHelper.getTotalSecondsFromHoursAndMinutes(emaIntervalHours, emaIntervalMinutes),
                )
              }
              trigger([
                'emaIntervalMinutes',
                'emaIntervalHours',
                'emaIntervalSeconds',
                'startTime',
                'endTime',
                'emaFlexibilityWindowSeconds',
              ])
            },
          }}
        />
        <span css={{...textStyle}}>between</span>
        <input
          type='hidden'
          {...register('startTime', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Time'),
            },
            pattern: {
              value: ScheduleTime.REGEX,
              message: ScheduleFormValidationErrorMessages.timeInvalid('Start Time'),
            },
            validate: {
              validateStartTime: (value) => {
                const {success} = ScheduleFormValidator.validateTimeInterval({
                  startTime: value,
                  endTime: getValues('endTime'),
                })
                return success ? true : ScheduleFormValidationErrorMessages.shouldBeLessThan('Start Time', 'End Time')
              },
            },
            deps: ['endTime'],
          })}
        />
        <TaskScheduleSettingsTimeInput
          registerHours={registerName(register, 'startTimeHours')}
          registerMinutes={registerName(register, 'startTimeMinutes')}
          failedValidation={!!errors.startTimeHours || !!errors.startTimeMinutes || !!errors.startTime}
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Start Hours'),
            },
            max: {
              value: 23,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Start Hours'),
            },
            onChange: (e) => {
              const startTimeHours = parseInt(e.target.value)
              setValue('startTimeHours', startTimeHours)
              const startTimeMinutes = getValues('startTimeMinutes')
              if (!isNil(startTimeMinutes)) {
                setValue('startTime', ScheduleTimeHelper.format(startTimeHours, startTimeMinutes))
              }
              trigger([
                'startTimeHours',
                'startTimeMinutes',
                'startTime',
                'endTime',
                'emaIntervalSeconds',
                'emaFlexibilityWindowSeconds',
              ])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Start Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Start Minutes'),
            },
            onChange: (e) => {
              const startTimeMinutes = parseInt(e.target.value)
              setValue('startTimeMinutes', startTimeMinutes)
              const startTimeHours = getValues('startTimeHours')
              if (!isNil(startTimeHours)) {
                setValue('startTime', ScheduleTimeHelper.format(startTimeHours, startTimeMinutes))
              }
              trigger([
                'startTimeMinutes',
                'startTimeHours',
                'startTime',
                'endTime',
                'emaIntervalSeconds',
                'emaFlexibilityWindowSeconds',
              ])
            },
          }}
        />
        <span css={{...textStyle}}>to</span>
        <input
          type='hidden'
          {...register('endTime', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('End Time'),
            },
            pattern: {
              value: ScheduleTime.REGEX,
              message: ScheduleFormValidationErrorMessages.timeInvalid('End Time'),
            },
            validate: {
              validateEndTime: (value) => {
                const {success} = ScheduleFormValidator.validateTimeInterval({
                  startTime: getValues('startTime'),
                  endTime: value,
                })
                return success
                  ? true
                  : ScheduleFormValidationErrorMessages.shouldBeGreaterThan('End Time', 'Start Time')
              },
            },
            deps: ['startTime'],
          })}
        />
        <TaskScheduleSettingsTimeInput
          registerHours={registerName(register, 'endTimeHours')}
          registerMinutes={registerName(register, 'endTimeMinutes')}
          failedValidation={!!errors.endTimeHours || !!errors.endTimeMinutes || !!errors.endTime}
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('End Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('End Hours'),
            },
            max: {
              value: 23,
              message: ScheduleFormValidationErrorMessages.hourInvalid('End Hours'),
            },
            onChange: (e) => {
              const endTimeHours = parseInt(e.target.value)
              setValue('endTimeHours', endTimeHours)
              const endTimeMinutes = getValues('endTimeMinutes')
              if (!isNil(endTimeMinutes)) {
                setValue('endTime', ScheduleTimeHelper.format(endTimeHours, endTimeMinutes))
              }
              trigger([
                'endTimeHours',
                'endTimeMinutes',
                'endTime',
                'startTime',
                'emaIntervalSeconds',
                'emaFlexibilityWindowSeconds',
              ])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('End Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('End Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('End Minutes'),
            },
            onChange: (e) => {
              const endTimeMinutes = parseInt(e.target.value)
              setValue('endTimeMinutes', endTimeMinutes)
              const endTimeHours = getValues('endTimeHours')
              if (!isNil(endTimeHours)) {
                setValue('endTime', ScheduleTimeHelper.format(endTimeHours, endTimeMinutes))
              }
              trigger([
                'endTimeMinutes',
                'endTimeHours',
                'endTime',
                'startTime',
                'emaIntervalSeconds',
                'emaFlexibilityWindowSeconds',
              ])
            },
          }}
        />
      </div>
      <div>
        <span css={{...textStyle}}>Within a flexibility window of +/-</span>
        <input
          type='hidden'
          {...register('emaFlexibilityWindowSeconds', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Flexibility Window'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('Flexibility Window'),
            },
            validate: {
              validateEmaFlexibilityWindowSeconds: (value) => {
                const emaIntervalSeconds = getValues('emaIntervalSeconds')
                const emaMinimumGapSeconds = ScheduleFormValueHelper.emaFlexibilityWindowSecondsToEmaMinimumGapSeconds({
                  emaFlexibilityWindowSeconds: value,
                  emaIntervalSeconds,
                })

                // happens when emaFlexibilityWindow * 2 > emaInterval
                if (emaMinimumGapSeconds < 0) {
                  return ScheduleFormValidationErrorMessages.impossibleEmaFlexibilityWindow('Flexibility Window')
                }

                // happens when emaFlexibilityWindow * 2 === emaInterval
                if (emaMinimumGapSeconds === 0) {
                  return ScheduleFormValidationErrorMessages.emaFlexibilityWindowEqualsToFullyRandomizedEma(
                    'Flexibility Window',
                  )
                }

                // happens when emaFlexibilityWindow === 0
                if (emaMinimumGapSeconds === emaIntervalSeconds) {
                  return ScheduleFormValidationErrorMessages.emaFlexibilityWindowEqualsToFixedEma('Flexibility Window')
                }

                return true
              },
            },
            deps: ['emaIntervalSeconds'],
          })}
        />
        <TaskScheduleSettingsDurationInput
          registerHours={registerName(register, 'emaFlexibilityWindowHours')}
          registerMinutes={registerName(register, 'emaFlexibilityWindowMinutes')}
          failedValidation={
            !!errors.emaFlexibilityWindowHours ||
            !!errors.emaFlexibilityWindowMinutes ||
            !!errors.emaFlexibilityWindowSeconds
          }
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Flexibility Window Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Flexibility Window Hours'),
            },
            onChange: (e) => {
              const emaFlexibilityWindowHours = parseInt(e.target.value)
              setValue('emaFlexibilityWindowHours', emaFlexibilityWindowHours)
              const emaFlexibilityWindowMinutes = getValues('emaFlexibilityWindowMinutes')
              if (!isNil(emaFlexibilityWindowMinutes)) {
                setValue(
                  'emaFlexibilityWindowSeconds',
                  ScheduleTimeHelper.getTotalSecondsFromHoursAndMinutes(
                    emaFlexibilityWindowHours,
                    emaFlexibilityWindowMinutes,
                  ),
                )
              }
              trigger([
                'emaFlexibilityWindowHours',
                'emaFlexibilityWindowMinutes',
                'emaFlexibilityWindowSeconds',
                'emaIntervalSeconds',
                'startTime',
                'endTime',
              ])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Flexibility Window Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Flexibility Window Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Flexibility Window Minutes'),
            },
            onChange: (e) => {
              const emaFlexibilityWindowMinutes = parseInt(e.target.value)
              setValue('emaFlexibilityWindowMinutes', emaFlexibilityWindowMinutes)
              const emaFlexibilityWindowHours = getValues('emaFlexibilityWindowHours')
              if (!isNil(emaFlexibilityWindowHours)) {
                setValue(
                  'emaFlexibilityWindowSeconds',
                  ScheduleTimeHelper.getTotalSecondsFromHoursAndMinutes(
                    emaFlexibilityWindowHours,
                    emaFlexibilityWindowMinutes,
                  ),
                )
              }
              trigger([
                'emaFlexibilityWindowMinutes',
                'emaFlexibilityWindowHours',
                'emaFlexibilityWindowSeconds',
                'emaIntervalSeconds',
                'startTime',
                'endTime',
              ])
            },
          }}
        />
      </div>
      <div
        css={{
          height: '1px',
          backgroundColor: color.border.default,
          width: '100%',
        }}
      />
      <div>
        <span css={{...textStyle}}>The task will remains visible for</span>
        <input
          type='hidden'
          {...register('emaExpirationSeconds', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Expiration'),
            },
            min: {
              value: 1,
              message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('Expiration'),
            },
          })}
        />
        <TaskScheduleSettingsDurationInput
          registerHours={registerName(register, 'emaExpirationHours')}
          registerMinutes={registerName(register, 'emaExpirationMinutes')}
          failedValidation={
            !!errors.emaExpirationHours || !!errors.emaExpirationMinutes || !!errors.emaExpirationSeconds
          }
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Expiration Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Expiration Hours'),
            },
            onChange: (e) => {
              const emaExpirationHours = parseInt(e.target.value)
              setValue('emaExpirationHours', emaExpirationHours)
              const emaExpirationMinutes = getValues('emaExpirationMinutes')
              if (!isNil(emaExpirationMinutes)) {
                setValue(
                  'emaExpirationSeconds',
                  ScheduleTimeHelper.getTotalSecondsFromHoursAndMinutes(emaExpirationHours, emaExpirationMinutes),
                )
              }
              trigger(['emaExpirationHours', 'emaExpirationMinutes', 'emaExpirationSeconds'])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Expiration Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Expiration Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Expiration Minutes'),
            },
            onChange: (e) => {
              const emaExpirationMinutes = parseInt(e.target.value)
              setValue('emaExpirationMinutes', emaExpirationMinutes)
              const emaExpirationHours = getValues('emaExpirationHours')
              if (!isNil(emaExpirationHours)) {
                setValue(
                  'emaExpirationSeconds',
                  ScheduleTimeHelper.getTotalSecondsFromHoursAndMinutes(emaExpirationHours, emaExpirationMinutes),
                )
              }
              trigger(['emaExpirationMinutes', 'emaExpirationHours', 'emaExpirationSeconds'])
            },
          }}
        />
      </div>
      {latestErrorMessage && <p css={{...textStyle, color: color.warning}}>{latestErrorMessage}</p>}
    </div>
  )
}
