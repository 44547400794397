import {selectTheme} from '../../../store/state/theme'
import {defaultSvgProps, SvgProps} from '../type/props'

export const CloseIcon = (props?: SvgProps) => {
  const {color} = selectTheme()
  const {width, height, fill, flexShrink} = defaultSvgProps(props, {
    width: 24,
    height: 24,
    fill: color.textIcon.title,
    flexShrink: 0,
  })

  return (
    <svg
      width={width}
      height={height}
      style={{flexShrink}}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
        fill={fill}
      />
    </svg>
  )
}
