import {StreamDataTypeToGraphDataTypeMap} from '../../../components/charts/utils/utils'
import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'
import {DeviceStreamData} from '../../../shared/mongo/device_stream_data'
import {StreamDataDbInsertedResult} from '../../db/db_data_setter'
import {cloneDeep} from 'lodash'

export enum CompletionStreamDataMetadataMapActionType {
  STREAM_DATA_METADATA_SET = 'STREAM_DATA_METADATA_SET',
  STREAM_DATA_METADATA_UPDATE = 'STREAM_DATA_METADATA_UPDATE',
}

export const doSTREAM_DATA_METADATA_SET = createAction(
  CompletionStreamDataMetadataMapActionType.STREAM_DATA_METADATA_SET,
)

export const doSTREAM_DATA_METADATA_UPDATE = createAction(
  CompletionStreamDataMetadataMapActionType.STREAM_DATA_METADATA_UPDATE,
)

interface StreamDataMetadata {
  dataStartTimeStamp: number
  dataEndTimeStamp: number
  timeOffset: number
  dbInserted?: boolean
}

type ObjectIdMetadataRecord = Record<string, StreamDataMetadata>
export type TypedStreamDataMetadataRecord = Record<string, ObjectIdMetadataRecord>

interface RootState {
  completionStreamDataMetadataMap: CompletionStreamDataMapState
}
/* selector */
export const selectCompletionStreamDataMetadataMap = () => {
  return useSelector((state: RootState) => state.completionStreamDataMetadataMap)
}

export const completionStreamDataMetadataMapActionCreators = {
  doSTREAM_DATA_METADATA_SET,
  doSTREAM_DATA_METADATA_UPDATE,
}

export type CompletionStreamDataMapState = Record<string, TypedStreamDataMetadataRecord>

export const completionStreamDataMetadataMapDefaultState: CompletionStreamDataMapState = {}

type Action =
  | {
      type: CompletionStreamDataMetadataMapActionType.STREAM_DATA_METADATA_SET
      payload: {
        completionId: string
        timeOffset: number
        dataIndexList: DeviceStreamData[]
      }
    }
  | {
      type: CompletionStreamDataMetadataMapActionType.STREAM_DATA_METADATA_UPDATE
      payload: {
        insertedResultList: StreamDataDbInsertedResult[]
      }
    }

export const completionStreamDataMetadataMapReducer = (
  state: CompletionStreamDataMapState = completionStreamDataMetadataMapDefaultState,
  {type, payload}: Action,
) => {
  const newState = cloneDeep(state)

  switch (type) {
    case CompletionStreamDataMetadataMapActionType.STREAM_DATA_METADATA_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          completionId: v.string().exist(),
          dataIndexList: v.array().items(
            v.object({
              dataType: v.string().required(),
              dataStartTime: v.date().required(),
              dataEndTime: v.date().required(),
            }),
          ),
        }),
      })

      const timeOffset = payload.timeOffset
      const typedStreamDataMetadata = payload.dataIndexList.reduce((result, dataIndex) => {
        const graphDataType = StreamDataTypeToGraphDataTypeMap[dataIndex.dataType]
        const objectId = dataIndex._id as unknown as string
        const streamDataMetadata = {
          dataStartTimeStamp: new Date(dataIndex.dataStartTime).getTime() + timeOffset,
          dataEndTimeStamp: new Date(dataIndex.dataEndTime).getTime() + timeOffset,
          timeOffset,
        }

        if (result[graphDataType]) {
          result[graphDataType][objectId] = streamDataMetadata
        } else {
          result[graphDataType] = {
            [objectId]: streamDataMetadata,
          }
        }

        return result
      }, {} as TypedStreamDataMetadataRecord)

      newState[payload.completionId] = typedStreamDataMetadata
      return newState
    }

    case CompletionStreamDataMetadataMapActionType.STREAM_DATA_METADATA_UPDATE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          insertedResultList: v.array().items(
            v.object({
              completionId: v.string().required(),
              dataType: v.string().required(),
              objectId: v.string().required(),
            }),
          ),
        }),
      })

      const completionDataInsertedResult = payload.insertedResultList.reduce((acc, dbResult) => {
        const completionId = dbResult.completionId
        if (acc[completionId]) {
          acc[completionId].push(dbResult)
        } else {
          acc[completionId] = [dbResult]
        }
        return acc
      }, {} as Record<string, StreamDataDbInsertedResult[]>)

      for (const [completionId, dbResultList] of Object.entries(completionDataInsertedResult)) {
        const completionStreamDataMetadata = state[completionId]
        if (completionStreamDataMetadata) {
          dbResultList.forEach((dbResult) => {
            if (completionStreamDataMetadata[dbResult.dataType]) {
              completionStreamDataMetadata[dbResult.dataType][dbResult.objectId].dbInserted = true
            }
          })
          newState[completionId] = completionStreamDataMetadata
        }
      }
      return newState
    }

    default:
      return state
  }
}
