import {v4 as uuid} from 'uuid'
import {format} from 'date-fns'
import {getTimezoneOffset, utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz'
import _ from 'lodash'
import {Nullish} from 'utility-types'

const isoTimezoneRegex = /(Z|[+-](?:2[0-3]|[01][0-9])(?::?(?:[0-5][0-9]))?)$/
const getTimezone = (isoString: string) => isoString.match(isoTimezoneRegex)?.shift()
const stripTimezone = (isoString: string) => isoString.replace(isoTimezoneRegex, '')

const utcToLocalTime = (date: Date | number | string) => {
  const utcDate = new Date(date)
  return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000)
}

const unwrap = <T>(value: T | undefined | null, errorMessage?: string): T => {
  if (value === null || value === undefined) {
    throw new Error(errorMessage || 'Missing value')
  } else {
    return value
  }
}

const dateToYYMMDD = (date: Date): number => Number(format(date, 'yyMMdd'))

const parseYYMMDD = (yymmddIndex: number): {year: number; month: number; day: number} => {
  const yymmdd = String(yymmddIndex)
    .match(/.{1,2}/g)
    ?.map(Number)
  if (!yymmdd || yymmdd.length !== 3) {
    throw new Error(`invalid yymmddIndex ${yymmddIndex}`)
  }
  const [year, month, day] = yymmdd

  return {
    year: 2000 + year,
    month,
    day,
  }
}

const dateFromYYMMDD = (yymmddIndex: number): Date => {
  const {year, month, day} = parseYYMMDD(yymmddIndex)
  return new Date(Date.UTC(year, month - 1, day))
}

const stringToStream = (str: string): ReadableStream => {
  return new ReadableStream({
    start(controller) {
      controller.enqueue(new TextEncoder().encode(str))
      controller.close()
    },
  })
}

const streamToString = (stream: ReadableStream): Promise<string> => {
  const reader = stream.getReader()
  const textDecoder = new TextDecoder()
  let result = String()

  async function read(): Promise<string> {
    try {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const {done, value} = await reader.read()
        if (done) {
          break
        }
        result += textDecoder.decode(value, {stream: true})
      }
      result += textDecoder.decode()
      return result
    } catch (error) {
      console.error('Stream reading error:', error)
      throw error
    } finally {
      reader.releaseLock()
    }
  }

  return read()
}

const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return {r, g, b}
}

const rgbToHex = (r: number, g: number, b: number): string => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}

const applyOpacity = (baseHex: string, opacity: number): string => {
  const baseColor = hexToRgb(baseHex)

  const blendedColor = {
    r: Math.round(baseColor.r * opacity + 255 * (1 - opacity)),
    g: Math.round(baseColor.g * opacity + 255 * (1 - opacity)),
    b: Math.round(baseColor.b * opacity + 255 * (1 - opacity)),
  }

  return rgbToHex(blendedColor.r, blendedColor.g, blendedColor.b)
}

const addHashIfNeeded = (hexString: string): string => (hexString.startsWith('#') ? hexString : '#' + hexString)

const reorder = <T>(list: Iterable<T> | ArrayLike<T>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const toYYMMDDListFromRange = (start: number, end: number): number[] => {
  const startDate = toUTCDatefromYYMMDDIndex(start)
  const endDate = toUTCDatefromYYMMDDIndex(end)
  return UTCDatetoYYMMDDRange(startDate, endDate).map((yymmdd) => parseInt(yymmdd))
}

const toUTCDatefromYYMMDDIndex = (yymmddIndex: number | string): Date => {
  const matchResult = (_.isString(yymmddIndex) ? yymmddIndex : String(yymmddIndex)).match(/.{1,2}/g)
  const [yy, mm, dd] = unwrap(matchResult).map(Number)
  return new Date(Date.UTC(2000 + yy, mm - 1, dd))
}

const UTCDatetoYYMMDDRange = (start: Date, end: Date): string[] => {
  const dates: string[] = []
  let currentDate = new Date(start)

  while (currentDate <= new Date(end)) {
    dates.push(formatUTCDateToYYMMDDIndex(currentDate))
    currentDate = new Date(
      Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate() + 1),
    )
  }

  return dates
}

const formatUTCDateToYYMMDDIndex = (date: Date): string => {
  const year = date.getUTCFullYear().toString().slice(-2)
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const day = date.getUTCDate().toString().padStart(2, '0')
  return `${year}${month}${day}`
}

export type SoftTryResult<T> =
  | {
      success: true
      value: T
      error: undefined
    }
  | {
      success: false
      value: undefined
      error: Error
    }

export const softTryResultUnwrap = <T>(result: SoftTryResult<T>): T => {
  if (result.success) {
    return result.value
  } else {
    throw result.error
  }
}

export const softTryResultMaybe = <T>(result: SoftTryResult<T>, defaultValue?: T): T | undefined => {
  if (result.success) {
    return result.value
  } else {
    return defaultValue
  }
}

export const softTry = <T>(fn: () => T): SoftTryResult<T> => {
  try {
    return {
      success: true,
      value: fn(),
      error: undefined,
    }
  } catch (error) {
    return {
      success: false,
      value: undefined,
      error: error as Error,
    }
  }
}

export const maybeSoftTry = <T>(fn: () => T, defaultValue?: T): T | undefined => {
  const result = softTry(fn)
  return softTryResultMaybe(result, defaultValue)
}

export const t = {
  uuid,
  log: console.log,
  utcToLocalTime,
  utcToZonedTime,
  zonedTimeToUtc,
  getTimezoneOffset,
  getTimezone,
  stripTimezone,
  unwrap,
  parseYYMMDD,
  dateToYYMMDD,
  dateFromYYMMDD,
  streamToString,
  stringToStream,
  hexToRgb,
  rgbToHex,
  applyOpacity,
  addHashIfNeeded,
  reorder,
  toYYMMDDListFromRange,
  toUTCDatefromYYMMDDIndex,
  isFiniteNumber: (value: unknown | Nullish): value is number => _.isNumber(value) && _.isFinite(value),
  softTry,
  maybeSoftTry,
  softTryResultUnwrap,
  softTryResultMaybe,
  noop: _.noop,
}
