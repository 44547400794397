import {handleEsc} from '../../lib'
import {ButtonColorTheme, PopupSize, PopupTemplate} from '..'

export interface ParticipantNotificationDiscardPopPropsT {
  setDisplayNotificationDiscardPop: (arg: boolean) => void
  setDisplayPopupSendNotification: (arg: boolean) => void
}

export const ParticipantNotificationDiscardPop = (props: ParticipantNotificationDiscardPopPropsT) => {
  const {setDisplayNotificationDiscardPop, setDisplayPopupSendNotification} = props

  handleEsc(setDisplayNotificationDiscardPop)

  const handleDiscardConfirm = () => {
    setDisplayNotificationDiscardPop(false)
    setDisplayPopupSendNotification(false)
  }

  return (
    <PopupTemplate
      {...{
        title: 'Discard this message',
        description: 'Do you want to discard this message?',
        background: true,
        popupSize: PopupSize.Small,
        handleClosePopup: () => setDisplayNotificationDiscardPop(false),
        mainFunction: handleDiscardConfirm,
        buttonContent: 'Discard',
        buttonColorTheme: ButtonColorTheme.Warning,
      }}
    />
  )
}
