import {useEffect, useState, Dispatch, SetStateAction} from 'react'
import {debug, RequestResult} from '../../lib'
import {createDispatchActions} from '../../store'
import {ParticipantPopupOperationType, PopupSimple, PopupSize, PopupTemplate} from '..'
import {IParticipant} from '../../shared/db'

export const PopupRegenerateInviteCode = (props: {
  setOperationType: Dispatch<SetStateAction<ParticipantPopupOperationType | null>>
  setRegenerateCode: Dispatch<SetStateAction<Record<string, string>>>
  participant: IParticipant | null
}) => {
  const {setOperationType, participant, setRegenerateCode} = props
  const handleClosePopup = () => {
    setOperationType(null)
  }
  const {doREQUEST_PARTICIPANT_CREDENTIALS_REGENERATE}: any = createDispatchActions()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  const handleSubmit = (data: any) => {
    debug('PopupRegenerateInviteCode.onSubmit', {data})
    doREQUEST_PARTICIPANT_CREDENTIALS_REGENERATE({
      setRequestResult,
      payload: {
        participantId: participant?.id,
      },
    })
  }

  useEffect(() => {
    if (!requestResult?.success) return
    // setRegenerateCode(requestComplete?.payload?.payload?.credentials?.loginCode)
    setRegenerateCode((prev) => {
      if (!participant?.id) {
        return prev
      } else {
        return {
          ...prev,
          [participant.id]: requestResult?.result.payload?.credentials?.loginCode,
        }
      }
    })
    handleClosePopup()
  }, [requestResult])

  return (
    <PopupTemplate
      {...{
        title: 'Regenerate Invite Code',
        description:
          'If a participant accidentally logs out or gets a new phone, regenerating a new code will render the previous code invalid.',
        background: true,
        popupSize: PopupSize.Small,
        handleClosePopup,
        mainFunction: handleSubmit,
        buttonContent: 'Regenerate Code',
      }}
    />
  )
}
