import React, {useState, useEffect} from 'react'
import {RIF} from '../../lib'
import {selectTheme} from '../../store'

export enum QuestionnaireInputSize {
  extraSmall = '72px',
  small = '36.286rem',
  medium = '38.143rem',
  big = '53.143rem',
}

interface QuestionnaireInputProps {
  defaultValue?: string
  initialValue: string
  onChangeAction: (arg: string) => void
  placeholder: string
  maxLength: number
  size: QuestionnaireInputSize
  disabled?: boolean
  inputType?: 'number' | 'decimal' | 'text'
  hideWordCount?: boolean
}

export const QuestionnaireInput = (props: QuestionnaireInputProps) => {
  const {color, fontWeight} = selectTheme()
  const {
    defaultValue, 
    initialValue,
    placeholder,
    onChangeAction,
    maxLength,
    size,
    disabled,
    inputType,
    hideWordCount
  } = props
  const [focusing, setFocusing] = useState(false)
  const [inputValue, setInputValue] = useState(defaultValue || '')

  useEffect(() => {
    setInputValue(defaultValue || '')
  }, [defaultValue])

  const handleFocus = () => {
    setFocusing(true)
    if (inputValue === initialValue) {
      setInputValue('')
    }
  }

  const handleBlur = () => {
    setFocusing(false)
    if (!inputValue) {
      setInputValue(initialValue)
      if (onChangeAction) onChangeAction(initialValue)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || e.target.value === '-') {
      setInputValue(e.target.value)
    } else {
      if (inputType === 'number') {
        if (e.target.value === '') {
          setInputValue('0')
        } else if (!isNaN(parseFloat(e.target.value))) {
          setInputValue(e.target.value)
        }
      } else if (inputType === 'decimal') {
        if (e.target.value === '') {
          setInputValue('0')
        } else {
          const number = parseInt(e.target.value)
          if (!isNaN(number) && number % 1 === 0) {
            setInputValue(e.target.value)
          }
        }
      } else {
        setInputValue(e.target.value)
      }
    }
    if (onChangeAction) onChangeAction(e.target.value)
  }

  return (
    <div
      // {...props}
      css={{
        width: size,
        height: '42px',
        position: 'relative',
        borderRadius: '5px',
        overflow: 'hidden',
        border: `1px solid ${focusing ? color.grey_400 : color.disabled}`,
        backgroundColor: color.white,
        ':hover': {
          border: `1px solid ${color.grey_400}`,
        },
      }}
    >
      <input
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={inputValue}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        css={{
          border: 'none',
          width: '100%',
          height: '100%',
          padding: hideWordCount ? '0 0 0 20px': '0 100px 0 20px',
          backgroundColor: disabled ? color.grey_20 : color.white,
          ':focus': {
            outline: 'none',
          },
        }}
      />
      {RIF(
        focusing && !hideWordCount,
        <p
          css={{
            color: color.grey_400,
            fontWeight: fontWeight.medium,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            right: '20px',
            top: '0',
          }}
        >
          {inputValue.length} / {maxLength}
        </p>,
      )}
    </div>
  )
}
