import {Dispatch, SetStateAction} from 'react'
import {handleEsc} from '../../lib'
import {PopupSize, PopupTemplate} from '.'
import {ButtonColorTheme} from '../atoms'

export interface PopupConfirmDeleteTagProps {
  onClose: Dispatch<SetStateAction<boolean>>
  onDelete: () => void
}

export const PopupConfirmDeleteTag = (props: PopupConfirmDeleteTagProps) => {
  const {onDelete} = props
  const handleClosePopup = () => {
    if (props.onClose) {
      props.onClose(false)
    }
  }

  handleEsc(handleClosePopup)

  return (
    <PopupTemplate
      {...{
        title: 'Are you sure you want to delete this tag in this project?',
        description: '',
        background: true,
        popupSize: PopupSize.Small,
        handleClosePopup,
        mainFunction: onDelete,
        buttonContent: 'Delete',
        buttonColorTheme: ButtonColorTheme.Warning,
      }}
    />
  )
}
