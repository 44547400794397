import {selectTheme} from '../../../store/state/theme'
import {SvgProps, defaultSvgProps} from '../type/props'

export const RadioButtonIcon = (props?: Omit<SvgProps, 'fill' | 'stroke'> & {foregroundColor?: string}) => {
  const {color} = selectTheme()
  const {width, height, foregroundColor, flexShrink} = defaultSvgProps(props, {
    width: 20,
    height: 20,
    foregroundColor: color.border.black,
    flexShrink: 0,
  })
  return (
    <svg
      width={width}
      height={height}
      style={{flexShrink}}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10' cy='10' r='9' stroke={foregroundColor} strokeWidth='2' />
      <circle cx='10' cy='10' r='5' fill={foregroundColor} />
    </svg>
  )
}
