import {darken} from '../../lib'
import {CloseIcon} from '../../lib/svg/icon/close'
import {TempTaskSchedule} from '../../model/schedule'
import {selectTheme} from '../../store'

export interface TaskScheduleDeletePopProps {
  schedule: TempTaskSchedule
  onClickConfirm: (schedule: TempTaskSchedule) => void
  onClickCancel: () => void
}

export const TaskScheduleDeletePop = (props: TaskScheduleDeletePopProps) => {
  const {schedule, onClickConfirm, onClickCancel} = props

  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <div
      id='deletePopBackgroundId'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto',
        top: 0,
        left: 0,
        zIndex: 103,
      }}
    >
      <div
        css={{
          width: '50%',
          maxHeight: '90%',
          background: color.white,
          borderRadius: '5px',
          boxShadow: '0px 4px 10px 0px #0000001A',
          overflowY: 'auto',
          margin: '0 auto',
        }}
      >
        <div css={{display: 'flex', flexDirection: 'column', gap: 24, padding: 16}}>
          <div css={{display: 'flex', flexDirection: 'column', gap: 16}}>
            <div css={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <span css={{fontSize: fontSize.h3, fontWeight: fontWeight.thick}}>Delete Schedule</span>
              <button
                css={{
                  border: 'none',
                  backgroundColor: color.white,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 0,
                }}
                onClick={() => onClickCancel()}
              >
                <CloseIcon />
              </button>
            </div>
            <p css={{fontSize: fontSize.h6, fontWeight: fontWeight.bold, color: color.textIcon.secondary}}>
              Are you sure you want to delete this schedule?
            </p>
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 8,
            backgroundColor: color.white,
            boxShadow: '0px 4px 10px 0px #0000001A',
            padding: '16px 24px',
            marginTop: 8,
          }}
        >
          <button
            onClick={() => onClickCancel()}
            css={{
              padding: '8px 16px',
              borderRadius: 4,
              border: 'none',
              backgroundColor: color.surface.default,
              color: color.textIcon.secondary,
              cursor: 'pointer',
              fontSize: fontSize.h6,
              fontWeight: fontWeight.thick,
              '&:hover': {
                backgroundColor: darken(color.surface.default, 10),
              },
              '&:active': {
                backgroundColor: darken(color.surface.default, 20),
              },
              transition: 'all 0.2s ease-in-out',
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => onClickConfirm(schedule)}
            css={{
              padding: '8px 16px',
              borderRadius: 4,
              border: 'none',
              backgroundColor: color.state.warning,
              color: color.white,
              cursor: 'pointer',
              fontSize: fontSize.h6,
              fontWeight: fontWeight.thick,
              '&:hover': {
                backgroundColor: darken(color.state.warning, 10),
              },
              '&:active': {
                backgroundColor: darken(color.state.warning, 20),
              },
              transition: 'all 0.2s ease-in-out',
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}
