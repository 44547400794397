import {Required} from 'utility-types'
import {Dispatch, SetStateAction} from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import {ITaskSchedule} from '../../shared/db'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doMETHOD_UPDATE_TASK_SCHEDULE} from '../state'
import {getToken} from './token_fetcher'
import {getTaskScheduleSchemaMap} from '../../model/schedule'

export const REQUEST_TASK_SCHEDULE_UPDATE = 'REQUEST_TASK_SCHEDULE_UPDATE'
export const doREQUEST_TASK_SCHEDULE_UPDATE = createDoRequestAction(REQUEST_TASK_SCHEDULE_UPDATE)

export const requestTaskScheduleUpdateActionCreators = {
  doREQUEST_TASK_SCHEDULE_UPDATE,
}

interface Payload {
  payload: {
    requestId: string
    schedule: Required<Partial<ITaskSchedule>, 'id'>
  }
  setRequestResult?: Dispatch<SetStateAction<RequestResult>>
}

export function* requestTaskScheduleUpdate({payload, setRequestResult}: Payload): SagaIterator {
  debug('saga*requestTaskScheduleUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        schedule: v.object(getTaskScheduleSchemaMap({required: ['id']})),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_TASK_SCHEDULE_UPDATE,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const accessToken = yield call(getToken)

  const {schedule} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/task-schedule-update`,
    accessToken,
    data: schedule,
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_TASK_SCHEDULE_UPDATE,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/task-schedule-update',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(doMETHOD_UPDATE_TASK_SCHEDULE(result.payload))

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_TASK_SCHEDULE_UPDATE,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* taskScheduleUpdateSaga() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield takeEvery(REQUEST_TASK_SCHEDULE_UPDATE as any, requestTaskScheduleUpdate)
}
