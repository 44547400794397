import {useState, useEffect, Dispatch, SetStateAction, ChangeEvent} from 'react'
import {handleEsc, RequestResult} from '../../lib'
import {createDispatchActions} from '../../store'
import {Input, Textarea, LabelBox, PopupTemplate, PopupSize, ParticipantPopupOperationType} from '..'
import {IParticipant} from '../../shared/db'

export const ParticipantPopup = (props: {
  closeAction: () => void
  method: string
  participant?: IParticipant | null
  setSearchValue?: Dispatch<SetStateAction<string>>
  setOperationType?: Dispatch<SetStateAction<ParticipantPopupOperationType | null>>
  projectId?: string
  batchId?: string
}) => {
  const {doREQUEST_PARTICIPANT_CREATE, doREQUEST_PARTICIPANT_UPDATE, doADHERENCE_DELETE}: any = createDispatchActions()
  const {closeAction, method, setSearchValue, setOperationType, projectId, batchId, participant} = props

  handleEsc(closeAction)

  const [requestResultOfIdOfParticipantCreate, setRequestResultOfIdOfParticipantCreate] =
    useState<RequestResult | null>(null)
  const [requestResultOfIdOfParticipantUpdate, setRequestResultOfIdOfParticipantUpdate] =
    useState<RequestResult | null>(null)

  const [participantName, setParticipantName] = useState<string>(participant?.insignia ?? '')
  const [participantNote, setParticipantNote] = useState<string>(participant?.note ?? '')

  const onSubmit = () => {
    // clear adherence cache to force request update adherence for other page needs
    doADHERENCE_DELETE({projectId})

    if (method === 'Add') {
      doREQUEST_PARTICIPANT_CREATE({
        setRequestResult: setRequestResultOfIdOfParticipantCreate,
        payload: {
          batchId,
          insignia: participantName,
          note: participantNote === '' ? undefined : participantNote, // backend not allow empty string
        },
      })
    }
    if (method === 'Edit') {
      doREQUEST_PARTICIPANT_UPDATE({
        setRequestResult: setRequestResultOfIdOfParticipantUpdate,
        payload: {
          participantId: participant?.id,
          insignia: participantName,
          note: participantNote === '' ? ' ' : participantNote, // backend not allow empty string
        },
      })
    }
  }

  useEffect(() => {
    if (!requestResultOfIdOfParticipantCreate && !requestResultOfIdOfParticipantUpdate) return
    if (method === 'Add') setSearchValue?.('')
    closeAction()
  }, [requestResultOfIdOfParticipantCreate, requestResultOfIdOfParticipantUpdate])

  const handleDeleteParticipant = () => {
    setOperationType?.(ParticipantPopupOperationType.Delete)
  }

  return (
    <PopupTemplate
      {...{
        title: `${method} Participant`,
        description: '',
        background: true,
        popupSize: PopupSize.Medium,
        handleClosePopup: closeAction,
        mainFunction: onSubmit,
        buttonContent: method === 'Add' ? 'Add' : 'Save',
        buttonDisabled: participantName.length === 0,
        thirdButtonText: method === 'Edit' ? 'Delete Participant' : undefined,
        thirdButtonFunction: method === 'Edit' ? handleDeleteParticipant : undefined,
      }}
    >
      <div>
        <LabelBox
          {...{
            label: 'Participant ID',
            leftCharacters: participantName ? 50 - participantName?.length : 50,
            required: true,
          }}
        >
          <Input
            style={{marginBottom: '32px'}}
            placeholder='Use a unique and anonymous identifier'
            maxLength='50'
            value={participantName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setParticipantName(e.target.value)}
            autoFocus={method === 'Add'}
          />
        </LabelBox>

        <LabelBox
          {...{
            label: 'Notes',
            leftCharacters: 250 - participantNote?.length,
          }}
        >
          <Textarea
            rows={3}
            placeholder='Add internal notes here'
            maxLength={250}
            value={participantNote}
            onChange={(e) => setParticipantNote(e.target.value)}
          />
        </LabelBox>
      </div>
    </PopupTemplate>
  )
}
