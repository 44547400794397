import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {ScheduleTime} from '../../shared/db'
import {ScheduleFormValidationErrorMessages, ScheduleFormValidator, ScheduleTimeHelper} from '../../model/schedule'
import {selectTheme} from '../../store'
import {DefaultTaskScheduleSettingsTextStyle, TaskScheduleSettingsTimeInput} from './task_schedule_settings_input'
import {maybeSoftTry} from '../../lib'
import {useMount} from 'react-use'
import {registerName} from '../../lib/form'
import {isNil} from 'lodash'

interface TaskScheduleSettingsRecurringFixedScheduleForm {
  startTime: string
  startTimeHours: number
  startTimeMinutes: number
  endTime: string
  endTimeHours: number
  endTimeMinutes: number
}

type TaskScheduleSettingsRecurringFixedScheduleFormOutput = Omit<
  TaskScheduleSettingsRecurringFixedScheduleForm,
  'startTimeHours' | 'startTimeMinutes' | 'endTimeHours' | 'endTimeMinutes'
>

export interface TaskScheduleSettingsRecurringFixedScheduleProps {
  values: Partial<TaskScheduleSettingsRecurringFixedScheduleFormOutput>
  onValidated: (form: TaskScheduleSettingsRecurringFixedScheduleFormOutput) => void
  onError: () => void
}

export const TaskScheduleSettingsRecurringFixedSchedule = (props: TaskScheduleSettingsRecurringFixedScheduleProps) => {
  const {values, onValidated, onError} = props

  const {color, fontSize, fontWeight} = selectTheme()

  const textStyle = DefaultTaskScheduleSettingsTextStyle({color, fontSize, fontWeight})

  const defaultStartTime = values.startTime ?? '05:00'
  const defaultStartTimeParsed = maybeSoftTry(() => ScheduleTime.parse(defaultStartTime))

  const defaultEndTime = values.endTime ?? '23:59'
  const defaultEndTimeParsed = maybeSoftTry(() => ScheduleTime.parse(defaultEndTime))

  const {
    register,
    formState: {isValidating, isValid, errors},
    trigger,
    getValues,
    setValue,
  } = useForm<TaskScheduleSettingsRecurringFixedScheduleForm>({
    mode: 'onChange',
    defaultValues: {
      startTime: defaultStartTime,
      startTimeHours: defaultStartTimeParsed?.hours ?? 5,
      startTimeMinutes: defaultStartTimeParsed?.minutes ?? 0,
      endTime: defaultEndTime,
      endTimeHours: defaultEndTimeParsed?.hours ?? 23,
      endTimeMinutes: defaultEndTimeParsed?.minutes ?? 59,
    },
  })

  const [latestErrorMessage, setLatestErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    for (const error of Object.values(errors)) {
      if (error?.message) {
        onError()
        setLatestErrorMessage(error.message)
        return
      }
    }
    setLatestErrorMessage(null)
  }, [
    errors.startTime,
    errors.startTimeHours,
    errors.startTimeMinutes,
    errors.endTime,
    errors.endTimeHours,
    errors.endTimeMinutes,
  ])

  useMount(() => {
    trigger()
  })

  useEffect(() => {
    if (isValid && !isValidating) {
      const form = getValues()
      onValidated(form)
    }
  }, [isValid, isValidating])

  return (
    <div css={{display: 'flex', flexDirection: 'column', gap: 16}}>
      <div>
        <span css={{...textStyle}}>The task will appear at</span>
        <input
          type='hidden'
          {...register('startTime', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Time'),
            },
            pattern: {
              value: ScheduleTime.REGEX,
              message: ScheduleFormValidationErrorMessages.timeInvalid('Start Time'),
            },
            validate: {
              validTimeInterval: (value) => {
                const {success} = ScheduleFormValidator.validateTimeInterval({
                  startTime: value,
                  endTime: getValues('endTime'),
                })
                return success ? true : ScheduleFormValidationErrorMessages.shouldBeLessThan('Start Time', 'End Time')
              },
            },
            deps: ['endTime'],
          })}
        />
        <TaskScheduleSettingsTimeInput
          registerHours={registerName(register, 'startTimeHours')}
          registerMinutes={registerName(register, 'startTimeMinutes')}
          failedValidation={!!errors.startTimeHours || !!errors.startTimeMinutes}
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Time Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Start Time Hours'),
            },
            max: {
              value: 23,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Start Time Hours'),
            },
            onChange: (e) => {
              const startTimeHours = parseInt(e.target.value)
              setValue('startTimeHours', startTimeHours)
              const startTimeMinutes = getValues('startTimeMinutes')
              if (!isNil(startTimeMinutes)) {
                setValue('startTime', ScheduleTimeHelper.format(startTimeHours, startTimeMinutes))
              }
              trigger(['startTimeHours', 'startTimeMinutes', 'startTime', 'endTime'])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Time Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Start Time Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Start Time Minutes'),
            },
            onChange: (e) => {
              const startTimeMinutes = parseInt(e.target.value)
              setValue('startTimeMinutes', startTimeMinutes)
              const startTimeHours = getValues('startTimeHours')
              if (!isNil(startTimeHours)) {
                setValue('startTime', ScheduleTimeHelper.format(startTimeHours, startTimeMinutes))
              }
              trigger(['startTimeMinutes', 'startTimeHours', 'startTime', 'endTime'])
            },
          }}
        />
        <span css={{...textStyle}}>and expire at</span>
        <input
          type='hidden'
          {...register('endTime', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('End Time'),
            },
            pattern: {
              value: ScheduleTime.REGEX,
              message: ScheduleFormValidationErrorMessages.timeInvalid('End Time'),
            },
            validate: {
              validTimeInterval: (value) => {
                const {success} = ScheduleFormValidator.validateTimeInterval({
                  startTime: getValues('startTime'),
                  endTime: value,
                })
                return success
                  ? true
                  : ScheduleFormValidationErrorMessages.shouldBeGreaterThan('End Time', 'Start Time')
              },
            },
            deps: ['startTime'],
          })}
        />
        <TaskScheduleSettingsTimeInput
          registerHours={registerName(register, 'endTimeHours')}
          registerMinutes={registerName(register, 'endTimeMinutes')}
          failedValidation={!!errors.endTimeHours || !!errors.endTimeMinutes}
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('End Time Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('End Time Hours'),
            },
            max: {
              value: 23,
              message: ScheduleFormValidationErrorMessages.hourInvalid('End Time Hours'),
            },
            onChange: (e) => {
              const endTimeHours = parseInt(e.target.value)
              setValue('endTimeHours', endTimeHours)
              const endTimeMinutes = getValues('endTimeMinutes')
              if (!isNil(endTimeMinutes)) {
                setValue('endTime', ScheduleTimeHelper.format(endTimeHours, endTimeMinutes))
              }
              trigger(['endTimeHours', 'endTimeMinutes', 'endTime', 'startTime'])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('End Time Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('End Time Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('End Time Minutes'),
            },
            onChange: (e) => {
              const endTimeMinutes = parseInt(e.target.value)
              setValue('endTimeMinutes', endTimeMinutes)
              const endTimeHours = getValues('endTimeHours')
              if (!isNil(endTimeHours)) {
                setValue('endTime', ScheduleTimeHelper.format(endTimeHours, endTimeMinutes))
              }
              trigger(['endTimeMinutes', 'endTimeHours', 'endTime', 'startTime'])
            },
          }}
        />
      </div>
      {latestErrorMessage && <p css={{...textStyle, color: color.warning}}>{latestErrorMessage}</p>}
    </div>
  )
}
