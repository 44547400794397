import {ReactNode, useRef, useState, CSSProperties} from 'react'
import {darken, RIF, t, useClickOutside} from '../../lib'

import {ButtonMore} from '../atoms'
import {selectTheme} from '../../store'
import {ScheduleDescription, ScheduleDescriptionCreator} from '../../model/schedule'

import {ITaskSchedule, TaskScheduleScheduleType, TaskScheduleTaskType} from '../../shared/db'
import {Nullish} from 'utility-types'
import {CalendarIcon} from '../../lib/svg/icon/calendar'
import {TimeIcon} from '../../lib/svg/icon/time'
import {WindowIcon} from '../../lib/svg/icon/window'
import {EyeIcon} from '../../lib/svg/icon/eye'

export interface TaskScheduleCardProps {
  schedule: Partial<ITaskSchedule>
  isViewOnly?: boolean
  onClickSchedule?: (schedule: Partial<ITaskSchedule>) => void
  onClickDeleteSchedule?: (schedule: Partial<ITaskSchedule>) => void
  style?: CSSProperties
}

export const TaskScheduleCard = (props: TaskScheduleCardProps) => {
  const {schedule, isViewOnly, onClickSchedule, onClickDeleteSchedule, style} = props
  const itemDescription: ScheduleDescription | undefined = (() => {
    const creator = ScheduleDescriptionCreator.fromSchedule(schedule)
    if (creator) {
      return creator.create()
    }
  })()

  const {color, fontSize, fontWeight} = selectTheme()

  const [displayRemoveButton, setDisplayRemoveButton] = useState(false)
  const brickRef = useRef<HTMLInputElement | null>(null)
  useClickOutside(brickRef, () => setDisplayRemoveButton(false))

  const tag = (name: string, color: string, background: string) => {
    return (
      <div
        css={{
          background,
          color,
          padding: '4px 8px',
          borderRadius: 5,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: color,
          fontSize: fontSize.h8,
          fontWeight: fontWeight.bold,
        }}
      >
        {name}
      </div>
    )
  }

  const removeButton = () =>
    RIF(
      displayRemoveButton,
      <button
        type='button'
        onClick={(e) => {
          e.stopPropagation()
          onClickDeleteSchedule?.(schedule)
        }}
        css={{
          position: 'absolute',
          right: 0,
          top: 25,
          padding: '8px 16px',
          borderRadius: 4,
          width: 'fit-content',
          border: `1px solid ${color.grey_160}`,
          backgroundColor: color.white,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: darken(color.white, 10),
          },
          '&:active': {
            backgroundColor: darken(color.white, 20),
          },
          transition: 'all 0.2s ease-in-out',
        }}
      >
        <span css={{fontSize: fontSize.h6, fontWeight: fontWeight.thick}}>Remove</span>
      </button>,
    )

  const descriptionItem = (icon: ReactNode, description: ReactNode) => (
    <div css={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 8}}>
      {icon}
      {description}
    </div>
  )
  const dateDescriptionItem = (description: ScheduleDescription['dateDescription'] | Nullish) =>
    RIF(description, (description) =>
      descriptionItem(
        <CalendarIcon />,
        <span css={{fontWeight: fontWeight.bold, fontSize: fontSize.h7}}>{description}</span>,
      ),
    )
  const timeDescriptionItem = (description: ScheduleDescription['timeDescription'] | Nullish) =>
    RIF(description, (description) =>
      descriptionItem(
        <TimeIcon />,
        <span css={{fontWeight: fontWeight.bold, fontSize: fontSize.h7}}>{description}</span>,
      ),
    )
  const emaFlexibilityDescriptionItem = (description: ScheduleDescription['emaFlexibilityDescription'] | Nullish) =>
    RIF(description, (description) =>
      descriptionItem(
        <WindowIcon />,
        <div css={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <span css={{color: color.textIcon.secondary, fontWeight: fontWeight.medium, fontSize: fontSize.h7}}>
            {description.prefixText}
          </span>
          <span css={{fontWeight: fontWeight.bold, fontSize: fontSize.h7, marginLeft: 4}}>{description.valueText}</span>
        </div>,
      ),
    )
  const emaExpirationDescriptionItem = (description: ScheduleDescription['emaExpirationDescription'] | Nullish) =>
    RIF(description, (description) =>
      descriptionItem(
        <EyeIcon />,
        <div css={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <span css={{color: color.textIcon.secondary, fontWeight: fontWeight.medium, fontSize: fontSize.h7}}>
            {description.prefixText}
          </span>
          <span css={{fontWeight: fontWeight.bold, fontSize: fontSize.h7, marginLeft: 4}}>{description.valueText}</span>
        </div>,
      ),
    )

  return (
    <div
      data-testid='task_schedule_item'
      onClick={() => {
        if (!isViewOnly) {
          onClickSchedule?.(schedule)
        }
      }}
      css={{
        width: '100%',
        height: 'fit-content',
        borderRadius: 5,
        padding: 16,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        cursor: 'pointer',
        ...style,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 16,
        }}
      >
        <div css={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 8}}>
          {RIF(itemDescription?.scheduleTaskType, (scheduleTaskType) => {
            const name = t.unwrap(itemDescription?.scheduleTaskTypeDescription)
            switch (scheduleTaskType) {
              case TaskScheduleTaskType.OneTimeOnly:
                return tag(name, color.tag.green.dark, color.tag.green.light)
              case TaskScheduleTaskType.Recurring:
                return tag(name, color.tag.purple.dark, color.tag.purple.light)
            }
          })}
          {RIF(itemDescription?.emaScheduleType, (emaScheduleType) => {
            const name = t.unwrap(itemDescription?.emaScheduleTypeDescription)
            switch (emaScheduleType) {
              case TaskScheduleScheduleType.SemiRandomizedEMA:
                return tag(name, color.tag.yellow.dark, color.tag.yellow.light)
              case TaskScheduleScheduleType.FullyRandomizedEMA:
                return tag(name, color.tag.blue.dark, color.tag.blue.light)
            }
          })}
        </div>
        <div css={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', gap: 12}}>
          {dateDescriptionItem(itemDescription?.dateDescription)}
          {timeDescriptionItem(itemDescription?.timeDescription)}
          {emaFlexibilityDescriptionItem(itemDescription?.emaFlexibilityDescription)}
          {emaExpirationDescriptionItem(itemDescription?.emaExpirationDescription)}
        </div>
      </div>
      {isViewOnly || (
        <span
          ref={brickRef}
          css={{
            color: color.grey_400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            position: 'relative',
            cursor: 'pointer',
            borderRadius: '4px',
          }}
        >
          <ButtonMore
            type='button'
            onClick={(e) => {
              e.stopPropagation()
              setDisplayRemoveButton(!displayRemoveButton)
            }}
          />
          {removeButton()}
        </span>
      )}
    </div>
  )
}
