import { IEntityModel } from './base'
import { IQuestionnaire } from './questionnaire'
import { IQuestionnaireSection } from './questionnaire_section'

export enum QuestionnaireQuestionType {
  Text = 'text',
  Textarea = 'textarea',
  Number = 'number',
  Date = 'date',
  Select = 'select',
  MultiSelect = 'multi_select',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Time = 'time',
  DateTime = 'date_time',
}

export interface InputConfigOptions {
  options: {
    name: string
    value: string
  }[]
}

export interface IQuestionnaireQuestion<
  T extends QuestionnaireQuestionType = QuestionnaireQuestionType,
  TInput = T extends
    | QuestionnaireQuestionType.Select
    | QuestionnaireQuestionType.MultiSelect
    | QuestionnaireQuestionType.Radio
    ? InputConfigOptions
    : undefined,
> extends IEntityModel {
  questionnaireId: string
  questionnaireSectionId: string
  questionnaire?: IQuestionnaire
  questionnaireSection?: IQuestionnaireSection
  description: string
  required: boolean
  label: string
  type: T
  inputConfig?: TInput
  index: number
  mediaUrl?: string | null
}
