import {WidthProvider} from 'react-grid-layout'
import {RIF} from '../../../lib/render_if'
import {t} from '../../../lib/t'
import {CorrelationResult, Variable} from '../../../shared/analysis'
import {selectTheme} from '../../../store/state/theme'
import ReactGridLayout from 'react-grid-layout'
import {CorrelationGraph} from './correlation_graph'
import {CorrelationCoordinate} from './correlation_coordinate'
import {getVariableCode, getVariableDisplayName} from '../../../lib/chart_data'
import {SmileIcon} from '../../../lib/svg/icon/smile'

const ComparisonTag = (props: {identifier: string; displayName: string; index: number}) => {
  const {identifier, displayName, index} = props
  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
      }}
    >
      {RIF(
        index !== 0,
        <p
          css={{
            fontSize: fontSize.h7,
            marginRight: '8px',
          }}
        >
          vs.
        </p>,
      )}
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          padding: '4px',
          borderRadius: '5px',
          backgroundColor: color.surface.grey.dark,
        }}
      >
        <p
          css={{
            minWidth: '12px',
            minHeight: '12px',
            lineHeight: '12px',
            borderRadius: '50%',
            fontSize: fontSize.h8,
            fontWeight: fontWeight.thick,
            color: color.white,
            backgroundColor: color.surface.dark,
            textAlign: 'center',
            marginRight: '4px',
          }}
        >
          {identifier}
        </p>
        <p
          css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
          }}
        >
          {displayName}
        </p>
      </div>
    </div>
  )
}

const ComparisonResultEmpty = () => {
  const {color, fontSize} = selectTheme()
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <SmileIcon />
      <div
        css={{
          color: color.textIcon.secondary,
          margin: '8px 0',
        }}
      >
        No Comparison Results
      </div>
      <div
        css={{
          color: color.textIcon.light,
          fontSize: fontSize.h7,
          textAlign: 'center',
        }}
      >
        There are no additional comparison figures for mean values between participants or durations.
      </div>
    </div>
  )
}

const GridWidthProvider = WidthProvider(ReactGridLayout)

export const CorrelationComparisonResult = (
  props: CorrelationResult & {
    displayingVariableList: Variable[]
    selected: CorrelationCoordinate | undefined
    onClickGraph: (selected: CorrelationCoordinate) => void
  },
) => {
  const {displayingVariableList, selected} = props
  const {color, fontWeight} = selectTheme()
  const items =
    props.items.flat().find(({coord}) => coord.row === selected?.row && coord.column === selected?.column)
      ?.sub_graphs ?? []

  return (
    <div
      css={{
        minHeight: '100%',
        width: '244px',
        minWidth: '244px',
        padding: '16px',
        border: `1px solid ${color.border._80}`,
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <p
          css={{
            fontWeight: fontWeight.thick,
            marginBottom: '16px',
          }}
        >
          Comparison Result
        </p>
        {displayingVariableList.map((variable, index) => {
          const identifier = getVariableCode(index)
          const displayName = getVariableDisplayName(variable)
          return <ComparisonTag key={identifier} identifier={identifier} displayName={displayName} index={index} />
        })}
      </div>
      {RIF(items.length === 0, <ComparisonResultEmpty />)}
      {RIF(
        items.length,
        <>
          {items.map((item) => {
            const coord = t.unwrap(props.selected)
            return (
              <CorrelationGraph
                {...{
                  style: {width: '100%'},
                  coord,
                  item,
                  onClickGraph: props.onClickGraph,
                }}
              />
            )
          })}
        </>,
      )}
      {/* {RIF(
        items.length,
        <GridWidthProvider margin={[8, 8]}>
          {items.map((item, rowIndex) => {
            const coord = t.unwrap(props.selected)
            const selected = item.analysis_type === coord.analysisType
            return (
              <div key={rowIndex} data-grid={{x: 0, y: rowIndex, w: 1, h: 1}}>
                <CorrelationGraph
                  {...{
                    coord,
                    item,
                    // selected,
                    onClickGraph: props.onClickGraph,
                  }}
                />
              </div>
            )
          })}
        </GridWidthProvider>,
      )} */}
      <div />
    </div>
  )
}
