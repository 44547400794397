import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {createDispatchActions, selectTheme} from '../../store'
import {debug, RequestResult, RIF, useCurrentProjectState} from '../../lib'
import {ButtonColorTheme, Checkbox, ParticipantPopupOperationType, PopupSize, PopupTemplate} from '..'
import {IParticipant} from '../../shared/db'

export const PopupDeleteParticipant = (props: {
  setOperationType: Dispatch<SetStateAction<ParticipantPopupOperationType | null>>
  participant: IParticipant | null
}) => {
  const {setOperationType, participant} = props
  const {color} = selectTheme()
  const {project} = useCurrentProjectState()
  const batchId = project?.batchList?.[0]?.id
  const {doREQUEST_PARTICIPANT_DELETE}: any = createDispatchActions()

  const handleClosePopup = () => {
    setOperationType(null)
  }

  const [checkboxValue1, setCheckboxValue1] = useState(false)
  const [checkboxValue2, setCheckboxValue2] = useState(false)

  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)
  const handleDeleteParticipant = (data: any) => {
    debug('ParticipantPopup.handleDeleteParticipant', {data})
    doREQUEST_PARTICIPANT_DELETE({
      setRequestResult,
      payload: {
        participantId: participant?.id,
        batchId,
      },
    })
  }
  useEffect(() => {
    if (!requestResult) return
    if (!requestResult.success) return
    handleClosePopup()
  }, [requestResult])

  return (
    <>
      {RIF(
        !participant?.activated,
        <PopupTemplate
          {...{
            title: `Delete Participant ${participant?.insignia}`,
            description: 'Are you sure you want to delete this participant?',
            background: true,
            popupSize: PopupSize.Medium,
            buttonContent: 'Delete Participant',
            mainFunction: handleDeleteParticipant,
            handleClosePopup,
            buttonColorTheme: ButtonColorTheme.Warning,
          }}
        />,
      )}
      {RIF(
        participant?.activated,
        <PopupTemplate
          {...{
            title: `Delete Participant ${participant?.insignia}`,
            description: 'Delete this participant will delete all of data collected. Are you sure you want to delete?',
            background: true,
            popupSize: PopupSize.Medium,
            buttonContent: 'Delete Participant',
            mainFunction: handleDeleteParticipant,
            handleClosePopup,
            buttonColorTheme: ButtonColorTheme.Warning,
            buttonDisabled: !checkboxValue1 || !checkboxValue2,
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            <label css={{display: 'flex', gap: '8px', cursor: 'pointer'}}>
              <Checkbox value={checkboxValue1} color={color.state.default} onChange={setCheckboxValue1} />
              <span css={{lineHeight: '16px'}}>
                I understand that removing a participant stops their data collection and deletes previously collected
                data.
              </span>
            </label>
            <label css={{display: 'flex', gap: '8px', cursor: 'pointer'}}>
              <Checkbox value={checkboxValue2} color={color.state.default} onChange={setCheckboxValue2} />
              <span css={{lineHeight: '16px'}}>I understand that deleted data cannot be restored.</span>
            </label>
          </div>
        </PopupTemplate>,
      )}
    </>
  )
}
