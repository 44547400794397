import {CSSProperties, Dispatch, SetStateAction, createRef, useEffect, useState} from 'react'
import {debounce, isNil, omitBy, trimStart} from 'lodash'

import {ITag} from '../../shared/db'
import {RIF, handleEsc, t, useClickOutside} from '../../lib'
import {createDispatchActions, selectTheme} from '../../store'
import {CheckmarkIcon, DeleteIcon} from '../../asset/image'

interface TagColorItem {
  name: string
  color: string
}

export interface PopupEditTagProps {
  tag: ITag | null
  onClose: Dispatch<SetStateAction<boolean>>
  onDeleteTag: () => void
  style?: CSSProperties
}

export const PopupEditTag = (props: PopupEditTagProps) => {
  const {color, tagColor, tagBackgroundColorOpacity, fontSize, fontWeight} = selectTheme()
  const tagColorItems: TagColorItem[] = Object.entries<string>(tagColor).map(([name, color]) => ({
    name,
    color: trimStart(color, '#'),
  }))

  const {tag, onDeleteTag} = props

  const popupStyle = props.style ?? {}
  const onClose = () => {
    if (props.onClose) {
      props.onClose(false)
    }
  }
  const popupRef = createRef<HTMLElement>()
  handleEsc(() => onClose())
  useClickOutside(popupRef, () => onClose())

  const [selectedTagColorIndex, setSelectedTagColorIndex] = useState<number>(
    tagColorItems.findIndex(({color}) => color === tag?.color),
  )
  const [tagValueInput, setTagValueInput] = useState<string | null>(tag?.value ?? null)
  const [tagValueFinish, setTagValueFinish] = useState<string | null>(null)
  const [isHoveringDelete, setIsHoveringDelete] = useState(false)

  const {doREQUEST_PROJECT_TAG_UPDATE}: any = createDispatchActions()

  useEffect(() => {
    if (!tag) return
    let color: string | undefined
    const selectedTagColor = tagColorItems[selectedTagColorIndex]
    if (selectedTagColor && selectedTagColor.color !== tag.color) {
      color = selectedTagColor.color
    }
    let value: string | undefined
    if (tagValueFinish && tagValueFinish !== tag.value) {
      value = tagValueFinish
    }
    if (!(color || value)) return

    const {projectId, id: tagId} = tag
    doREQUEST_PROJECT_TAG_UPDATE({
      payload: omitBy(
        {
          projectId,
          tagId,
          color,
          value,
        },
        isNil,
      ),
    })
  }, [selectedTagColorIndex, tagValueFinish])

  const handleClickDelete = debounce(
    () => {
      if (tag) {
        onDeleteTag()
      }
    },
    1000,
    {leading: true, trailing: false},
  )

  const TagColorItemElement = (item: TagColorItem, index: number) => {
    const accentColor = t.addHashIfNeeded(item.color)
    const backgroundColor = t.applyOpacity(accentColor, tagBackgroundColorOpacity)
    const selected = selectedTagColorIndex === index
    const handleClick = debounce(
      () => {
        if (!selected) {
          setSelectedTagColorIndex(index)
        }
      },
      1000,
      {leading: true, trailing: false},
    )
    return (
      <div
        key={item.name}
        style={{
          width: '100%',
          height: '100%',
          padding: '4px 8px',
          borderRadius: 5,
          justifyContent: 'flex-start',
          gap: 60,
          alignItems: 'center',
          display: 'flex',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <div
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 8,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: 16,
              height: 16,
              position: 'relative',
              background: backgroundColor,
              borderRadius: 3,
              border: `1px solid ${accentColor}`,
            }}
          />
          <div
            style={{
              color: color.black,
              fontSize: fontSize.h6,
              fontWeight: fontWeight.medium,
            }}
          >
            {item.name}
          </div>
        </div>
        {RIF(selected, <img src={CheckmarkIcon} width={16} height={16} />)}
      </div>
    )
  }

  return (
    <div
      style={{
        width: 'auto',
        height: 'auto',
        zIndex: 99,
        paddingTop: 8,
        paddingBottom: 16,
        background: color.white,
        boxShadow: '0px 4px 10px 1px rgba(104.12, 104.12, 104.12, 0.25)',
        borderRadius: 5,
        border: `1px solid ${color.grey_160}`,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 12,
        display: 'inline-flex',
        ...popupStyle,
      }}
    >
      <div
        style={{
          padding: '0px 8px',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 8,
          display: 'flex',
        }}
      >
        <textarea
          value={tagValueInput ?? ''}
          css={{
            width: '100%',
            fontSize: fontSize.h7,
            fontWeight: fontWeight.medium,
            backgroundColor: color.white,
            padding: '6px 8px',
            borderRadius: 5,
            border: `1px solid ${color.grey_160}`,
            boxSizing: 'border-box',
            resize: 'none',
            overflow: 'hidden',
            ':hover': {
              border: `1px solid ${color.grey_160}`,
            },
            ':active': {
              border: `1px solid ${color.grey_160}`,
              outline: 'none',
            },
            ':focus': {
              border: `1px solid ${color.grey_160}`,
              boxShadow: `none`,
              outline: 'none',
            },
            '::placeholder': {
              color: color.grey_300,
            },
          }}
          rows={1}
          onChange={(event) => setTagValueInput(trimStart(event.target.value).replace(/[\n\r]/g, ''))}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              setTagValueFinish(tagValueInput)
            }
          }}
        />
        <div
          onMouseEnter={() => setIsHoveringDelete(true)}
          onMouseLeave={() => setIsHoveringDelete(false)}
          onClick={handleClickDelete}
          style={{
            width: '100%',
            height: '100%',
            padding: 4,
            background: isHoveringDelete ? t.applyOpacity(color.black, 0.04) : color.white,
            borderRadius: 5,
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 8,
            display: 'inline-flex',
            cursor: 'pointer',
          }}
        >
          <img src={DeleteIcon} width={20} height={20} />
          <span
            style={{
              color: color.black,
              fontSize: 14,
              fontWeight: fontWeight.medium,
            }}
          >
            Delete
          </span>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          border: `1px solid ${color.grey_160}`,
        }}
      />
      <div
        style={{
          padding: '0px 8px',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 8,
          display: 'flex',
        }}
      >
        <span
          style={{
            color: color.grey_600,
            fontSize: fontSize.h7,
            fontWeight: fontWeight.medium,
          }}
        >
          Colors
        </span>
        <div
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 4,
            display: 'flex',
          }}
        >
          {tagColorItems.map(TagColorItemElement)}
        </div>
      </div>
    </div>
  )
}
