import {CSSProperties, Dispatch, SetStateAction, createRef, useEffect, useState} from 'react'
import {compact, concat, debounce, isArray, maxBy, trimStart} from 'lodash'
import {DragDropContext, Draggable, DropResult, Droppable} from 'react-beautiful-dnd'

import {IParticipant, ITag} from '../../shared/db'
import {RIF, RequestResult, handleEsc, t, useClickOutside} from '../../lib'
import {createDispatchActions, selectTheme} from '../../store'
import {Tag} from '../atoms'
import {DragSmallIcon, MoreSmallIcon} from '../../asset/image'

export interface PopupAddTagProps {
  participant?: IParticipant
  projectTagList: ITag[]
  isEditingTag: boolean
  onClose: Dispatch<SetStateAction<boolean>>
  onEditTag: (tag: ITag) => void
  style?: CSSProperties
}

type TagCreateEvent =
  | {
      type: 'existing'
      tag: ITag
    }
  | {
      type: 'new'
      tag: Pick<ITag, 'color' | 'value'>
    }

export const PopupAddTag = (props: PopupAddTagProps) => {
  const {color, tagColor, fontSize, fontWeight} = selectTheme()
  const tagColorList = Object.freeze(Object.values<string>(tagColor))
  const {participant, projectTagList, isEditingTag, onEditTag} = props
  const popupStyle = props.style ?? {}
  const participantTagList = participant?.tagList ?? []

  const {
    doREQUEST_PROJECT_TAG_CREATE,
    doREQUEST_PROJECT_TAG_INDEX_ORDER_UPDATE,
    doREQUEST_PARTICIPANT_TAG_ADD,
    doREQUEST_PARTICIPANT_TAG_REMOVE,
  }: any = createDispatchActions()

  const [value, setValue] = useState<string | null>(null)
  const [tagHoverIndex, setTagHoverIndex] = useState(-1)
  const [tagCreateEvent, setTagCreateEvent] = useState<TagCreateEvent | null>(null)

  const popupRef = createRef<HTMLDivElement>()
  const onClose = () => {
    if (props.onClose && !isEditingTag) {
      props.onClose(false)
    }
  }

  handleEsc(() => onClose())
  useClickOutside(popupRef, () => onClose())

  const resolveNewTagColor = () => {
    const latestColor = maxBy(projectTagList, ({createdAt}) => createdAt)?.color
    if (latestColor) {
      const latestColorHex = t.addHashIfNeeded(latestColor)
      const latestColorIndex = tagColorList.findIndex((color) => color === latestColorHex)
      if (latestColorIndex !== -1) {
        const newColorIndex = (latestColorIndex + 1) % tagColorList.length
        return tagColorList[newColorIndex]
      }
    }
    return tagColorList[0]
  }

  const resolveTagCreateEvent = (value: string | null): TagCreateEvent | null => {
    const tag = projectTagList.find((tag) => tag.value === value)
    if (tag) {
      return {
        type: 'existing',
        tag,
      }
    }
    if (value) {
      return {
        type: 'new',
        tag: {
          color: resolveNewTagColor(),
          value,
        },
      }
    }
    return null
  }

  const handleParticipantTagAdd = debounce(
    (tag: ITag | ITag[]) => {
      if (participant) {
        const {batchId, id: participantId} = participant
        doREQUEST_PARTICIPANT_TAG_ADD({
          payload: {
            batchId,
            participantId,
            tagIds: (isArray(tag) ? tag : [tag]).map(({id}) => id),
          },
        })
      }
    },
    1000,
    {leading: true, trailing: false},
  )

  const handleFinishEvent = debounce(
    (value: string | null) => {
      if (!value) return
      setValue(null)
      setTagCreateEvent(null)

      if (!participant) return
      const {projectId} = participant

      const tagCreateEvent = resolveTagCreateEvent(value)
      if (!tagCreateEvent) return

      const {type, tag} = tagCreateEvent
      switch (type) {
        case 'existing':
          handleParticipantTagAdd(tag)
          break
        case 'new':
          doREQUEST_PROJECT_TAG_CREATE({
            payload: {
              projectId,
              tags: [tag].map(({color, value}) => ({
                color: trimStart(color, '#'),
                value,
              })),
            },
          })
          break
      }
    },
    1000,
    {leading: true, trailing: false},
  )

  useEffect(() => {
    setTagCreateEvent(resolveTagCreateEvent(value))
  }, [value])

  const handleClickRemoveParticipantTag = debounce(
    (tag: ITag) => {
      if (!participant) return
      const {batchId, id: participantId} = participant
      doREQUEST_PARTICIPANT_TAG_REMOVE({
        payload: {
          batchId,
          participantId,
          tagIds: [tag.id],
        },
      })
    },
    1000,
    {leading: true, trailing: false},
  )

  const handleDragProjectTag = (result: DropResult) => {
    const {destination, source} = result
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    if (!participant) return
    const {projectId} = participant
    doREQUEST_PROJECT_TAG_INDEX_ORDER_UPDATE({
      payload: {
        projectId,
        tagIds: t.reorder(projectTagList, source.index, destination.index).map(({id}) => id),
      },
    })
  }

  const TagElement = (tagOrEvent: ITag | TagCreateEvent, index: number) => {
    if ('type' in tagOrEvent) {
      const {type, tag} = tagOrEvent
      switch (type) {
        case 'new':
          return (
            <div
              key={tag.value}
              onClick={() => handleFinishEvent(tag.value)}
              style={{
                width: '100%',
                height: '100%',
                padding: '6px 8px',
                background: color.background,
                borderRadius: 5,
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 8,
                display: 'inline-flex',
              }}
            >
              <span
                css={{
                  minWidth: '38px',
                  color: color.black,
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.medium,
                  wordBreak: 'break-word',
                }}
              >
                Create
              </span>
              <Tag tag={tag} />
            </div>
          )
        default:
          return
      }
    } else {
      const isHovering = tagHoverIndex === index
      return (
        <Draggable key={tagOrEvent.id} draggableId={tagOrEvent.id} index={index}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <div
                onMouseEnter={() => setTagHoverIndex(index)}
                onMouseLeave={() => setTagHoverIndex(-1)}
                style={{
                  width: '100%',
                  height: '100%',
                  background: isHovering ? t.applyOpacity(color.black, 0.04) : color.white,
                  padding: 4,
                  borderRadius: 5,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  <img src={DragSmallIcon} width={6} height={12} />
                  <Tag onClick={handleParticipantTagAdd} tag={tagOrEvent} />
                </div>
                <img
                  style={{display: isHovering ? 'inline' : 'none', cursor: 'pointer'}}
                  onClick={() => onEditTag(tagOrEvent)}
                  src={MoreSmallIcon}
                  width={16}
                  height={16}
                />
              </div>
            </div>
          )}
        </Draggable>
      )
    }
  }

  return (
    <div
      ref={popupRef}
      css={{
        width: '100%',
        height: '100%',
        zIndex: 99,
        padding: 8,
        gap: 8,
        background: color.white,
        boxShadow: '0px 4px 10px 1px rgba(104.12, 104.12, 104.12, 0.25)',
        borderRadius: 5,
        border: `1px ${color.grey_160} solid`,
        flexDirection: 'column',
        display: 'flex',
        ...popupStyle,
      }}
    >
      <div
        style={{
          width: '100%',
          height: 'auto%',
          padding: '6px 8px',
          gap: 8,
          borderRadius: 5,
          border: `1px solid ${color.grey_160}`,
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {participantTagList.map((tag) => (
          <Tag key={tag.id} tag={tag} showTrailingRemoveIcon={true} onClick={handleClickRemoveParticipantTag} />
        ))}
        <textarea
          value={value ?? ''}
          css={{
            width: '100%',
            fontSize: fontSize.h7,
            fontWeight: fontWeight.medium,
            backgroundColor: color.transparent,
            borderRadius: 5,
            border: `1px solid ${color.transparent}`,
            boxSizing: 'border-box',
            resize: 'none',
            overflow: 'hidden',
            ':hover': {
              border: `1px solid ${color.transparent}`,
            },
            ':active': {
              border: `1px solid ${color.transparent}`,
              outline: 'none',
            },
            ':focus': {
              border: `1px solid ${color.transparent}`,
              boxShadow: `none`,
              outline: 'none',
            },
            '::placeholder': {
              color: color.grey_300,
            },
          }}
          rows={1}
          placeholder={projectTagList.length ? 'Search or Create New' : 'Create New'}
          onChange={(event) => setValue(trimStart(event.target.value).replace(/[\n\r]/g, ''))}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && value) {
              event.preventDefault()
              handleFinishEvent(value)
            }
          }}
        />
      </div>
      {RIF(
        projectTagList.length,
        <span
          css={{
            color: color.grey_600,
            fontSize: fontSize.h7,
            fontWeight: fontWeight.medium,
            wordBreak: 'break-word',
          }}
        >
          Select an option or create one
        </span>,
      )}
      <DragDropContext onDragEnd={handleDragProjectTag}>
        <Droppable droppableId='projectTagList'>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {compact(compact(concat<ITag | TagCreateEvent | null>([tagCreateEvent], projectTagList)).map(TagElement))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
