import {useEffect, useState, Dispatch, SetStateAction} from 'react'
import {Controller} from 'react-hook-form'
import {selectTheme, selectWorkspaces} from '../../store'
import {v, useForm, RIF, useCurrentProjectState, useCurrentWorkspaceState} from '../../lib'
import {AboutProjectIcon} from '../../asset/image'
import {Input, LabelBox, Textarea} from '..'

const schema = v.object({
  name: v.string().max(50).required(),
  investigator: v.string().max(50).optional(),
  organization: v.string().max(50).optional(),
  description: v.string().max(250).optional(),
})

export interface AboutProjectBlockPropsT {
  setAboutProjectValueFilled: Dispatch<SetStateAction<boolean>>
  setAboutProjectValue: Dispatch<
    SetStateAction<Record<'name' | 'description' | 'investigator' | 'organization', string>>
  >
  aboutProjectValue: Record<'name' | 'description' | 'investigator' | 'organization', string>
}

export const AboutProjectBlock = (props: AboutProjectBlockPropsT) => {
  const {pad, color, fontWeight} = selectTheme()
  const [projectNameWordCount, setProjectNameWordCount] = useState(0)
  const [projectInvestigatorWordCount, setProjectInvestigatorWordCount] = useState(0)
  const [organizationWordCount, setOrganizationWordCount] = useState(0)
  const [introductionWordCount, setIntroductionWordCount] = useState(0)
  const workspacesState = selectWorkspaces()
  const {workspaceId} = useCurrentWorkspaceState()
  const role = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'

  const wordCountMaximum = {
    projectName: 50,
    projectInvestigator: 50,
    organization: 50,
    introduction: 250,
  }

  const handleWordCount = (
    e: any,
    type: 'projectName' | 'projectInvestigator' | 'organization' | 'introduction',
    wordCountSetter: Dispatch<SetStateAction<number>>,
  ) => {
    e.target.value = e.target.value.slice(0, wordCountMaximum[type])
    wordCountSetter(e.target.value.length)
  }

  const {setAboutProjectValueFilled, setAboutProjectValue, aboutProjectValue} = props

  const {
    control,
    watch,
    formState: {errors},
  } = useForm({schema})

  // toggle the left checkIcon color according to input field completed
  useEffect(() => {
    if (
      aboutProjectValue?.name &&
      aboutProjectValue?.investigator &&
      aboutProjectValue?.organization &&
      aboutProjectValue?.description
    ) {
      setAboutProjectValueFilled(true)
    }
    if (
      aboutProjectValue?.name === '' ||
      aboutProjectValue?.investigator === '' ||
      aboutProjectValue?.organization === '' ||
      aboutProjectValue?.description === ''
    ) {
      setAboutProjectValueFilled(false)
    }
    // when reload set default value
    if (aboutProjectValue?.name) setProjectNameWordCount(aboutProjectValue?.name?.length)
    if (aboutProjectValue?.investigator) setProjectInvestigatorWordCount(aboutProjectValue?.investigator?.length)
    if (aboutProjectValue?.organization) setOrganizationWordCount(aboutProjectValue?.organization?.length)
    if (aboutProjectValue?.description) setIntroductionWordCount(aboutProjectValue?.description?.length)
  }, [
    aboutProjectValue?.name,
    aboutProjectValue?.investigator,
    aboutProjectValue?.organization,
    aboutProjectValue?.description,
  ])

  // setValue if initial watch() not undefined
  useEffect(() => {
    if (watch('name') !== undefined) {
      setAboutProjectValue((prevState: any) => ({
        ...prevState,
        name: watch('name'),
      }))
    }
    if (watch('investigator') !== undefined) {
      setAboutProjectValue((prevState: any) => ({
        ...prevState,
        investigator: watch('investigator'),
      }))
    }
    if (watch('organization') !== undefined) {
      setAboutProjectValue((prevState: any) => ({
        ...prevState,
        organization: watch('organization'),
      }))
    }
    if (watch('description') !== undefined) {
      setAboutProjectValue((prevState: any) => ({
        ...prevState,
        description: watch('description'),
      }))
    }
  }, [watch('name'), watch('investigator'), watch('organization'), watch('description')])

  return (
    <div
      css={{
        display: 'flex',
        background: color.white,
        borderRadius: '5px;',
        width: '55vw',
        marginBottom: pad.large,
        boxShadow: '0px 4px 12px 0px #D4D4D440',
      }}
    >
      {/* left */}
      <div
        css={{
          padding: pad.large,
          borderRight: `1px solid ${color.grey_100}`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '26%',
        }}
      >
        <div>
          <div
            css={{
              width: '40px',
              height: '40px',
              background: color.background,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: pad.large,
            }}
          >
            <img src={AboutProjectIcon} width='24px' />
          </div>
          <p
            css={{
              fontSize: '18px',
              fontWeight: fontWeight.thick,
              marginBottom: pad.medium,
            }}
          >
            About Project
          </p>

          <p css={{lineHeight: '19px', fontWeight: fontWeight.regular}}>
            Provide more details about your project for participants.
          </p>
        </div>
      </div>

      {/* right */}
      <div css={{padding: pad.xl, width: '74%'}}>
        <p
          css={{
            fontSize: '14px',
            fontWeight: fontWeight.medium,
            marginBottom: pad.xl,
          }}
        >
          Project information as seen by participants:
        </p>

        {/* projectName */}
        <LabelBox
          {...{
            label: 'Project Name',
            required: true,
            leftCharacters: wordCountMaximum.projectName - projectNameWordCount,
          }}
        >
          <>
            <Controller
              control={control}
              name='name'
              render={({field: {name, value, onChange}}) => (
                <Input
                  readOnly={role === 'viewer'}
                  {...{
                    name: name,
                    value: aboutProjectValue?.name,
                    onChange: onChange,
                    onInput: (e: any) => handleWordCount(e, 'projectName', setProjectNameWordCount),
                  }}
                  maxLength='50'
                  placeholder='Labfront Sleep Study'
                  css={{marginBottom: '27px'}}
                />
              )}
            />
          </>
        </LabelBox>

        {/* Investigator */}
        <LabelBox
          {...{
            label: 'Principal Investigator',
            required: true,
            leftCharacters: wordCountMaximum.projectInvestigator - projectInvestigatorWordCount,
          }}
        >
          <>
            <Controller
              control={control}
              name='investigator'
              render={({field: {name, value, onChange}}) => (
                <Input
                  readOnly={role === 'viewer'}
                  {...{
                    name: name,
                    value: aboutProjectValue?.investigator,
                    onChange: onChange,
                    onInput: (e: any) => handleWordCount(e, 'projectInvestigator', setProjectInvestigatorWordCount),
                  }}
                  maxLength='50'
                  placeholder='Dr. Luna Shiba'
                  css={{marginBottom: '27px'}}
                />
              )}
            />
          </>
        </LabelBox>

        {/* Organization */}
        <LabelBox
          {...{
            label: 'Organization or Institution',
            required: true,
            leftCharacters: wordCountMaximum.organization - organizationWordCount,
          }}
        >
          <>
            <Controller
              control={control}
              name='organization'
              render={({field: {name, value, onChange}}) => (
                <Input
                  readOnly={role === 'viewer'}
                  {...{
                    name: name,
                    value: aboutProjectValue?.organization,
                    onChange: onChange,
                    onInput: (e: any) => handleWordCount(e, 'organization', setOrganizationWordCount),
                  }}
                  maxLength='50'
                  placeholder='Labfront'
                  css={{marginBottom: '27px'}}
                />
              )}
            />
          </>
        </LabelBox>

        {/* Introduction */}
        <LabelBox
          {...{
            label: 'Introduction',
            required: true,
            leftCharacters: wordCountMaximum.introduction - introductionWordCount,
          }}
        >
          <>
            <Controller
              control={control}
              name='description'
              render={({field: {name, value, onChange}}) => (
                <Textarea
                  readOnly={role === 'viewer'}
                  {...{
                    name: name,
                    value: aboutProjectValue?.description,
                    onChange: onChange,
                    onInput: (e) => handleWordCount(e, 'introduction', setIntroductionWordCount),
                  }}
                  maxLength={250}
                  placeholder='Briefly introduce your study here.'
                  rows={4}
                />
              )}
            />
          </>
        </LabelBox>
      </div>
    </div>
  )
}
