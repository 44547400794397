import {selectTheme} from '../../../store/state/theme'
import {defaultSvgProps, SvgProps} from '../type/props'

export const EyeIcon = (props?: SvgProps) => {
  const {color} = selectTheme()
  const {width, height, fill, flexShrink} = defaultSvgProps(props, {
    width: 16,
    height: 16,
    fill: color.textIcon.title,
    flexShrink: 0,
  })

  return (
    <svg
      width={width}
      height={height}
      style={{flexShrink}}
      viewBox='0 0 16 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.99984 1.99996C10.5265 1.99996 12.7798 3.41996 13.8798 5.66663C12.7798 7.91329 10.5265 9.33329 7.99984 9.33329C5.47317 9.33329 3.21984 7.91329 2.11984 5.66663C3.21984 3.41996 5.47317 1.99996 7.99984 1.99996ZM7.99984 0.666626C4.6665 0.666626 1.81984 2.73996 0.666504 5.66663C1.81984 8.59329 4.6665 10.6666 7.99984 10.6666C11.3332 10.6666 14.1798 8.59329 15.3332 5.66663C14.1798 2.73996 11.3332 0.666626 7.99984 0.666626ZM7.99984 3.99996C8.91984 3.99996 9.6665 4.74663 9.6665 5.66663C9.6665 6.58663 8.91984 7.33329 7.99984 7.33329C7.07984 7.33329 6.33317 6.58663 6.33317 5.66663C6.33317 4.74663 7.07984 3.99996 7.99984 3.99996ZM7.99984 2.66663C6.3465 2.66663 4.99984 4.01329 4.99984 5.66663C4.99984 7.31996 6.3465 8.66663 7.99984 8.66663C9.65317 8.66663 10.9998 7.31996 10.9998 5.66663C10.9998 4.01329 9.65317 2.66663 7.99984 2.66663Z'
        fill={fill}
      />
    </svg>
  )
}
