import {selectTheme} from '../../store'

export const TextLink = (props) => {
  const {color, fontWeight} = selectTheme()

  const {disabled, textColor} = props

  return (
    <a
      {...props}
      css={{
        border: 'none',
        backgroundColor: 'transparent',
        color: disabled ? color.disabled : textColor ? color[textColor] : color.primary,
        fontWeight: fontWeight.bold,
        cursor: disabled ? 'auto' : 'pointer',
        borderRadius: '5px',
        padding: '12px 15px',
        ':hover': {
          background: color.hover,
        },
        ':focus': {
          outline: 'none',
        },
      }}
    >
      {props.children}
    </a>
  )
}
