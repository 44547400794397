import {
  FieldValues,
  FieldPath,
  RegisterOptions,
  UseFormRegister,
  UseFormRegisterReturn,
  UseFormHandleSubmit,
} from 'react-hook-form'

type RegisterNameReturn<T extends FieldValues> = (
  options?: RegisterOptions<T, FieldPath<T>>,
) => UseFormRegisterReturn<FieldPath<T>>

export const registerName =
  <T extends FieldValues>(register: UseFormRegister<T>, name: FieldPath<T>): RegisterNameReturn<T> =>
  (options) =>
    register(name, options)

export type Register<T extends FieldValues> = RegisterNameReturn<T>

export const stopPropagate = <T extends FieldValues>(handleSubmit: UseFormHandleSubmit<T>): UseFormHandleSubmit<T> => {
  return (onValid, onInvalid) => {
    return handleSubmit((data, event) => {
      event?.preventDefault()
      event?.stopPropagation()
      onValid(data)
    }, onInvalid)
  }
}
