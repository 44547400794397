import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {selectTheme} from '../../store'
import {
  DefaultTaskScheduleSettingsTextStyle,
  TaskScheduleSettingsRelativeDayInput,
} from './task_schedule_settings_input'
import {ScheduleFormValidationErrorMessages} from '../../model/schedule'
import {useMount} from 'react-use'
import {registerName} from '../../lib/form'

interface TaskScheduleSettingsRecurringMultipleTimesInOneDayRelativeForm {
  day: number
}

export interface TaskScheduleSettingsRecurringMultipleTimesInOneDayRelativeFormProps {
  values: Partial<TaskScheduleSettingsRecurringMultipleTimesInOneDayRelativeForm>
  onValidated: (form: TaskScheduleSettingsRecurringMultipleTimesInOneDayRelativeForm) => void
  onError: () => void
}

export const TaskScheduleSettingsRecurringMultipleTimesInOneDayRelative = (
  props: TaskScheduleSettingsRecurringMultipleTimesInOneDayRelativeFormProps,
) => {
  const {values, onValidated, onError} = props

  const {color, fontSize, fontWeight} = selectTheme()

  const textStyle = DefaultTaskScheduleSettingsTextStyle({color, fontSize, fontWeight})

  const {
    register,
    formState: {isValidating, isValid, errors},
    trigger,
    getValues,
  } = useForm<TaskScheduleSettingsRecurringMultipleTimesInOneDayRelativeForm>({
    mode: 'onChange',
    defaultValues: {
      day: values.day ?? 1,
    },
  })

  const [latestErrorMessage, setLatestErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    for (const error of Object.values(errors)) {
      if (error?.message) {
        onError()
        setLatestErrorMessage(error.message)
        return
      }
    }
    setLatestErrorMessage(null)
  }, [errors.day])

  useMount(() => {
    trigger()
  })

  useEffect(() => {
    if (isValid && !isValidating) {
      const form = getValues()
      onValidated(form)
    }
  }, [isValid, isValidating])

  return (
    <div css={{display: 'flex', flexDirection: 'column', gap: 16}}>
      <div>
        <span css={{...textStyle}}>The task will recur multiple times on</span>
        <TaskScheduleSettingsRelativeDayInput
          register={registerName(register, 'day')}
          failedValidation={!!errors.day}
          {...{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Day'),
            },
            min: {
              value: 1,
              message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('Day'),
            },
          }}
        />
      </div>
      {latestErrorMessage && <p css={{...textStyle, color: color.warning}}>{latestErrorMessage}</p>}
    </div>
  )
}
