import {CSSProperties} from 'react'
import {selectTheme} from '../../store'
import {EmotionJSX} from '@emotion/react/types/jsx-namespace'

export const ButtonText = (props: {
  children: EmotionJSX.Element | string
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  textColor?: string
  textColorWhileHovered?: string
  backgroundColorWhileHovered?: string
  css?: CSSProperties
}) => {
  const {color, fontWeight, borderRadius} = selectTheme()
  const {children, onClick, textColor, textColorWhileHovered, backgroundColorWhileHovered, css} = props

  return (
    <button
      onClick={onClick}
      css={{
        ...css,
        display: 'inline-flex',
        alignItems: 'center',
        height: '40px',
        padding: '0 16px',
        borderRadius: borderRadius.default,
        border: 'none',
        backgroundColor: 'transparent',
        fontWeight: fontWeight.thick,
        color: textColor ?? color.textIcon.link,
        cursor: 'pointer',
        ':hover': {
          color: textColorWhileHovered ?? color.surface.hover.blue,
          backgroundColor: backgroundColorWhileHovered ?? color.surface.blue.light,
        },
      }}
    >
      {children}
    </button>
  )
}
