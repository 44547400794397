import { IEntityModel } from './base'

export enum ScheduleType {
  Absolute = 'absolute',
  Relative = 'relative',
  Recurring = 'recurring',
}

export enum EmaType {
  Random = 'random',
  SemiRandom = 'semiRandom',
  Fixed = 'fixed',
}

export interface ITaskSchedule extends IEntityModel {
  taskId: string
  type: ScheduleType
  inactive: boolean
  scheduleYear?: number
  scheduleMonth?: number
  scheduleDay?: number
  scheduleHour?: number
  scheduleMinute?: number
  scheduleSecond?: number
  scheduleWeekdays?: number
  relativeStartDayOrYymmdd?: number
  relativeEndDayOrYymmdd?: number
  startTime?: string
  endTime?: string
  emaType?: EmaType
  emaOcurrences?: number
  emaIntervalSeconds?: number
  emaMinimumGapSeconds?: number
  emaExpirationSeconds?: number
}

/*
  date components that represents both absolute and relative date.

  Examples:
    absolute date: { year: 2024, month: 11, day: 30, hour: 18, minute: 45, second: 30 }
    relative date: { day: 2 }
    recurring every Tuesday & Wednesday: { weekdays: 23 }
    recurring every 2 days: { day: 2 } 
    recurring monthly on the 2nd week: { weekOfMonth: 2 }

  References:
    date-fns DateValues: https://date-fns.org/v3.6.0/docs/set#types/DateValues/1840
    date-fns Duration: https://date-fns.org/v3.6.0/docs/formatDuration#types/Duration/1851
    Swift DateComponents: https://developer.apple.com/documentation/foundation/datecomponents
**/
export type ScheduleDateTimeComponents = Pick<
  ITaskSchedule,
  | 'scheduleYear'
  | 'scheduleMonth'
  | 'scheduleDay'
  | 'scheduleHour'
  | 'scheduleMinute'
  | 'scheduleSecond'
  | 'scheduleWeekdays'
>

export type SchedulePeriodComponents = Pick<
  ITaskSchedule,
  | 'relativeStartDayOrYymmdd'
  | 'relativeEndDayOrYymmdd'
  | 'startTime'
  | 'endTime'
>

export type ScheduleEmaComponents = Pick<
  ITaskSchedule,
  | 'emaType'
  | 'emaOcurrences'
  | 'emaIntervalSeconds'
  | 'emaMinimumGapSeconds'
  | 'emaExpirationSeconds'
>

export type ScheduleComparable = Pick<ITaskSchedule, 'type'> &
  ScheduleDateTimeComponents &
  SchedulePeriodComponents &
  ScheduleEmaComponents

export class ScheduleDate {
  constructor(
    readonly year: number,
    readonly month: number,
    readonly day: number,
    readonly hour: number | undefined,
    readonly minute: number | undefined,
    readonly second: number | undefined,
  ) {}

  static fromComponents(
    components: ScheduleDateTimeComponents &
      Required<
        Pick<
          ScheduleDateTimeComponents,
          'scheduleYear' | 'scheduleMonth' | 'scheduleDay'
        >
      >,
  ): ScheduleDate {
    return new ScheduleDate(
      components.scheduleYear,
      components.scheduleMonth,
      components.scheduleDay,
      components.scheduleHour,
      components.scheduleMinute,
      components.scheduleSecond,
    )
  }

  static fromDate(date: Date): ScheduleDate {
    return new ScheduleDate(
      date.getUTCFullYear(),
      date.getUTCMonth() + 1,
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
  }

  static isYymmdd(relativeDayOrYymmdd?: number): boolean {
    if (!relativeDayOrYymmdd) return false
    const digits =
      Math.max(Math.floor(Math.log10(Math.abs(relativeDayOrYymmdd))), 0) + 1
    return digits === 6
  }

  static fromYymmdd(yymmdd: number): ScheduleDate {
    if (!this.isYymmdd(yymmdd)) {
      throw new Error('invalid yymmdd')
    }

    function doYymmdd(n: number, exponent: number, yymmdd: number[]): number[] {
      if (exponent < 0) return yymmdd
      const divisor = Math.pow(10, exponent)
      const [quotient, remainder] = [Math.floor(n / divisor), n % divisor]
      return doYymmdd(remainder, exponent - 2, [...yymmdd, quotient])
    }

    const [yy, mm, dd] = doYymmdd(yymmdd, 4, [])
    return new ScheduleDate(2000 + yy, mm, dd, undefined, undefined, undefined)
  }

  static fromRelativeDay(
    relativeDay: number,
    absoluteBaseDate?: ScheduleDate,
  ): ScheduleDate {
    if (relativeDay < 1) {
      throw new Error('relative day should be greater than 0')
    }
    const date = absoluteBaseDate?.toDate() ?? new Date()
    date.setDate(date.getDate() + relativeDay - 1)
    return ScheduleDate.fromDate(date)
  }

  static checkValidPeriod(start: ScheduleDate, end: ScheduleDate) {
    if (start.toDate().getTime() >= end.toDate().getTime()) {
      throw new Error('end date should be greater than start date')
    }
  }

  withTime(time: ScheduleTime): ScheduleDate {
    return new ScheduleDate(
      this.year,
      this.month,
      this.day,
      time.hours,
      time.minutes,
      time.seconds,
    )
  }

  getWeekday(): number {
    const weekday = this.toDate().getUTCDay()
    return weekday === 0 ? 7 : weekday
  }

  toComponents(): ScheduleDateTimeComponents &
    Required<
      Pick<ScheduleDateTimeComponents, 'scheduleYear' | 'scheduleMonth'>
    > {
    return {
      scheduleYear: this.year,
      scheduleMonth: this.month,
      scheduleDay: this.day,
      scheduleHour: this.hour,
      scheduleMinute: this.minute,
      scheduleSecond: this.second,
    }
  }

  toDate() {
    return new Date(
      Date.UTC(
        this.year,
        this.month - 1,
        this.day,
        this.hour ?? 0,
        this.minute ?? 0,
        this.second ?? 0,
      ),
    )
  }

  toYymmddString(): string {
    return `${this.year.toString().slice(-2)}${this.month
      .toString()
      .padStart(2, '0')}${this.day.toString().padStart(2, '0')}`
  }

  toYymmdd(): number {
    return parseInt(this.toYymmddString())
  }

  isValid(): boolean {
    return !isNaN(this.toDate().getTime())
  }

  isBefore(other: ScheduleDate): boolean {
    return this.toDate().getTime() < other.toDate().getTime()
  }

  isAfter(other: ScheduleDate): boolean {
    return this.toDate().getTime() > other.toDate().getTime()
  }

  isSame(other: ScheduleDate): boolean {
    return this.toDate().getTime() === other.toDate().getTime()
  }
}

export class ScheduleTime {
  // capture groups: hours, minutes, seconds (optional)
  static readonly REGEX = /^([01]\d|2[0-3]):([0-5]\d)(?::([0-5]\d))?$/

  constructor(
    readonly hours: number,
    readonly minutes: number,
    readonly seconds: number | undefined,
  ) {}

  static parse(time: string): ScheduleTime {
    const match = time.match(this.REGEX)
    if (!match) {
      throw new Error('not an schedule time string')
    }
    const [hours, minutes, seconds] = match.slice(1)
    return new ScheduleTime(
      parseInt(hours),
      parseInt(minutes),
      seconds ? parseInt(seconds) : undefined,
    )
  }

  static fromComponents(
    components: ScheduleDateTimeComponents &
      Required<
        Pick<ScheduleDateTimeComponents, 'scheduleHour' | 'scheduleMinute'>
      >,
  ): ScheduleTime {
    return new ScheduleTime(
      components.scheduleHour,
      components.scheduleMinute,
      components.scheduleSecond,
    )
  }

  static fromDate(date: Date): ScheduleTime {
    return new ScheduleTime(
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds() || undefined,
    )
  }

  static fromTotalSeconds(totalSeconds: number): ScheduleTime {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60
    return new ScheduleTime(hours, minutes, seconds)
  }

  static getSecondsInterval(start: ScheduleTime, end: ScheduleTime) {
    return end.totalSeconds() - start.totalSeconds()
  }

  static checkValidInterval(start: ScheduleTime, end: ScheduleTime) {
    if (this.getSecondsInterval(start, end) <= 0) {
      throw new Error('end time should be greater than start time')
    }
  }

  toDate() {
    return new Date(this.totalSeconds() * 1000)
  }

  totalSeconds() {
    return this.hours * 3600 + this.minutes * 60 + (this.seconds ?? 0)
  }

  toDuration() {
    return {
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
    }
  }

  toComponents(): ScheduleDateTimeComponents &
    Required<
      Pick<ScheduleDateTimeComponents, 'scheduleHour' | 'scheduleMinute'>
    > {
    return {
      scheduleHour: this.hours,
      scheduleMinute: this.minutes,
      scheduleSecond: this.seconds,
    }
  }

  toString(): string {
    const format = (value: number) => String(value).padStart(2, '0')
    const components = [this.hours, this.minutes]
    if (this.seconds) {
      components.push(this.seconds)
    }
    return components.map((value) => format(value)).join(':')
  }
}

export enum TaskScheduleTaskType {
  OneTimeOnly = 'oneTimeOnly',
  Recurring = 'recurring',
}

export enum TaskScheduleTaskSubTypeRecurring {
  Daily = 'daily',
  SpecificDaysOfTheWeek = 'specificDaysOfTheWeek',
  EveryXDays = 'everyXDays',
  MultipleTimesInOneDay = 'multipleTimesInOneDay',
}

export enum TaskScheduleScheduleType {
  FixedSchedule = 'fixedSchedule',
  FixedEMA = 'fixedEMA',
  SemiRandomizedEMA = 'semiRandomizedEMA',
  FullyRandomizedEMA = 'fullyRandomizedEMA',
}

export enum TaskScheduleDateType {
  Absolute = 'absolute',
  Relative = 'relative',
}

export type ScheduleTypeParsed = {
  dateType: TaskScheduleDateType
} & (
  | {
      taskType: TaskScheduleTaskType.OneTimeOnly
      scheduleType:
        | TaskScheduleScheduleType.FixedSchedule
        | TaskScheduleScheduleType.FullyRandomizedEMA
    }
  | {
      taskType: TaskScheduleTaskType.Recurring
      taskSubType:
        | TaskScheduleTaskSubTypeRecurring.Daily
        | TaskScheduleTaskSubTypeRecurring.EveryXDays
        | TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek
      scheduleType: TaskScheduleScheduleType
    }
  | {
      taskType: TaskScheduleTaskType.Recurring
      taskSubType: TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay
      scheduleType:
        | TaskScheduleScheduleType.FixedEMA
        | TaskScheduleScheduleType.SemiRandomizedEMA
        | TaskScheduleScheduleType.FullyRandomizedEMA
    }
)

export class ScheduleParser {
  constructor(private readonly schedule: Partial<ITaskSchedule>) {}

  getDateTimeComponents(): Partial<ScheduleDateTimeComponents> {
    const {
      scheduleYear,
      scheduleMonth,
      scheduleDay,
      scheduleHour,
      scheduleMinute,
      scheduleSecond,
      scheduleWeekdays,
    } = this.schedule
    return {
      scheduleYear,
      scheduleMonth,
      scheduleDay,
      scheduleHour,
      scheduleMinute,
      scheduleSecond,
      scheduleWeekdays,
    }
  }

  getSchedulePeriodComponents(): Partial<SchedulePeriodComponents> {
    const {
      relativeStartDayOrYymmdd,
      relativeEndDayOrYymmdd,
      startTime: dailyStartTime,
      endTime: dailyEndTime,
    } = this.schedule
    return {
      relativeStartDayOrYymmdd,
      relativeEndDayOrYymmdd,
      startTime: dailyStartTime,
      endTime: dailyEndTime,
    }
  }

  getScheduleEmaComponents(): Partial<ScheduleEmaComponents> {
    const {
      emaType,
      emaOcurrences,
      emaIntervalSeconds,
      emaMinimumGapSeconds,
      emaExpirationSeconds,
    } = this.schedule
    return {
      emaType,
      emaOcurrences,
      emaIntervalSeconds,
      emaMinimumGapSeconds,
      emaExpirationSeconds,
    }
  }

  getComparableSchedule(): Partial<ScheduleComparable> {
    return {
      type: this.schedule.type,
      ...this.getDateTimeComponents(),
      ...this.getSchedulePeriodComponents(),
      ...this.getScheduleEmaComponents(),
    }
  }

  getExistingComparableSchedule(): Partial<ScheduleComparable> {
    return this.filterExists(this.getComparableSchedule())
  }

  tryParse(): ScheduleTypeParsed {
    const existingComponents = this.getExistingComparableSchedule()

    const isOneTimeOnlyFixedSchedule =
      this.isOneTimeOnlyFixedSchedule(existingComponents)
    if (isOneTimeOnlyFixedSchedule) {
      return {
        taskType: TaskScheduleTaskType.OneTimeOnly,
        scheduleType: TaskScheduleScheduleType.FixedSchedule,
        dateType: isOneTimeOnlyFixedSchedule.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    if (this.isOneTimeOnlyFullyRandomizedEMAAbsolute(existingComponents)) {
      return {
        taskType: TaskScheduleTaskType.OneTimeOnly,
        scheduleType: TaskScheduleScheduleType.FullyRandomizedEMA,
        dateType: TaskScheduleDateType.Absolute,
      }
    }

    if (this.isOneTimeOnlyFullyRandomizedEMARelative(existingComponents)) {
      return {
        taskType: TaskScheduleTaskType.OneTimeOnly,
        scheduleType: TaskScheduleScheduleType.FullyRandomizedEMA,
        dateType: TaskScheduleDateType.Relative,
      }
    }

    const isRecurringDailyFixedSchedule =
      this.isRecurringDailyFixedSchedule(existingComponents)
    if (isRecurringDailyFixedSchedule) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.Daily,
        scheduleType: TaskScheduleScheduleType.FixedSchedule,
        dateType: isRecurringDailyFixedSchedule.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringDailyFixedEma =
      this.isRecurringDailyFixedEma(existingComponents)
    if (isRecurringDailyFixedEma) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.Daily,
        scheduleType: TaskScheduleScheduleType.FixedEMA,
        dateType: isRecurringDailyFixedEma.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringDailySemiRandomizedEma =
      this.isRecurringDailySemiRandomizedEma(existingComponents)
    if (isRecurringDailySemiRandomizedEma) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.Daily,
        scheduleType: TaskScheduleScheduleType.SemiRandomizedEMA,
        dateType: isRecurringDailySemiRandomizedEma.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringDailyFullyRandomizedEma =
      this.isRecurringDailyFullyRandomizedEma(existingComponents)
    if (isRecurringDailyFullyRandomizedEma) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.Daily,
        scheduleType: TaskScheduleScheduleType.FullyRandomizedEMA,
        dateType: isRecurringDailyFullyRandomizedEma.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringSpecificDaysOfTheWeekFixedSchedule =
      this.isRecurringSpecificDaysOfTheWeekFixedSchedule(existingComponents)
    if (isRecurringSpecificDaysOfTheWeekFixedSchedule) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek,
        scheduleType: TaskScheduleScheduleType.FixedSchedule,
        dateType: isRecurringSpecificDaysOfTheWeekFixedSchedule.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringSpecificDaysOfTheWeekFixedEma =
      this.isRecurringSpecificDaysOfTheWeekFixedEma(existingComponents)
    if (isRecurringSpecificDaysOfTheWeekFixedEma) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek,
        scheduleType: TaskScheduleScheduleType.FixedEMA,
        dateType: isRecurringSpecificDaysOfTheWeekFixedEma.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringSpecificDaysOfTheWeekSemiRandomizedEma =
      this.isRecurringSpecificDaysOfTheWeekSemiRandomizedEma(existingComponents)
    if (isRecurringSpecificDaysOfTheWeekSemiRandomizedEma) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek,
        scheduleType: TaskScheduleScheduleType.SemiRandomizedEMA,
        dateType: isRecurringSpecificDaysOfTheWeekSemiRandomizedEma.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringSpecificDaysOfTheWeekFullyRandomizedEma =
      this.isRecurringSpecificDaysOfTheWeekFullyRandomizedEma(
        existingComponents,
      )
    if (isRecurringSpecificDaysOfTheWeekFullyRandomizedEma) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek,
        scheduleType: TaskScheduleScheduleType.FullyRandomizedEMA,
        dateType: isRecurringSpecificDaysOfTheWeekFullyRandomizedEma.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringEveryXDaysFixedSchedule =
      this.isRecurringEveryXDaysFixedSchedule(existingComponents)
    if (isRecurringEveryXDaysFixedSchedule) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.EveryXDays,
        scheduleType: TaskScheduleScheduleType.FixedSchedule,
        dateType: isRecurringEveryXDaysFixedSchedule.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringEveryXDaysFixedEma =
      this.isRecurringEveryXDaysFixedEma(existingComponents)
    if (isRecurringEveryXDaysFixedEma) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.EveryXDays,
        scheduleType: TaskScheduleScheduleType.FixedEMA,
        dateType: isRecurringEveryXDaysFixedEma.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringEveryXDaysSemiRandomizedEma =
      this.isRecurringEveryXDaysSemiRandomizedEma(existingComponents)
    if (isRecurringEveryXDaysSemiRandomizedEma) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.EveryXDays,
        scheduleType: TaskScheduleScheduleType.SemiRandomizedEMA,
        dateType: isRecurringEveryXDaysSemiRandomizedEma.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    const isRecurringEveryXDaysFullyRandomizedEma =
      this.isRecurringEveryXDaysFullyRandomizedEma(existingComponents)
    if (isRecurringEveryXDaysFullyRandomizedEma) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.EveryXDays,
        scheduleType: TaskScheduleScheduleType.FullyRandomizedEMA,
        dateType: isRecurringEveryXDaysFullyRandomizedEma.isAbsolute
          ? TaskScheduleDateType.Absolute
          : TaskScheduleDateType.Relative,
      }
    }

    if (
      this.isRecurringMultipleTimesInOneDayFixedEmaAbsolute(existingComponents)
    ) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay,
        scheduleType: TaskScheduleScheduleType.FixedEMA,
        dateType: TaskScheduleDateType.Absolute,
      }
    }

    if (
      this.isRecurringMultipleTimesInOneDayFixedEmaRelative(existingComponents)
    ) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay,
        scheduleType: TaskScheduleScheduleType.FixedEMA,
        dateType: TaskScheduleDateType.Relative,
      }
    }

    if (
      this.isRecurringMultipleTimesInOneDaySemiRandomizedEmaAbsolute(
        existingComponents,
      )
    ) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay,
        scheduleType: TaskScheduleScheduleType.SemiRandomizedEMA,
        dateType: TaskScheduleDateType.Absolute,
      }
    }

    if (
      this.isRecurringMultipleTimesInOneDaySemiRandomizedEmaRelative(
        existingComponents,
      )
    ) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay,
        scheduleType: TaskScheduleScheduleType.SemiRandomizedEMA,
        dateType: TaskScheduleDateType.Relative,
      }
    }

    if (
      this.isRecurringMultipleTimesInOneDayFullyRandomizedEmaAbsolute(
        existingComponents,
      )
    ) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay,
        scheduleType: TaskScheduleScheduleType.FullyRandomizedEMA,
        dateType: TaskScheduleDateType.Absolute,
      }
    }

    if (
      this.isRecurringMultipleTimesInOneDayFullyRandomizedEmaRelative(
        existingComponents,
      )
    ) {
      return {
        taskType: TaskScheduleTaskType.Recurring,
        taskSubType: TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay,
        scheduleType: TaskScheduleScheduleType.FullyRandomizedEMA,
        dateType: TaskScheduleDateType.Relative,
      }
    }

    throw new Error('failed parsing task schedule')
  }

  private isOneTimeOnlyFixedSchedule(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
      ],
      ['relativeEndDayOrYymmdd', 'endTime'],
    )
    if (!isKeysValid) return false

    switch (existingComponents.type) {
      case ScheduleType.Absolute:
        this.checkValidAbsoluteDateTimePeriod(existingComponents)
        return { isAbsolute: true }
      case ScheduleType.Relative:
        this.checkValidRelativeDateTimePeriod(existingComponents)
        return { isAbsolute: false }
      default:
        return false
    }
  }

  private isOneTimeOnlyFullyRandomizedEMAAbsolute(
    existingComponents: Partial<ScheduleComparable>,
  ): boolean {
    const isKeysValid = this.checkOnlyKeysExists(existingComponents, [
      'type',
      'scheduleYear',
      'scheduleMonth',
      'scheduleDay',
      'startTime',
      'endTime',
      'emaType',
      'emaExpirationSeconds',
    ])
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Absolute &&
      existingComponents.emaType === EmaType.Random
    ) {
      this.checkValidAbsoluteDate(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidOneTimeRandomEma(existingComponents)
      return true
    }

    return false
  }

  private isOneTimeOnlyFullyRandomizedEMARelative(
    existingComponents: Partial<ScheduleComparable>,
  ): boolean {
    const isKeysValid = this.checkOnlyKeysExists(existingComponents, [
      'type',
      'scheduleDay',
      'startTime',
      'endTime',
      'emaType',
      'emaExpirationSeconds',
    ])
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Relative &&
      existingComponents.emaType === EmaType.Random
    ) {
      this.checkValidDay(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidOneTimeRandomEma(existingComponents)
      return true
    }

    return false
  }

  private isRecurringDailyFixedSchedule(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (existingComponents.type === ScheduleType.Recurring) {
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringDailyFixedEma(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
        'emaType',
        'emaIntervalSeconds',
        'emaExpirationSeconds',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.Fixed
    ) {
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidFixedEma(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringDailySemiRandomizedEma(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
        'emaType',
        'emaIntervalSeconds',
        'emaMinimumGapSeconds',
        'emaExpirationSeconds',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.SemiRandom
    ) {
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidSemiRandomEma(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringDailyFullyRandomizedEma(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
        'emaType',
        'emaOcurrences',
        'emaMinimumGapSeconds',
        'emaExpirationSeconds',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.Random
    ) {
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidRandomEma(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringSpecificDaysOfTheWeekFixedSchedule(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'scheduleWeekdays',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (existingComponents.type === ScheduleType.Recurring) {
      this.checkValidWeekdays(existingComponents)
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringSpecificDaysOfTheWeekFixedEma(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'scheduleWeekdays',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
        'emaType',
        'emaIntervalSeconds',
        'emaExpirationSeconds',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.Fixed
    ) {
      this.checkValidWeekdays(existingComponents)
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidFixedEma(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringSpecificDaysOfTheWeekSemiRandomizedEma(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'scheduleWeekdays',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
        'emaType',
        'emaIntervalSeconds',
        'emaMinimumGapSeconds',
        'emaExpirationSeconds',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.SemiRandom
    ) {
      this.checkValidWeekdays(existingComponents)
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidSemiRandomEma(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringSpecificDaysOfTheWeekFullyRandomizedEma(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'scheduleWeekdays',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
        'emaType',
        'emaOcurrences',
        'emaMinimumGapSeconds',
        'emaExpirationSeconds',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.Random
    ) {
      this.checkValidWeekdays(existingComponents)
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidRandomEma(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringEveryXDaysFixedSchedule(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'scheduleDay',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (existingComponents.type === ScheduleType.Recurring) {
      this.checkValidDay(existingComponents)
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringEveryXDaysFixedEma(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'scheduleDay',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
        'emaType',
        'emaIntervalSeconds',
        'emaExpirationSeconds',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.Fixed
    ) {
      this.checkValidDay(existingComponents)
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidFixedEma(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringEveryXDaysSemiRandomizedEma(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'scheduleDay',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
        'emaType',
        'emaIntervalSeconds',
        'emaMinimumGapSeconds',
        'emaExpirationSeconds',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.SemiRandom
    ) {
      this.checkValidDay(existingComponents)
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidSemiRandomEma(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringEveryXDaysFullyRandomizedEma(
    existingComponents: Partial<ScheduleComparable>,
  ): { isAbsolute: boolean } | false {
    const isKeysValid = this.checkOnlyKeysExists(
      existingComponents,
      [
        'type',
        'scheduleDay',
        'relativeStartDayOrYymmdd',
        'relativeEndDayOrYymmdd',
        'startTime',
        'endTime',
        'emaType',
        'emaOcurrences',
        'emaMinimumGapSeconds',
        'emaExpirationSeconds',
      ],
      ['relativeEndDayOrYymmdd'],
    )
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.Random
    ) {
      this.checkValidDay(existingComponents)
      const { isAbsolute } = this.checkValidDatePeriod(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidRandomEma(existingComponents)
      return { isAbsolute }
    }

    return false
  }

  private isRecurringMultipleTimesInOneDayFixedEmaAbsolute(
    existingComponents: Partial<ScheduleComparable>,
  ): boolean {
    const isKeysValid = this.checkOnlyKeysExists(existingComponents, [
      'type',
      'scheduleYear',
      'scheduleMonth',
      'scheduleDay',
      'startTime',
      'endTime',
      'emaType',
      'emaIntervalSeconds',
      'emaExpirationSeconds',
    ])
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.Fixed
    ) {
      this.checkValidAbsoluteDate(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidFixedEma(existingComponents)
      return true
    }

    return false
  }

  private isRecurringMultipleTimesInOneDayFixedEmaRelative(
    existingComponents: Partial<ScheduleComparable>,
  ): boolean {
    const isKeysValid = this.checkOnlyKeysExists(existingComponents, [
      'type',
      'scheduleDay',
      'startTime',
      'endTime',
      'emaType',
      'emaIntervalSeconds',
      'emaExpirationSeconds',
    ])
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.Fixed
    ) {
      this.checkValidDay(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidFixedEma(existingComponents)
      return true
    }

    return false
  }

  private isRecurringMultipleTimesInOneDaySemiRandomizedEmaAbsolute(
    existingComponents: Partial<ScheduleComparable>,
  ): boolean {
    const isKeysValid = this.checkOnlyKeysExists(existingComponents, [
      'type',
      'scheduleYear',
      'scheduleMonth',
      'scheduleDay',
      'startTime',
      'endTime',
      'emaType',
      'emaIntervalSeconds',
      'emaMinimumGapSeconds',
      'emaExpirationSeconds',
    ])
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.SemiRandom
    ) {
      this.checkValidAbsoluteDate(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidSemiRandomEma(existingComponents)
      return true
    }

    return false
  }

  private isRecurringMultipleTimesInOneDaySemiRandomizedEmaRelative(
    existingComponents: Partial<ScheduleComparable>,
  ): boolean {
    const isKeysValid = this.checkOnlyKeysExists(existingComponents, [
      'type',
      'scheduleDay',
      'startTime',
      'endTime',
      'emaType',
      'emaIntervalSeconds',
      'emaMinimumGapSeconds',
      'emaExpirationSeconds',
    ])
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.SemiRandom
    ) {
      this.checkValidDay(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidSemiRandomEma(existingComponents)
      return true
    }

    return false
  }

  private isRecurringMultipleTimesInOneDayFullyRandomizedEmaAbsolute(
    existingComponents: Partial<ScheduleComparable>,
  ): boolean {
    const isKeysValid = this.checkOnlyKeysExists(existingComponents, [
      'type',
      'scheduleYear',
      'scheduleMonth',
      'scheduleDay',
      'startTime',
      'endTime',
      'emaType',
      'emaOcurrences',
      'emaMinimumGapSeconds',
      'emaExpirationSeconds',
    ])
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.Random
    ) {
      this.checkValidAbsoluteDate(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidRandomEma(existingComponents)
      return true
    }

    return false
  }

  private isRecurringMultipleTimesInOneDayFullyRandomizedEmaRelative(
    existingComponents: Partial<ScheduleComparable>,
  ): boolean {
    const isKeysValid = this.checkOnlyKeysExists(existingComponents, [
      'type',
      'scheduleDay',
      'startTime',
      'endTime',
      'emaType',
      'emaOcurrences',
      'emaMinimumGapSeconds',
      'emaExpirationSeconds',
    ])
    if (!isKeysValid) return false

    if (
      existingComponents.type === ScheduleType.Recurring &&
      existingComponents.emaType === EmaType.Random
    ) {
      this.checkValidDay(existingComponents)
      this.checkValidTimePeriod(existingComponents)
      this.checkValidRandomEma(existingComponents)
      return true
    }

    return false
  }

  private checkOnlyKeysExists(
    existingComponents: Partial<ScheduleComparable>,
    expectedKeys: (keyof ScheduleComparable)[],
    optionalKeys: (keyof ScheduleComparable)[] = [],
  ): boolean {
    const existingKeys = Object.keys(
      existingComponents,
    ) as (keyof ScheduleComparable)[]
    const filteredExistingKeys =
      optionalKeys.length > 0
        ? existingKeys.filter((key) => !optionalKeys.includes(key))
        : existingKeys
    const filteredExpectedKeys =
      optionalKeys.length > 0
        ? expectedKeys.filter((key) => !optionalKeys.includes(key))
        : expectedKeys
    return this.isElementsEqual(filteredExistingKeys, filteredExpectedKeys)
  }

  private checkValidAbsoluteDate(components: Partial<ScheduleComparable>) {
    const { scheduleYear, scheduleMonth, scheduleDay } = components
    if (!(scheduleYear && scheduleMonth && scheduleDay)) {
      throw new Error('absolute date components missing')
    }
    const scheduleDate = ScheduleDate.fromComponents({
      scheduleYear,
      scheduleMonth,
      scheduleDay,
    })
    if (!scheduleDate.isValid()) {
      throw new Error('absolute date is not valid')
    }
  }

  private checkValidDatePeriod(components: Partial<ScheduleComparable>): {
    isAbsolute: boolean
  } {
    const { relativeStartDayOrYymmdd, relativeEndDayOrYymmdd } = components
    if (!relativeStartDayOrYymmdd) {
      throw new Error('date period components missing')
    }

    if (!relativeEndDayOrYymmdd) {
      return { isAbsolute: ScheduleDate.isYymmdd(relativeStartDayOrYymmdd) }
    }

    if (relativeStartDayOrYymmdd > relativeEndDayOrYymmdd) {
      throw new Error('invalid date period')
    }

    const isAbsolute =
      ScheduleDate.isYymmdd(relativeStartDayOrYymmdd) &&
      ScheduleDate.isYymmdd(relativeEndDayOrYymmdd)

    return { isAbsolute }
  }

  private checkValidTimePeriod(components: Partial<ScheduleComparable>) {
    const { startTime, endTime } = components
    if (!(startTime && endTime)) {
      throw new Error('time period components missing')
    }

    const start = ScheduleTime.parse(startTime)
    const end = ScheduleTime.parse(endTime)
    ScheduleTime.checkValidInterval(start, end)
  }

  private checkValidAbsoluteDateTimePeriod(
    components: Partial<ScheduleComparable>,
  ) {
    const {
      relativeStartDayOrYymmdd,
      relativeEndDayOrYymmdd,
      startTime,
      endTime,
    } = components
    if (!(relativeStartDayOrYymmdd && startTime)) {
      throw new Error('start date time components missing')
    }
    if (relativeEndDayOrYymmdd && !endTime) {
      throw new Error('endTime missing')
    }
    if (!relativeEndDayOrYymmdd && endTime) {
      throw new Error('relativeEndDayOrYymmdd missing')
    }

    const start = ScheduleDate.fromYymmdd(relativeStartDayOrYymmdd).withTime(
      ScheduleTime.parse(startTime),
    )
    if (relativeEndDayOrYymmdd && endTime) {
      const end = ScheduleDate.fromYymmdd(relativeEndDayOrYymmdd).withTime(
        ScheduleTime.parse(endTime),
      )
      ScheduleDate.checkValidPeriod(start, end)
    }
  }

  private checkValidRelativeDateTimePeriod(
    components: Partial<ScheduleComparable>,
  ) {
    const {
      relativeStartDayOrYymmdd,
      relativeEndDayOrYymmdd,
      startTime,
      endTime,
    } = components
    if (!(relativeStartDayOrYymmdd && startTime)) {
      throw new Error('start date time components missing')
    }
    if (relativeEndDayOrYymmdd && !endTime) {
      throw new Error('endTime missing')
    }
    if (!relativeEndDayOrYymmdd && endTime) {
      throw new Error('relativeEndDayOrYymmdd missing')
    }

    const start = ScheduleDate.fromRelativeDay(
      relativeStartDayOrYymmdd,
    ).withTime(ScheduleTime.parse(startTime))
    if (relativeEndDayOrYymmdd && endTime) {
      const end = ScheduleDate.fromRelativeDay(relativeEndDayOrYymmdd).withTime(
        ScheduleTime.parse(endTime),
      )
      ScheduleDate.checkValidPeriod(start, end)
    }
  }

  private checkValidDay(components: Partial<ScheduleComparable>) {
    const { scheduleDay } = components
    if (!scheduleDay) {
      throw new Error('scheduleDay missing')
    }
    if (scheduleDay <= 0) {
      throw new Error('scheduleDay should be positive')
    }
  }

  private checkValidWeekdays(components: Partial<ScheduleComparable>) {
    const validWeekdays = new Set([1, 2, 3, 4, 5, 6, 7])
    const { scheduleWeekdays } = components
    if (!scheduleWeekdays) {
      throw new Error('scheduleWeekdays required')
    }

    const validInteger =
      Number.isInteger(scheduleWeekdays) && scheduleWeekdays > 0
    if (!validInteger) {
      throw new Error('scheduleWeekdays not valid integer')
    }

    const weekdays = this.toDigits(scheduleWeekdays)
    if (!this.allUnique(weekdays)) {
      throw new Error('duplicate weekdays in scheduleWeekdays')
    }
    if (!weekdays.every((value) => validWeekdays.has(value))) {
      throw new Error('invalid weekday in scheduleWeekdays')
    }
  }

  private checkValidOneTimeRandomEma(components: Partial<ScheduleComparable>) {
    const {
      emaType,
      emaOcurrences,
      emaIntervalSeconds,
      emaMinimumGapSeconds,
      emaExpirationSeconds,
    } = components
    if (!(emaType && emaExpirationSeconds)) {
      throw new Error('missing one time random ema components')
    }
    if (emaOcurrences || emaIntervalSeconds || emaMinimumGapSeconds) {
      throw new Error(
        'conflicting ema schedule components for one time random ema',
      )
    }

    if (emaType !== EmaType.Random) {
      throw new Error(`ema type should be ${EmaType.Random}`)
    }

    this.checkValidGenericEma(components)
  }

  private checkValidFixedEma(components: Partial<ScheduleComparable>) {
    const {
      emaType,
      emaOcurrences,
      emaIntervalSeconds,
      emaMinimumGapSeconds,
      emaExpirationSeconds,
    } = components
    if (!(emaType && emaIntervalSeconds && emaExpirationSeconds)) {
      throw new Error('missing one time random ema components')
    }
    if (emaOcurrences || emaMinimumGapSeconds) {
      throw new Error('conflicting ema schedule components for fixed ema')
    }

    if (emaType !== EmaType.Fixed) {
      throw new Error(`ema type should be ${EmaType.Fixed}`)
    }

    this.checkValidGenericEma(components)
  }

  private checkValidSemiRandomEma(components: Partial<ScheduleComparable>) {
    const {
      emaType,
      emaOcurrences,
      emaIntervalSeconds,
      emaMinimumGapSeconds,
      emaExpirationSeconds,
    } = components
    if (
      !(
        emaType &&
        emaIntervalSeconds &&
        emaMinimumGapSeconds &&
        emaExpirationSeconds
      )
    ) {
      throw new Error('missing semi random ema components')
    }
    if (emaOcurrences) {
      throw new Error('conflicting ema schedule components for semi random ema')
    }

    if (emaType !== EmaType.SemiRandom) {
      throw new Error(`ema type should be ${EmaType.SemiRandom}`)
    }

    this.checkValidGenericEma(components)

    // semi random specific checks
    if (emaMinimumGapSeconds > emaIntervalSeconds) {
      throw new Error(
        'emaMinimumGapSeconds should not be greater than emaIntervalSeconds',
      )
    }

    if (emaMinimumGapSeconds === emaIntervalSeconds) {
      throw new Error(
        'emaMinimumGapSeconds should not be equal to emaIntervalSeconds, use fixed ema instead',
      )
    }
  }

  private checkValidRandomEma(components: Partial<ScheduleComparable>) {
    const {
      emaType,
      emaOcurrences,
      emaIntervalSeconds,
      emaMinimumGapSeconds,
      emaExpirationSeconds,
    } = components
    if (
      !(
        emaType &&
        emaOcurrences &&
        emaMinimumGapSeconds &&
        emaExpirationSeconds
      )
    ) {
      throw new Error('missing fully random ema components')
    }
    if (emaIntervalSeconds) {
      throw new Error(
        'conflicting ema schedule components for fully random ema',
      )
    }

    if (emaType !== EmaType.Random) {
      throw new Error(`ema type should be ${EmaType.Random}`)
    }

    this.checkValidGenericEma(components)
  }

  private checkValidGenericEma(components: Partial<ScheduleComparable>) {
    const {
      emaType,
      emaOcurrences,
      emaIntervalSeconds,
      emaMinimumGapSeconds,
      emaExpirationSeconds,
    } = components

    if (!emaType) {
      throw new Error('ema type missing')
    }

    if (this.exists(emaOcurrences) && emaOcurrences <= 0) {
      throw new Error('emaOcurrences should be positive')
    }

    if (
      this.exists(emaIntervalSeconds) &&
      (emaIntervalSeconds <= 0 || emaIntervalSeconds >= 3600 * 24)
    ) {
      throw new Error(
        'emaIntervalSeconds should be positive and less than 24 hours',
      )
    }

    if (this.exists(emaMinimumGapSeconds) && emaMinimumGapSeconds <= 0) {
      throw new Error('emaMinimumGapSeconds should be positive')
    }

    if (this.exists(emaExpirationSeconds) && emaExpirationSeconds <= 0) {
      throw new Error('emaExpirationSeconds should be positive')
    }
  }

  private filterExists<T extends object>(record: T): Partial<T> {
    return Object.fromEntries(
      Object.entries(record).filter(([, value]) => this.exists(value)),
    ) as Partial<T>
  }

  private exists<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined
  }

  private isElementsEqual<T>(a: T[], b: T[]): boolean {
    return a.length === b.length && new Set([...a, ...b]).size === a.length
  }

  private toDigits(integer: number): number[] {
    const strArr = String(integer).match(/\d/g)
    return Array.isArray(strArr) ? strArr.map(Number) : []
  }

  private allUnique<T>(array: T[]): boolean {
    return new Set(array).size === array.length
  }
}

export class ScheduleComparer {
  constructor(private readonly schedule: ScheduleComparable) {}

  static isScheduleDateTimeComponentsIdentical(
    a: ScheduleDateTimeComponents,
    b: ScheduleDateTimeComponents,
  ): boolean {
    return (
      this.nullishCompare(a.scheduleYear, b.scheduleYear) &&
      this.nullishCompare(a.scheduleMonth, b.scheduleMonth) &&
      this.nullishCompare(a.scheduleDay, b.scheduleDay) &&
      this.nullishCompare(a.scheduleHour, b.scheduleHour) &&
      this.nullishCompare(a.scheduleMinute, b.scheduleMinute) &&
      this.nullishCompare(a.scheduleSecond, b.scheduleSecond) &&
      this.nullishCompare(a.scheduleWeekdays, b.scheduleWeekdays)
    )
  }

  static isSchedulePeriodComponentsIdentical(
    a: SchedulePeriodComponents,
    b: SchedulePeriodComponents,
  ): boolean {
    return (
      this.nullishCompare(
        a.relativeStartDayOrYymmdd,
        b.relativeStartDayOrYymmdd,
      ) &&
      this.nullishCompare(a.relativeEndDayOrYymmdd, b.relativeEndDayOrYymmdd) &&
      this.nullishCompare(a.startTime, a.startTime) &&
      this.nullishCompare(b.endTime, b.endTime)
    )
  }

  static isScheduleEmaComponentsIdentical(
    a: ScheduleEmaComponents,
    b: ScheduleEmaComponents,
  ): boolean {
    return (
      this.nullishCompare(a.emaType, b.emaType) &&
      this.nullishCompare(a.emaOcurrences, b.emaOcurrences) &&
      this.nullishCompare(a.emaIntervalSeconds, b.emaIntervalSeconds) &&
      this.nullishCompare(a.emaMinimumGapSeconds, b.emaMinimumGapSeconds) &&
      this.nullishCompare(a.emaExpirationSeconds, b.emaExpirationSeconds)
    )
  }

  static isScheduleIdentical(
    a: ScheduleComparable,
    b: ScheduleComparable,
  ): boolean {
    return (
      a.type === b.type &&
      this.isScheduleDateTimeComponentsIdentical(a, b) &&
      this.isSchedulePeriodComponentsIdentical(a, b) &&
      this.isScheduleEmaComponentsIdentical(a, b)
    )
  }

  private static nullishCompare<T>(
    a: T | null | undefined,
    b: T | null | undefined,
  ): boolean {
    if (a && b) {
      return a === b
    }
    return true
  }
}

export type Randomizer = () => number

export class EmaScheduleSimulatorResult {
  constructor(
    private readonly freeIntervalsSeconds: [number, number][],
    private readonly fixedEmaIntervalsSeconds: [number, number][],
    private readonly randomizedEmaIntervalsSeconds: [number, number][],
  ) {}

  static empty() {
    return new EmaScheduleSimulatorResult([], [], [])
  }

  freeIntervals(): [number, number][] {
    return this.freeIntervalsSeconds
  }

  fixedEmaIntervals(): [number, number][] {
    return this.fixedEmaIntervalsSeconds
  }

  randomizedEmaIntervals(): [number, number][] {
    return this.randomizedEmaIntervalsSeconds
  }

  freeDateIntervals(
    absoluteBaseDate: ScheduleDate,
  ): [ScheduleDate, ScheduleDate][] {
    return this.freeIntervalsSeconds.map((interval) =>
      this.toDateInterval(interval, absoluteBaseDate),
    )
  }

  fixedEmaDateIntervals(
    absoluteBaseDate: ScheduleDate,
  ): [ScheduleDate, ScheduleDate][] {
    return this.fixedEmaIntervalsSeconds.map((interval) =>
      this.toDateInterval(interval, absoluteBaseDate),
    )
  }

  randomizedEmaDateIntervals(
    absoluteBaseDate: ScheduleDate,
  ): [ScheduleDate, ScheduleDate][] {
    return this.randomizedEmaIntervalsSeconds.map((interval) =>
      this.toDateInterval(interval, absoluteBaseDate),
    )
  }

  private toDateInterval(
    interval: [number, number],
    absoluteBaseDate: ScheduleDate,
  ): [ScheduleDate, ScheduleDate] {
    const [start, end] = interval
    const startDate = absoluteBaseDate.withTime(
      ScheduleTime.fromTotalSeconds(start),
    )
    const endDate = absoluteBaseDate.withTime(
      ScheduleTime.fromTotalSeconds(end),
    )
    return [startDate, endDate]
  }
}

export class EmaScheduleSimulator {
  private readonly startTime: ScheduleTime
  private readonly endTime: ScheduleTime

  constructor(
    readonly schedule: ScheduleEmaComponents &
      Required<
        Pick<ScheduleEmaComponents, 'emaType' | 'emaExpirationSeconds'>
      > &
      (
        | Required<Pick<ScheduleEmaComponents, 'emaOcurrences'>>
        | Required<Pick<ScheduleEmaComponents, 'emaIntervalSeconds'>>
      ) & {
        startTime: string | ScheduleTime
        endTime: string | ScheduleTime
      },
    private readonly rand: Randomizer = Math.random,
  ) {
    this.startTime =
      typeof schedule.startTime === 'string'
        ? ScheduleTime.parse(schedule.startTime)
        : schedule.startTime
    this.endTime =
      typeof schedule.endTime === 'string'
        ? ScheduleTime.parse(schedule.endTime)
        : schedule.endTime
  }

  withType(emaType: EmaType): EmaScheduleSimulator {
    return new EmaScheduleSimulator({ ...this.schedule, emaType })
  }

  get isRandom(): boolean {
    return this.schedule.emaType === EmaType.Random
  }

  get isFixed(): boolean {
    return this.schedule.emaType === EmaType.Fixed
  }

  get isSemiRandom(): boolean {
    return this.schedule.emaType === EmaType.SemiRandom
  }

  get totalDurationSeconds(): number {
    return ScheduleTime.getSecondsInterval(this.startTime, this.endTime)
  }

  intervalToOcurrences(interval?: number): number {
    return (
      Math.floor(
        this.totalDurationSeconds /
          (interval ?? this.schedule.emaIntervalSeconds ?? Infinity),
      ) + 1
    )
  }

  ocurrencesToInterval(ocurrences?: number): number {
    return Math.floor(
      this.totalDurationSeconds /
        (ocurrences ?? this.schedule.emaOcurrences ?? Infinity),
    )
  }

  simulate(): EmaScheduleSimulatorResult {
    const {
      emaType,
      emaOcurrences,
      emaIntervalSeconds,
      emaMinimumGapSeconds,
      emaExpirationSeconds,
    } = this.schedule

    switch (String(emaType)) {
      case EmaType.Random: {
        const ocurrences =
          emaOcurrences ??
          (emaIntervalSeconds
            ? this.intervalToOcurrences(emaIntervalSeconds)
            : undefined)
        return ocurrences
          ? this.simulateRandom(
              ocurrences,
              emaMinimumGapSeconds ?? 0,
              emaExpirationSeconds,
            )
          : EmaScheduleSimulatorResult.empty()
      }
      case EmaType.Fixed: {
        const interval =
          emaIntervalSeconds ??
          (emaOcurrences ? this.ocurrencesToInterval(emaOcurrences) : undefined)
        return interval
          ? this.simulateFixed(interval, emaExpirationSeconds)
          : EmaScheduleSimulatorResult.empty()
      }
      case EmaType.SemiRandom: {
        const interval =
          emaIntervalSeconds ??
          (emaOcurrences ? this.ocurrencesToInterval(emaOcurrences) : undefined)
        return interval
          ? this.simulateSemiRandom(
              interval,
              emaMinimumGapSeconds ?? 0,
              emaExpirationSeconds,
            )
          : EmaScheduleSimulatorResult.empty()
      }
      default:
        return EmaScheduleSimulatorResult.empty()
    }
  }

  private simulateRandom(
    ocurrences: number,
    gap: number,
    expiration: number,
  ): EmaScheduleSimulatorResult {
    if (!ocurrences) {
      return EmaScheduleSimulatorResult.empty()
    }

    // calculate total free duration for ema allocation
    const totalDuration = this.totalDurationSeconds
    const totalGapDuration = (ocurrences - 1) * gap
    const totalFreeDuration = totalDuration - totalGapDuration

    // calculate random durations for free intervals that sums up to totalFreeDuration
    const strategy = () =>
      this.getRandomIntegersOfSum(ocurrences, totalFreeDuration)

    return this._simulate(strategy, gap, expiration)
  }

  private simulateFixed(
    interval: number,
    expiration: number,
  ): EmaScheduleSimulatorResult {
    if (!interval) return EmaScheduleSimulatorResult.empty()
    return this.simulateIntervalBased(interval, interval, expiration)
  }

  private simulateSemiRandom(
    interval: number,
    gap: number,
    expiration: number,
  ): EmaScheduleSimulatorResult {
    if (!interval) return EmaScheduleSimulatorResult.empty()
    return this.simulateIntervalBased(interval, gap, expiration)
  }

  /**
   * Simulate interval-based ema schedule
   *
   * to represent in graph:
   *
   * interval 1 ->
   *
   * (ema band 1 = interval / 2 - gap / 2)
   *
   * (half gap)
   *
   * (half interval 1)
   *
   * (half gap)
   *
   * (ema band 2 before interval = interval / 2 - gap / 2)
   *
   * interval 2 ->
   *
   * (ema band 2 after interval = interval / 2 - gap / 2)
   *
   * ...
   *
   * (half gap)
   *
   * (ema band n = interval / 2 - gap / 2)
   *
   * interval n ->
   *
   * @param interval interval is the maximum duration of time in which an ema task should occur
   * @param gap minimum gap between each ema task
   * @param expiration how long should an ema task last
   * @returns result object
   */
  private simulateIntervalBased(
    interval: number,
    gap: number,
    expiration: number,
  ): EmaScheduleSimulatorResult {
    // calculate ocurrences from total duration
    const totalDuration = this.totalDurationSeconds
    const ocurrences = this.intervalToOcurrences(interval)

    // calculate freeDuration, boundary has half the freeDuration
    const freeDuration = interval - gap
    const startFreeDuration = freeDuration / 2
    // extend end free duration if there's space
    const endFreeDuration = Math.min(
      startFreeDuration + (totalDuration % interval),
      freeDuration,
    )

    // calculate fixed durations for free intervals
    const strategy = (): number[] =>
      Array.from({ length: ocurrences }, (_, index) => {
        if (index === 0) return startFreeDuration
        if (index === ocurrences - 1) return endFreeDuration
        return freeDuration
      })

    return this._simulate(strategy, gap, expiration)
  }

  /**
   * Simulate ema schedule
   *
   * we use the concepts of free interval and gap to allocate possible ema tasks.
   * this algorithm can be broken down into steps:
   * 1. calculate free durations
   * 2. convert free durations into intervals
   * 3. intersperse gap in between free intervals
   * 4. randomly select a time in free intervals at which ema occurs
   *
   * to represent an ema timeline in graph with free intervals and gaps looks like:
   *
   * schedule start / ema free interval 1 start ->
   * (ema 1 occur) ->
   * ema free interval 1 end / gap 1 start ->
   * gap 1 end / ema free interval 2 start ->
   * (ema 2 occur) ->
   * ema free interval 2 end / gap 2 start ->
   * gap 2 end / ema free interval 3 start ->
   * ... ->
   * gap n - 1 end / ema free interval n start ->
   * (ema n occur) ->
   * ema free interval n end / schedule end
   *
   * @param freeDurationsStrategy
   * strategy to create free durations.
   * free durations are durations in which randomized ema schedule could occur.
   * free durations will be converted to free intervals with start and end.
   * @param gap gap between free intervals
   * @param expiration how long should an ema task last
   * @returns result object
   */
  private _simulate(
    freeDurationsStrategy: () => number[],
    gap: number,
    expiration: number,
  ): EmaScheduleSimulatorResult {
    // calculate daily start offset for date generation
    const dailyStart = this.startTime.totalSeconds()
    const intervals = freeDurationsStrategy().reduce<[number, number][]>(
      (acc, freeDuration, index) => {
        // convert free duration to free intervals
        if (index === 0) return [[0, freeDuration]]
        const [, prevEnd] = acc[acc.length - 1]
        const start = prevEnd + gap
        const end = start + freeDuration
        return [...acc, [start, end]]
      },
      [],
    )
    return this.genResult(intervals, dailyStart, expiration)
  }

  private getRandomIntegersOfSum(n: number, targetSum: number): number[] {
    if (n <= 1) return [targetSum]
    return (
      // generate `n-1` random partition points
      Array.from({ length: n - 1 }, () => Math.floor(this.rand() * targetSum))
        // add endpoints (0 and targetSum)
        .concat(0, targetSum)
        // sort the array
        .sort((a, b) => a - b)
        // calculate differences between consecutive partition points
        .reduce<number[]>(
          (acc, value, index, array) =>
            index < array.length - 1 ? [...acc, array[index + 1] - value] : acc,
          [],
        )
        // shuffle the result to randomize order
        .map((value) => ({ value, sortValue: this.rand() }))
        .sort((a, b) => a.sortValue - b.sortValue)
        .map(({ value }) => value)
    )
  }

  private genResult(
    intervals: [number, number][],
    start: number,
    expiration: number,
  ): EmaScheduleSimulatorResult {
    const freeIntervalsSeconds: [number, number][] = intervals.map(
      ([freeStart, freeEnd]) => [start + freeStart, start + freeEnd],
    )

    const fixedEmaIntervalsSeconds: [number, number][] =
      freeIntervalsSeconds.map(([freeStart]) => {
        return [freeStart, freeStart + expiration]
      })

    const randomizedEmaIntervalsSeconds: [number, number][] =
      freeIntervalsSeconds.map(([freeStart, freeEnd]) => {
        // randomly sample in free interval
        const offset = Math.floor(this.rand() * (freeEnd - freeStart + 1))
        // convert to ema interval accounting for daily start offset
        const start = freeStart + offset
        const end = start + expiration
        return [start, end]
      })

    return new EmaScheduleSimulatorResult(
      freeIntervalsSeconds,
      fixedEmaIntervalsSeconds,
      randomizedEmaIntervalsSeconds,
    )
  }
}
