import {useState, useMemo, Dispatch, SetStateAction} from 'react'
import {RIF, handleEsc} from '../../lib'
import {selectTheme} from '../../store'
import {Button, ButtonCancel, ButtonText, PopupSize, PopupTemplate, TextLink} from '..'
import {CheckSuccessIcon, WarningIcon} from '../../asset/image'
import {IParticipant} from '../../shared/db'

export interface ParticipantNotificationInfoPopPropsT {
  closeAction: (arg: boolean) => void
  notificationList: IParticipant[]
  notificationInfo: {
    message: {msgTitle: string; msgContent: string}
    response: any
  }
  setSelectedList: Dispatch<SetStateAction<IParticipant[]>>
}

export const ParticipantNotificationInfoPop = (props: ParticipantNotificationInfoPopPropsT) => {
  const {color, fontWeight, fontSize} = selectTheme()

  const {closeAction, notificationList, notificationInfo, setSelectedList} = props
  const {message, response} = notificationInfo

  const [displayErrorDetail, setDisplayErrorDetail] = useState(false)
  const resHasError = response?.some((item: any) => !item.success)
  const resErrorAmount = response?.filter((item: any) => !item.success).length

  // "POO2", "P001" ...
  const allErrorParticipants: string = useMemo(() => {
    let tempString = ''
    response?.map((item: any) => {
      if (!item.success) {
        const findResult = notificationList.find((el) => el.id === item.participantId)
        if (findResult) {
          tempString += `"${findResult.insignia}", `
        }
      }
    })
    return tempString.slice(0, -2)
  }, [notificationList, response])

  handleEsc(closeAction)
  const handleClosePopup = () => {
    if (displayErrorDetail) return setDisplayErrorDetail(false)
    closeAction(false)
    setSelectedList([])
  }

  return (
    <PopupTemplate
      {...{
        title: displayErrorDetail ? 'Error details' : 'Notification sent!',
        description: '',
        background: true,
        popupSize: PopupSize.Medium,
        handleClosePopup,
        mainFunction: handleClosePopup,
        buttonContent: displayErrorDetail ? 'IGot It' : 'Done',
        titleIconURL: displayErrorDetail ? WarningIcon : CheckSuccessIcon,
        hideCancelButton: true,
      }}
    >
      <>
        {RIF(
          displayErrorDetail,
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <p>Something went wrong with the push-notifications for the following participants:</p>
            <span css={{color: color.grey_600}}>{allErrorParticipants}</span>
          </div>,
        )}
        {RIF(
          !displayErrorDetail,
          <div>
            <p
              css={{
                marginBottom: '16px',
              }}
            >
              Your participants will now see this message when they open the app.
            </p>
            {RIF(
              resHasError,
              <div
                css={{
                  background: color.surface.warning,
                  padding: '8px 16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '24px',
                }}
              >
                <div css={{display: 'inline-flex', gap: '8px', alignItems: 'center'}}>
                  <img src={WarningIcon} width={20} />
                  <span css={{color: color.warning}}>Failed to sent to {resErrorAmount} participant(s).</span>
                </div>
                <ButtonText onClick={() => setDisplayErrorDetail(true)}>See error details</ButtonText>
              </div>,
            )}
            <div
              css={{
                background: color.surface.default,
                borderRadius: '8px',
                padding: '16px 24px',
                maxHeight: 'calc(85vh - 380px)',
                overflowY: 'scroll',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <p css={{fontWeight: fontWeight.thick, fontSize: fontSize.h5}}>{message?.msgTitle}</p>
              <p>{message?.msgContent}</p>
            </div>
          </div>,
        )}
      </>
    </PopupTemplate>
  )
}
