import {isNaN, isNull, isUndefined} from 'lodash'
import {ChangeEvent, CSSProperties, ReactNode} from 'react'
import {Controller, FieldValues, RegisterOptions, UseControllerProps} from 'react-hook-form'

import {selectTheme, ThemeState} from '../../store'
import {ScheduleWeekdaysHelper, ScheduleTimeHelper, WeekdayDescriptionMap, Weekday} from '../../model/schedule'
import {Register} from '../../lib/form'
import {darken} from '../../engine'
import {CheckIcon} from '../../lib/svg/icon/check'

// FIXME: padding values with leading zeros on onChange causes validation issues
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleNumberWithLeadingZeros = (e: ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value
  const numValue = parseInt(value)
  if (!isNaN(numValue)) {
    e.target.value = ScheduleTimeHelper.pad(numValue)
  }
}

const setOptionalInputAsOptionalNumber = (value: string | null | undefined): number | null | undefined => {
  if (isUndefined(value) || value === '') return undefined
  if (isNull(value)) return null
  const numValue = parseInt(value)
  return !isNaN(numValue) ? numValue : undefined
}

export const DefaultTaskScheduleSettingsTextStyle = (
  theme: Pick<ThemeState, 'color' | 'fontSize' | 'fontWeight'>,
): CSSProperties => {
  const {color, fontSize, fontWeight} = theme

  return {
    color: color.textIcon.title,
    fontWeight: fontWeight.medium,
    fontSize: fontSize.h6,
  }
}

export const DefaultTaskScheduleSettingsUnitStyle = (
  theme: Pick<ThemeState, 'color' | 'fontSize' | 'fontWeight'>,
): CSSProperties => {
  const {color} = theme

  return {
    ...DefaultTaskScheduleSettingsTextStyle(theme),
    color: color.textIcon.secondary,
  }
}

const DefaultTaskScheduleSettingsInputStyle = (
  theme: Pick<ThemeState, 'color' | 'fontSize' | 'fontWeight'>,
): CSSProperties => {
  return {
    ...DefaultTaskScheduleSettingsTextStyle(theme),
    textAlign: 'right',
    width: '5ch',
    border: 'none',
    outline: 'none',
  }
}

const TaskScheduleSettingsActionButtonStyle = (theme: Pick<ThemeState, 'color' | 'fontSize' | 'fontWeight'>) => {
  const {color, fontSize, fontWeight} = theme

  const borderColor = color.border.default
  const backgroundColor = color.surface.default

  const baseStyle: CSSProperties = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.textIcon.secondary,
    fontWeight: fontWeight.bold,
    fontSize: fontSize.h7,
    cursor: 'pointer',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor,
    borderRadius: 3,
    backgroundColor,
    padding: '4px 8px',
    margin: '0px 8px',
    transition: 'all 0.2s ease-in-out',
  }

  const hoverStyle: CSSProperties = {
    borderColor: darken(borderColor, 10),
    backgroundColor: darken(backgroundColor, 10),
  }

  const activeStyle: CSSProperties = {
    borderColor: darken(borderColor, 20),
    backgroundColor: darken(backgroundColor, 20),
  }

  return {
    ...baseStyle,
    '&:hover': hoverStyle,
    '&:active': activeStyle,
  } as CSSProperties
}

interface TaskScheduleSettingsActionButtonProps {
  children: ReactNode
  onClick: () => void
}

const TaskScheduleSettingsActionButton = (props: TaskScheduleSettingsActionButtonProps) => {
  const {children, onClick} = props

  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <button
      type='button'
      onClick={onClick}
      css={{
        ...TaskScheduleSettingsActionButtonStyle({color, fontSize, fontWeight}),
      }}
    >
      {children}
    </button>
  )
}

export interface TaskScheduleSettingsSetExpirationDateButtonProps {
  onClick: () => void
}

export const TaskScheduleSettingsSetExpirationDateButton = (
  props: TaskScheduleSettingsSetExpirationDateButtonProps,
) => {
  return (
    <TaskScheduleSettingsActionButton onClick={props.onClick}>Set Expiration Date</TaskScheduleSettingsActionButton>
  )
}

export interface TaskScheduleSettingsRemoveExpirationDateButtonProps {
  onClick: () => void
}

export const TaskScheduleSettingsRemoveExpirationDateButton = (
  props: TaskScheduleSettingsRemoveExpirationDateButtonProps,
) => {
  return (
    <TaskScheduleSettingsActionButton onClick={props.onClick}>Remove Expiration Date</TaskScheduleSettingsActionButton>
  )
}
interface TaskScheduleSettingsInputProps {
  failedValidation?: boolean
}

type TaskScheduleSettingsControlledInputProps<T extends FieldValues> = TaskScheduleSettingsInputProps &
  Required<Pick<UseControllerProps<T>, 'name' | 'control'>> &
  Pick<UseControllerProps<T>, 'rules'>

interface TaskScheduleSettingsBasicInputContainerProps {
  children: ReactNode
  disabled?: boolean
  failedValidation?: boolean
  style?: CSSProperties
}

const TaskScheduleSettingsBasicInputContainer = (props: TaskScheduleSettingsBasicInputContainerProps) => {
  const {children, disabled, failedValidation, style} = props
  const {color} = selectTheme()

  return (
    <div
      css={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 12px',
        margin: '0px 8px',
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: failedValidation ? color.warning : color.border.default,
        backgroundColor: color.white,
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? 'none' : 'auto',
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export interface TaskScheduleSettingsRelativeDayInputProps<T extends FieldValues>
  extends TaskScheduleSettingsInputProps,
    RegisterOptions<T> {
  register: Register<T>
}

export const TaskScheduleSettingsRelativeDayInput = <T extends FieldValues>(
  props: TaskScheduleSettingsRelativeDayInputProps<T>,
) => {
  const {failedValidation, register, ...options} = props

  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <TaskScheduleSettingsBasicInputContainer
      failedValidation={failedValidation}
      style={{
        gap: 8,
        padding: '8px 0px 8px 12px',
      }}
    >
      <span
        css={{
          ...DefaultTaskScheduleSettingsUnitStyle({color, fontSize, fontWeight}),
        }}
      >
        Day
      </span>
      <input
        type='number'
        min={1}
        css={{
          ...DefaultTaskScheduleSettingsInputStyle({color, fontSize, fontWeight}),
        }}
        {...register({
          ...options,
          setValueAs: setOptionalInputAsOptionalNumber,
        })}
      />
    </TaskScheduleSettingsBasicInputContainer>
  )
}

export interface TaskScheduleSettingsDayIntervalInputProps<T extends FieldValues>
  extends TaskScheduleSettingsInputProps,
    RegisterOptions<T> {
  register: Register<T>
}

export const TaskScheduleSettingsDayIntervalInput = <T extends FieldValues>(
  props: TaskScheduleSettingsDayIntervalInputProps<T>,
) => {
  const {failedValidation, register, ...options} = props

  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <TaskScheduleSettingsBasicInputContainer
      failedValidation={failedValidation}
      style={{
        gap: 0,
        padding: '8px 12px 8px 0px',
      }}
    >
      <input
        type='number'
        min={1}
        css={{
          ...DefaultTaskScheduleSettingsInputStyle({color, fontSize, fontWeight}),
        }}
        {...register({
          ...options,
          setValueAs: setOptionalInputAsOptionalNumber,
        })}
      />
      <span
        css={{
          ...DefaultTaskScheduleSettingsUnitStyle({color, fontSize, fontWeight}),
        }}
      >
        Days
      </span>
    </TaskScheduleSettingsBasicInputContainer>
  )
}

export interface TaskScheduleSettingsFrequencyInputProps<T extends FieldValues>
  extends TaskScheduleSettingsInputProps,
    RegisterOptions<T> {
  register: Register<T>
}

export const TaskScheduleSettingsFrequencyInput = <T extends FieldValues>(
  props: TaskScheduleSettingsFrequencyInputProps<T>,
) => {
  const {failedValidation, register, ...options} = props

  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <TaskScheduleSettingsBasicInputContainer
      failedValidation={failedValidation}
      style={{
        gap: 0,
        padding: '8px 12px 8px 0px',
      }}
    >
      <input
        type='number'
        min={1}
        css={{
          ...DefaultTaskScheduleSettingsInputStyle({color, fontSize, fontWeight}),
        }}
        {...register({
          ...options,
          setValueAs: setOptionalInputAsOptionalNumber,
        })}
      />
      <span
        css={{
          ...DefaultTaskScheduleSettingsUnitStyle({color, fontSize, fontWeight}),
        }}
      >
        time(s)
      </span>
    </TaskScheduleSettingsBasicInputContainer>
  )
}

export interface TaskScheduleSettingsTimeInputProps<T extends FieldValues> extends TaskScheduleSettingsInputProps {
  registerHours: Register<T>
  registerMinutes: Register<T>
  hoursOptions?: RegisterOptions<T>
  minutesOptions?: RegisterOptions<T>
}

export const TaskScheduleSettingsTimeInput = <T extends FieldValues>(props: TaskScheduleSettingsTimeInputProps<T>) => {
  const {registerHours, registerMinutes, failedValidation, hoursOptions, minutesOptions} = props

  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <TaskScheduleSettingsBasicInputContainer
      failedValidation={failedValidation}
      style={{
        gap: 0,
        padding: '8px 0px',
      }}
    >
      <input
        type='number'
        min={0}
        max={23}
        css={{
          ...DefaultTaskScheduleSettingsInputStyle({color, fontSize, fontWeight}),
          width: '4ch',
          marginLeft: 4,
        }}
        {...registerHours({
          ...hoursOptions,
          setValueAs: setOptionalInputAsOptionalNumber,
        })}
      />
      <span css={{...DefaultTaskScheduleSettingsUnitStyle({color, fontSize, fontWeight})}}>:</span>
      <input
        type='number'
        min={0}
        max={59}
        css={{
          ...DefaultTaskScheduleSettingsInputStyle({color, fontSize, fontWeight}),
          width: '4ch',
          marginLeft: 4,
        }}
        {...registerMinutes({
          ...minutesOptions,
          setValueAs: setOptionalInputAsOptionalNumber,
        })}
      />
    </TaskScheduleSettingsBasicInputContainer>
  )
}

export interface TaskScheduleSettingsDurationInputProps<T extends FieldValues> extends TaskScheduleSettingsInputProps {
  registerHours: Register<T>
  registerMinutes: Register<T>
  hoursOptions?: RegisterOptions<T>
  minutesOptions?: RegisterOptions<T>
}

export const TaskScheduleSettingsDurationInput = <T extends FieldValues>(
  props: TaskScheduleSettingsDurationInputProps<T>,
) => {
  const {registerHours, registerMinutes, failedValidation, hoursOptions, minutesOptions} = props

  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <TaskScheduleSettingsBasicInputContainer
      failedValidation={failedValidation}
      style={{
        gap: 0,
        padding: '8px 12px 8px 0px',
      }}
    >
      <input
        type='number'
        min={0}
        css={{
          ...DefaultTaskScheduleSettingsInputStyle({color, fontSize, fontWeight}),
          width: '4ch',
          marginLeft: 4,
        }}
        {...registerHours({
          ...hoursOptions,
          setValueAs: setOptionalInputAsOptionalNumber,
        })}
      />
      <span css={{...DefaultTaskScheduleSettingsUnitStyle({color, fontSize, fontWeight})}}>hr</span>
      <input
        type='number'
        min={0}
        max={59}
        css={{
          ...DefaultTaskScheduleSettingsInputStyle({color, fontSize, fontWeight}),
          width: '4ch',
          marginLeft: 4,
        }}
        {...registerMinutes({
          ...minutesOptions,
          setValueAs: setOptionalInputAsOptionalNumber,
        })}
      />
      <span css={{...DefaultTaskScheduleSettingsUnitStyle({color, fontSize, fontWeight})}}>min</span>
    </TaskScheduleSettingsBasicInputContainer>
  )
}

interface TaskScheduleSettingsWeekdaysPickerProps {
  value: Weekday[]
  onChange: (value: Weekday[]) => void
  failedValidation?: boolean
}

const TaskScheduleSettingsWeekdaysPicker = (props: TaskScheduleSettingsWeekdaysPickerProps) => {
  const {value, onChange, failedValidation} = props
  const {color, fontSize, fontWeight} = selectTheme()

  const handleWeekdayToggle = (weekday: Weekday) => {
    if (value.includes(weekday)) {
      onChange(value.filter((day) => day !== weekday))
    } else {
      onChange([...value, weekday].sort((a, b) => a - b))
    }
  }

  return (
    <div
      css={{
        display: 'inline-grid',
        gridTemplateColumns: 'repeat(7, auto)',
        width: 'fit-content',
        gap: 8,
      }}
    >
      {Array.from(WeekdayDescriptionMap.keys())
        .sort((a, b) => a - b)
        .map((weekday) => {
          const selected = value.includes(weekday)
          return (
            <div
              key={weekday}
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: color.white,
                borderRadius: 5,
                borderWidth: selected ? 2 : 1,
                borderStyle: 'solid',
                borderColor: failedValidation ? color.warning : selected ? color.border.black : color.border.default,
                padding: 4,
                gap: 8,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  borderColor: color.border.black,
                },
                whiteSpace: 'nowrap',
                userSelect: 'none',
              }}
              onClick={() => handleWeekdayToggle(weekday)}
            >
              <span css={{...DefaultTaskScheduleSettingsTextStyle({color, fontSize, fontWeight}), color: color.black}}>
                {ScheduleWeekdaysHelper.getWeekdayString(weekday)}
              </span>
              {selected && <CheckIcon />}
            </div>
          )
        })}
    </div>
  )
}

export type TaskScheduleSettingsWeekdaysInputProps<T extends FieldValues> = TaskScheduleSettingsControlledInputProps<T>

export const TaskScheduleSettingsWeekdaysInput = <T extends FieldValues>(
  props: TaskScheduleSettingsWeekdaysInputProps<T>,
) => {
  const {name, control, rules, failedValidation} = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {value, onChange}}) => (
        <TaskScheduleSettingsWeekdaysPicker
          {...{
            value: ScheduleWeekdaysHelper.splitWeekdays(value),
            onChange: (value) => onChange(ScheduleWeekdaysHelper.joinWeekdays(value)),
            failedValidation,
          }}
        />
      )}
    />
  )
}
