import {EmotionJSX} from '@emotion/react/types/jsx-namespace'
import {CSSProperties} from 'react'
import {selectTheme} from '../../store'

export enum ButtonSize {
  Big = 'big',
  Medium = 'medium',
  Small = 'small',
  XSmall = 'xSmall',
}

export enum ButtonColorTheme {
  Primary = 'primary',
  Warning = 'warning',
  Grey = 'grey',
  Yellow = 'yellow',
  Black = 'black',
  Green = 'green',
  Purple = 'purple',
  Disabled = 'disabled',
}

export const ButtonTemplate = (props: {
  children: EmotionJSX.Element | string
  buttonSize: ButtonSize
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  reverse?: boolean
  colorTheme?: ButtonColorTheme
  disabled?: boolean
  css?: CSSProperties
}) => {
  const {children, buttonSize, onClick, reverse, colorTheme, disabled, css} = props
  const {color, fontSize, fontWeight, borderRadius} = selectTheme()
  const colorThemeMapping = {
    [ButtonColorTheme.Primary]: {
      color: reverse ? color.textIcon.link : color.textIcon.white,
      border: reverse ? `1px solid ${color.state.default}` : 'none',
      backgroundColor: reverse ? 'transparent' : color.state.default,
      ':hover': {
        backgroundColor: reverse ? color.surface.hover.hoverWhiteBg : color.surface.hover.blue,
      },
    },
    [ButtonColorTheme.Warning]: {
      color: reverse ? color.textIcon.warning : color.textIcon.white,
      border: reverse ? `1px solid ${color.state.warning}` : 'none',
      backgroundColor: reverse ? 'transparent' : color.state.warning,
      ':hover': {
        backgroundColor: reverse ? color.surface.warning : color.surface.hover.warning,
      },
    },
    [ButtonColorTheme.Grey]: {
      color: color.textIcon.secondary,
      border: 'none',
      backgroundColor: color.surface.default,
      ':hover': {
        backgroundColor: color.surface.hover.hoverGreyBg,
      },
    },
    [ButtonColorTheme.Disabled]: {
      color: reverse ? color.state.disabled : color.textIcon.white,
      border: reverse ? `1px solid ${color.state.disabled}` : 'none',
      backgroundColor: reverse ? 'transparent' : color.state.disabled,
    },
    [ButtonColorTheme.Yellow]: {
      color: reverse ? color.textIcon.warning : color.textIcon.white,
      border: reverse ? `1px solid ${color.state.warning}` : 'none',
      backgroundColor: reverse ? 'transparent' : color.state.warning,
      ':hover': {
        backgroundColor: reverse ? color.surface.warning : color.surface.hover.warning,
      },
    },
    [ButtonColorTheme.Black]: {
      color: reverse ? color.textIcon.warning : color.textIcon.white,
      border: reverse ? `1px solid ${color.state.warning}` : 'none',
      backgroundColor: reverse ? 'transparent' : color.state.warning,
      ':hover': {
        backgroundColor: reverse ? color.surface.warning : color.surface.hover.warning,
      },
    },
    [ButtonColorTheme.Green]: {
      color: reverse ? color.textIcon.warning : color.textIcon.white,
      border: reverse ? `1px solid ${color.state.warning}` : 'none',
      backgroundColor: reverse ? 'transparent' : color.state.warning,
      ':hover': {
        backgroundColor: reverse ? color.surface.warning : color.surface.hover.warning,
      },
    },
    [ButtonColorTheme.Purple]: {
      color: reverse ? color.textIcon.warning : color.textIcon.white,
      border: reverse ? `1px solid ${color.state.warning}` : 'none',
      backgroundColor: reverse ? 'transparent' : color.state.warning,
      ':hover': {
        backgroundColor: reverse ? color.surface.warning : color.surface.hover.warning,
      },
    },
  }
  const sizeMapping = {
    [ButtonSize.Big]: {
      height: '40px',
      padding: '0 16px',
      fontSize: fontSize.h6,
      fontWeight: fontWeight.thick,
    },
    [ButtonSize.Medium]: {
      height: '32px',
      padding: '0 16px',
      fontSize: fontSize.h6,
      fontWeight: fontWeight.thick,
    },
    [ButtonSize.Small]: {
      height: '24px',
      padding: '0 8px',
      fontSize: fontSize.h7,
      fontWeight: fontWeight.bold,
    },
    [ButtonSize.XSmall]: {
      height: '20px',
      padding: '0 8px',
      fontSize: fontSize.h7,
      fontWeight: fontWeight.bold,
    },
  }
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      css={{
        ...colorThemeMapping[disabled ? ButtonColorTheme.Disabled : colorTheme ?? ButtonColorTheme.Primary],
        ...sizeMapping[buttonSize],
        borderRadius: borderRadius.default,
        cursor: disabled ? 'default' : 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '4px',
        ...css,
      }}
    >
      {children}
    </button>
  )
}
