import {useForm} from 'react-hook-form'
import {ScheduleTime} from '../../shared/db'
import {useEffect, useState} from 'react'
import {selectTheme} from '../../store'
import {
  DefaultTaskScheduleSettingsTextStyle,
  TaskScheduleSettingsRelativeDayInput,
  TaskScheduleSettingsRemoveExpirationDateButton,
  TaskScheduleSettingsSetExpirationDateButton,
  TaskScheduleSettingsTimeInput,
} from './task_schedule_settings_input'
import {maybeSoftTry} from '../../lib'
import {ScheduleFormValidationErrorMessages, ScheduleFormValidator, ScheduleTimeHelper} from '../../model/schedule'
import {useMount} from 'react-use'
import {registerName} from '../../lib/form'
import {isNil} from 'lodash'

interface TaskScheduleSettingsOneTimeOnlyFixedScheduleRelativeForm {
  startDay: number
  endDay?: number
  startTime: string
  startTimeHours: number
  startTimeMinutes: number
  endTime?: string
  endTimeHours?: number
  endTimeMinutes?: number
}

type TaskScheduleSettingsOneTimeOnlyFixedScheduleRelativeOutput = Omit<
  TaskScheduleSettingsOneTimeOnlyFixedScheduleRelativeForm,
  'startTimeHours' | 'startTimeMinutes' | 'endTimeHours' | 'endTimeMinutes'
>

export interface TaskScheduleSettingsOneTimeOnlyFixedScheduleRelativeProps {
  values: Partial<TaskScheduleSettingsOneTimeOnlyFixedScheduleRelativeOutput>
  onValidated: (form: TaskScheduleSettingsOneTimeOnlyFixedScheduleRelativeOutput) => void
  onError: () => void
}

export const TaskScheduleSettingsOneTimeOnlyFixedScheduleRelative = (
  props: TaskScheduleSettingsOneTimeOnlyFixedScheduleRelativeProps,
) => {
  const {values, onValidated, onError} = props

  const {color, fontSize, fontWeight} = selectTheme()

  const textStyle = DefaultTaskScheduleSettingsTextStyle({color, fontSize, fontWeight})

  const defaultEndDay = values.endDay ?? 7
  const [cachedEndDay, setCachedEndDay] = useState(defaultEndDay)

  const defaultStartTime = values.startTime ?? '05:00'
  const defaultStartTimeParsed = maybeSoftTry(() => ScheduleTime.parse(defaultStartTime))

  const defaultEndTime = values.endTime
  const defaultEndTimeParsed = maybeSoftTry(() => (defaultEndTime ? ScheduleTime.parse(defaultEndTime) : undefined))

  const defaultEndTimeHours = defaultEndTimeParsed?.hours ?? 23
  const [cachedEndTimeHours, setCachedEndTimeHours] = useState(defaultEndTimeHours)

  const defaultEndTimeMinutes = defaultEndTimeParsed?.minutes ?? 59
  const [cachedEndTimeMinutes, setCachedEndTimeMinutes] = useState(defaultEndTimeMinutes)

  const {
    register,
    formState: {isValidating, isValid, errors},
    trigger,
    setValue,
    getValues,
  } = useForm<TaskScheduleSettingsOneTimeOnlyFixedScheduleRelativeForm>({
    mode: 'onChange',
    defaultValues: {
      startDay: values.startDay ?? 1,
      startTime: defaultStartTime,
      startTimeHours: defaultStartTimeParsed?.hours ?? 5,
      startTimeMinutes: defaultStartTimeParsed?.minutes ?? 0,
      endDay: values.endDay,
      endTime: defaultEndTime,
      endTimeHours: defaultEndTimeParsed?.hours,
      endTimeMinutes: defaultEndTimeParsed?.minutes,
    },
  })

  const [showSetExpirationDateButton, setShowSetExpirationDateButton] = useState(isNil(defaultEndTimeParsed))

  const [latestErrorMessage, setLatestErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    for (const error of Object.values(errors)) {
      if (error?.message) {
        setLatestErrorMessage(error.message)
        onError()
        return
      }
    }
    setLatestErrorMessage(null)
  }, [
    errors.startDay,
    errors.endDay,
    errors.startTime,
    errors.startTimeHours,
    errors.startTimeMinutes,
    errors.endTime,
    errors.endTimeHours,
    errors.endTimeMinutes,
  ])

  useMount(() => {
    trigger()
  })

  useEffect(() => {
    if (isValid && !isValidating) {
      const form = getValues()
      onValidated(form)
    }
  }, [isValid, isValidating])

  const failedValidation =
    !!errors.startDay ||
    !!errors.endDay ||
    !!errors.startTime ||
    !!errors.startTimeHours ||
    !!errors.startTimeMinutes ||
    !!errors.endTime ||
    !!errors.endTimeHours ||
    !!errors.endTimeMinutes

  return (
    <div css={{display: 'flex', flexDirection: 'column', gap: 16}}>
      <div>
        <span css={{...textStyle}}>The task will appear on</span>
        <TaskScheduleSettingsRelativeDayInput
          register={registerName(register, 'startDay')}
          failedValidation={failedValidation}
          {...{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Day'),
            },
            min: {
              value: 1,
              message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('Start Day'),
            },
            validate: {
              lessThanOrEqual: (value) => {
                const endDay = getValues('endDay')
                if (value && endDay && value > endDay) {
                  return ScheduleFormValidationErrorMessages.shouldBeLessThanOrEqual('Start Day', 'End Day')
                }
                return true
              },
            },
            deps: ['endDay'],
          }}
        />
        <span css={{...textStyle}}>at</span>
        <input
          type='hidden'
          {...register('startTime', {
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Time'),
            },
            pattern: {
              value: ScheduleTime.REGEX,
              message: ScheduleFormValidationErrorMessages.timeInvalid('Start Time'),
            },
            validate: {
              validDateTimeInterval: (value) => {
                const {success} = ScheduleFormValidator.validateRelativeDateTimeInterval({
                  relativeStartDayOrYymmdd: getValues('startDay'),
                  relativeEndDayOrYymmdd: getValues('endDay'),
                  startTime: value,
                  endTime: getValues('endTime'),
                })
                return success ? true : ScheduleFormValidationErrorMessages.shouldBeLessThan('Start Time', 'End Time')
              },
            },
            deps: ['startDay', 'endDay', 'endTime'],
          })}
        />
        <TaskScheduleSettingsTimeInput
          registerHours={registerName(register, 'startTimeHours')}
          registerMinutes={registerName(register, 'startTimeMinutes')}
          failedValidation={failedValidation}
          hoursOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Hours'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Start Hours'),
            },
            max: {
              value: 23,
              message: ScheduleFormValidationErrorMessages.hourInvalid('Start Hours'),
            },
            onChange: (e) => {
              const startTimeHours = parseInt(e.target.value)
              setValue('startTimeHours', startTimeHours)
              const startTimeMinutes = getValues('startTimeMinutes')
              if (!isNil(startTimeMinutes)) {
                setValue('startTime', ScheduleTimeHelper.format(startTimeHours, startTimeMinutes))
              }
              trigger(['startTimeMinutes', 'startTimeHours', 'startTime', 'endTime'])
            },
          }}
          minutesOptions={{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Minutes'),
            },
            min: {
              value: 0,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Start Minutes'),
            },
            max: {
              value: 59,
              message: ScheduleFormValidationErrorMessages.minuteInvalid('Start Minutes'),
            },
            onChange: (e) => {
              const startTimeMinutes = parseInt(e.target.value)
              setValue('startTimeMinutes', startTimeMinutes)
              const startTimeHours = getValues('startTimeHours')
              if (!isNil(startTimeHours)) {
                setValue('startTime', ScheduleTimeHelper.format(startTimeHours, startTimeMinutes))
              }
              trigger(['startTimeMinutes', 'startTimeHours', 'startTime', 'endTime'])
            },
          }}
        />
        {showSetExpirationDateButton && (
          <>
            <span css={{...textStyle}}>until completed.</span>
            <TaskScheduleSettingsSetExpirationDateButton
              onClick={() => {
                setValue('endDay', cachedEndDay)
                setValue('endTimeHours', cachedEndTimeHours)
                setValue('endTimeMinutes', cachedEndTimeMinutes)
                setValue('endTime', ScheduleTimeHelper.format(cachedEndTimeHours, cachedEndTimeMinutes))
                setShowSetExpirationDateButton(false)
                trigger()
              }}
            />
          </>
        )}
        {!showSetExpirationDateButton && (
          <>
            <span css={{...textStyle}}>and expire on</span>
            <TaskScheduleSettingsRelativeDayInput
              register={registerName(register, 'endDay')}
              failedValidation={failedValidation}
              {...{
                min: {
                  value: 1,
                  message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('End Day'),
                },
                validate: {
                  greaterThanOrEqual: (value) => {
                    const startDay = getValues('startDay')
                    if (value && startDay && value < startDay) {
                      return ScheduleFormValidationErrorMessages.shouldBeGreaterThan('End Day', 'Start Day')
                    }
                    return true
                  },
                },
                deps: ['startDay'],
              }}
            />
            <span css={{...textStyle}}>at</span>
            <input
              type='hidden'
              {...register('endTime', {
                pattern: {
                  value: ScheduleTime.REGEX,
                  message: ScheduleFormValidationErrorMessages.timeInvalid('End Time'),
                },
                validate: {
                  validDateTimeInterval: (value) => {
                    const {success} = ScheduleFormValidator.validateRelativeDateTimeInterval({
                      relativeStartDayOrYymmdd: getValues('startDay'),
                      relativeEndDayOrYymmdd: getValues('endDay'),
                      startTime: getValues('startTime'),
                      endTime: value,
                    })
                    return success
                      ? true
                      : ScheduleFormValidationErrorMessages.shouldBeGreaterThan('End Time', 'Start Time')
                  },
                },
                deps: ['startDay', 'endDay', 'startTime'],
              })}
            />
            <TaskScheduleSettingsTimeInput
              registerHours={registerName(register, 'endTimeHours')}
              registerMinutes={registerName(register, 'endTimeMinutes')}
              failedValidation={failedValidation}
              hoursOptions={{
                min: {
                  value: 0,
                  message: ScheduleFormValidationErrorMessages.hourInvalid('End Hours'),
                },
                max: {
                  value: 23,
                  message: ScheduleFormValidationErrorMessages.hourInvalid('End Hours'),
                },
                onChange: (e) => {
                  const endTimeHours = parseInt(e.target.value)
                  setValue('endTimeHours', endTimeHours)
                  const endTimeMinutes = getValues('endTimeMinutes')
                  if (!isNil(endTimeMinutes)) {
                    setValue('endTime', ScheduleTimeHelper.format(endTimeHours, endTimeMinutes))
                  }
                  trigger(['endTimeHours', 'endTimeMinutes', 'endTime', 'startTime'])
                },
              }}
              minutesOptions={{
                min: {
                  value: 0,
                  message: ScheduleFormValidationErrorMessages.minuteInvalid('End Minutes'),
                },
                max: {
                  value: 59,
                  message: ScheduleFormValidationErrorMessages.minuteInvalid('End Minutes'),
                },
                onChange: (e) => {
                  const endTimeMinutes = parseInt(e.target.value)
                  setValue('endTimeMinutes', endTimeMinutes)
                  const endTimeHours = getValues('endTimeHours')
                  if (!isNil(endTimeHours)) {
                    setValue('endTime', ScheduleTimeHelper.format(endTimeHours, endTimeMinutes))
                  }
                  trigger(['endTimeMinutes', 'endTimeHours', 'endTime', 'startTime'])
                },
              }}
            />
            <TaskScheduleSettingsRemoveExpirationDateButton
              onClick={() => {
                setCachedEndDay(getValues('endDay') ?? defaultEndDay)
                setCachedEndTimeHours(getValues('endTimeHours') ?? defaultEndTimeHours)
                setCachedEndTimeMinutes(getValues('endTimeMinutes') ?? defaultEndTimeMinutes)
                setValue('endDay', undefined)
                setValue('endTimeHours', undefined)
                setValue('endTimeMinutes', undefined)
                setValue('endTime', undefined)
                setShowSetExpirationDateButton(true)
                trigger(['endDay', 'endTimeHours', 'endTimeMinutes', 'endTime'])
              }}
            />
          </>
        )}
      </div>
      {latestErrorMessage && <p css={{...textStyle, color: color.warning}}>{latestErrorMessage}</p>}
    </div>
  )
}
