import {defaults} from 'lodash'

export interface SvgProps {
  width?: number
  height?: number
  fill?: string
  stroke?: string
  flexShrink?: number
}

export const defaultSvgProps = <T extends SvgProps>(props: T | undefined, defaultProps: Partial<T>) => {
  return defaults({...props}, defaultProps) as T
}
