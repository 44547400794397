import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {
  _,
  collaboratorTypeHasRole,
  RIF,
  t,
  timeConvert,
  TimeConvertType,
  useCollaboratorTypeInProject,
  useCurrentProjectState,
} from '../../lib'
import {
  BbiDataDigestDateMap,
  createDispatchActions,
  GarminConnectWearTimeDateMap,
  selectBatchData,
  selectMethod,
  selectTheme,
} from '../../store'
import {Checkbox, NotificationButton, ParticipantTagsContainer, Tag, Tooltip} from '..'
import {
  Adherence,
  DigestDay,
  GarminDirect,
  ParticipantSelected,
  SidebarContent,
  TaskStateType,
  TaskType,
} from '../../model'
import {
  CheckAllIcon,
  CheckBlackIcon,
  CheckboxDefaultIcon,
  DisableBoxIcon,
  GarminGGreyIcon,
  GarminGIcon,
  GarminLGreyIcon,
  GarminLIcon,
  MessageGreyIcon,
} from '../../asset/image'
import {IParticipant, ProjectCollaboratorRole, WorkspaceCollaboratorRole} from '../../shared/db'

export enum AdherenceType {
  TaskCompletion,
  GarminDirectBbiDigest,
  GarminConnectWearTime,
}

export interface AdherenceTableEntryProps {
  tableHeader?: boolean
  index?: number
  startDate: Date
  setDisplaySidebar?: Dispatch<SetStateAction<boolean>>
  adherence?: Adherence
  viewTagList: any[]
  viewDatatype?: GarminDirect
  viewBy?: 'DeviceDataType' | 'Tasks'
  setSidebarContent?: Dispatch<SetStateAction<any>>
  setCurrentSidebarContentIndex?: (participantId: string, yymmddIndex: number) => void
  highLightBox?: string
  setHighLightBox?: Dispatch<SetStateAction<string>>
  selectedList: IParticipant[]
  setSelectedList: Dispatch<SetStateAction<IParticipant[]>>
  isHeaderSelectBtnClicked: boolean
  selectedAdherenceType: AdherenceType
  setIsHeaderSelectBtnClicked?: Dispatch<SetStateAction<boolean>>
  setDisplayPopupSendNotification?: Dispatch<SetStateAction<boolean>>
  sidebarContent?: SidebarContent
  bbiDataDigestDateMap?: BbiDataDigestDateMap
  garminConnectWearTimeDateMap?: GarminConnectWearTimeDateMap
}

export const AdherenceTableEntry = (props: AdherenceTableEntryProps) => {
  const {color} = selectTheme()
  const {projectId, project} = useCurrentProjectState()
  const batchId = project?.batchList?.[0]?.id
  const participantList: IParticipant[] = selectBatchData()?.[batchId ?? '']?.participantList || []
  const method = selectMethod()
  const garminDirect = method?.taskList?.find((item: TaskStateType) => item.type === TaskType.GarminDevice)
  const collaboratorType = useCollaboratorTypeInProject()
  const canUpdateParticipant = collaboratorTypeHasRole({
    collaboratorType,
    workspaceRole: WorkspaceCollaboratorRole.ParticipantUpdate,
    projectRole: ProjectCollaboratorRole.ParticipantUpdate,
  })

  const {
    tableHeader,
    index,
    setDisplaySidebar,
    adherence,
    startDate,
    viewDatatype,
    viewTagList,
    viewBy,
    setSidebarContent,
    setCurrentSidebarContentIndex,
    highLightBox,
    setHighLightBox,
    selectedList,
    setSelectedList,
    isHeaderSelectBtnClicked,
    selectedAdherenceType,
    setIsHeaderSelectBtnClicked,
    setDisplayPopupSendNotification,
    sidebarContent,
    bbiDataDigestDateMap,
    garminConnectWearTimeDateMap,
  } = props

  /* ------------------ req ------------------ */
  const {doREQUEST_REQUEST_PARTICIPANT_DAILY_DATA_DIGEST}: any = createDispatchActions()
  const week = timeConvert({time: startDate, type: TimeConvertType.entireWeek}) as string[]
  const todayWeekFormat = timeConvert({time: new Date(), type: TimeConvertType.todayWeekFormat})

  // TODO: wait BE, should update name from BE
  // const participantName = adherence?.status?.participantInsignia
  const participant = participantList.find((item: IParticipant) => item.id === adherence?.participantId)
  const participantName = participant?.insignia
  const participantActivatedDateString = participant?.activatedDate as unknown as string
  const dateBeforeParticipantActivatedDate = new Date(participantActivatedDateString)
  dateBeforeParticipantActivatedDate.setDate(dateBeforeParticipantActivatedDate.getDate() - 1)

  // TODO: wait mobile, last seen time?
  const participantLastSeen = adherence?.status?.unixTimestampLastAppOpen
  const participantLastSync = adherence?.status?.unixTimestampLastGarminDeviceSync
  const participantActivated = !!participantLastSeen || !!participantLastSync
  const lastSeen = participantLastSeen
    ? timeConvert({time: participantLastSeen, type: TimeConvertType.lastSeen24})
    : participantLastSync
    ? timeConvert({time: participantLastSync, type: TimeConvertType.lastSeen24})
    : ''
  const emptyDigestDayList: DigestDay[] = []
  const [digestDayList, setDigestDayList] = useState(emptyDigestDayList)

  // /* ----------------- select -------------------- */
  const participantSelected: boolean = selectedList?.some((item: IParticipant) => item.id === participant?.id)

  const handleClickHeaderSelectBtn = () => {
    setIsHeaderSelectBtnClicked?.((prev) => !prev)
    // select all
    if (selectedList.length === 0) {
      // const tempObjArr: ParticipantSelected[] = participantList.map((item) => ({
      //   participantId: item?.id,
      //   insignia: item?.insignia,
      //   pushEnable: !!item?.pushDeviceId,
      // }))
      // if (tempObjArr) setSelectedList(tempObjArr)
      setSelectedList(participantList)
    }
    // cancel all
    if (selectedList.length !== 0) {
      if (isHeaderSelectBtnClicked) {
        setSelectedList([])
      }
    }
  }

  const handleClickSelect = () => {
    if (!participant) return
    if (!participantSelected) {
      setSelectedList((prev) => [...prev, participant])
    }
    if (participantSelected) {
      setSelectedList((prev) => prev.filter((item) => item.id !== participant?.id))
    }
  }

  useEffect(() => {
    if (selectedList?.length > 0) return setIsHeaderSelectBtnClicked?.(true)
    if (selectedList?.length === 0) return setIsHeaderSelectBtnClicked?.(false)
  }, [selectedList?.length])

  const isoDateStringToYYMMDD = (dateString: string) => {
    const isoDateString = dateString.slice(0, 10)
    const dateInfo = isoDateString.split('-')
    try {
      return `${dateInfo[0].slice(2)}${dateInfo[1]}${dateInfo[2]}`
    } catch (e) {
      throw new Error(`invalid isoDateString ${dateString}`)
    }
  }

  useEffect(() => {
    const tempDigestArr: DigestDay[] = []
    const DAYS_OF_WEEK = 7
    // default array only fill "day"
    const y = startDate.getFullYear()
    const m = startDate.getMonth()
    const d = startDate.getDate()

    for (let i = 0; i < DAYS_OF_WEEK; i++) {
      const date = new Date(Date.UTC(y, m, d))
      date.setDate(date.getDate() + i)
      const dateIsoString = date.toISOString()
      const isoDate = dateIsoString.slice(0, 10)
      const yymmdd = +isoDateStringToYYMMDD(isoDate)
      tempDigestArr.push({
        day: isoDate,
        dayDate: dateIsoString,
        dayUnixTimestamp: date.getTime(),
        yymmddIndex: yymmdd,
      })
    }
    if (adherence?.digestDayList?.length) {
      // mapping if digestDay exists then replace the tempDigest item
      tempDigestArr.forEach((tempItem, index) => {
        const findResult = _.find(adherence.digestDayList, ['yymmddIndex', tempItem.yymmddIndex])
        if (findResult) {
          tempDigestArr[index] = findResult
          tempDigestArr[index].hasContent = true
        }
      })
    }

    if (bbiDataDigestDateMap) {
      tempDigestArr.forEach((tempItem, index) => {
        if (bbiDataDigestDateMap[tempItem.yymmddIndex]) {
          tempDigestArr[index].garminDirectBbiDigest = bbiDataDigestDateMap[tempItem.yymmddIndex]
        }
      })
    }

    if (bbiDataDigestDateMap) {
      tempDigestArr.forEach((tempItem, index) => {
        if (bbiDataDigestDateMap[tempItem.yymmddIndex]) {
          tempDigestArr[index].garminDirectBbiDigest = bbiDataDigestDateMap[tempItem.yymmddIndex]
        }
      })
    }

    if (garminConnectWearTimeDateMap) {
      tempDigestArr.forEach((tempItem, index) => {
        if (garminConnectWearTimeDateMap[tempItem.yymmddIndex]) {
          tempDigestArr[index].garminConnectWearTime = garminConnectWearTimeDateMap[tempItem.yymmddIndex]
        }
      })
    }
    setDigestDayList(tempDigestArr)
  }, [adherence?.digestDayList, startDate, bbiDataDigestDateMap, garminConnectWearTimeDateMap])

  const handleClick = (element: DigestDay) => {
    if (adherence && element.hasContent) {
      const participantId = adherence.participantId
      const yymmddIndex = element.yymmddIndex
      setCurrentSidebarContentIndex?.(participantId, yymmddIndex)

      if (element.detailInfo) {
        setSidebarContent?.({
          yymmddIndex: element.yymmddIndex,
          garminConnect: element.detailInfo.garminConnect,
          garminConnectSynced: element.detailInfo.garminConnect !== undefined,
          participantId: participantId,
          participantName: participantName,
          projectId: projectId,
          status: adherence?.status,
          task: element.detailInfo.task,
          dexcomEgvDataCount: element.detailInfo.dexcomDataCount,
        })
      } else {
        // show participant status first
        setSidebarContent?.({
          yymmddIndex: element.yymmddIndex,
          participantId: participantId,
          participantName: participantName,
          projectId: projectId,
          status: adherence?.status,
        })
        doREQUEST_REQUEST_PARTICIPANT_DAILY_DATA_DIGEST({
          payload: {
            projectId,
            participantId,
            yymmddIndex: element.yymmddIndex,
            requestGarminDirectData: element.garminDirectCollected === true,
            requestGarminConnectData: element.garminConnectSynced === true,
            requestDexcomData: element.dexcomSynced === true,
          },
        })
      }
    } else {
      setSidebarContent?.({
        yymmddIndex: element.yymmddIndex,
        participantId: adherence?.participantId,
        participantName: participantName,
        projectId: projectId,
        status: adherence?.status,
      })
    }

    setDisplaySidebar?.(true)
    // TODO: careful about "_id" (not official from BE)
    if (!element._id) return
    setHighLightBox?.(element._id)
  }

  // viewDatatype convert to BE key value order to display which type been selected
  const dataTypeConvertTable = (viewDatatype: string | undefined) => {
    if (viewDatatype === 'Heart Rate Interval (BBI)') return 'bbi'
    if (viewDatatype === 'Steps') return 'steps'
    if (viewDatatype === 'Stress') return 'stress'
    if (viewDatatype === 'Oxygen Saturation') return 'pulseOx'
    if (viewDatatype === 'Heart Rate') return 'hr'
    if (viewDatatype === 'Respiration') return 'respiration'
    if (viewDatatype === 'Zero Crossings') return 'zeroCrossing'
    if (viewDatatype === 'Actigraphy') return 'actigraphy'
    if (viewDatatype === 'Skin Temperature') return 'temperature'
    return ''
  }

  const getBackgroundColorByDataMinutes = (dataMinutes: number): string => {
    const twoHoursInMinutes = 120
    if (dataMinutes <= twoHoursInMinutes) return '#FFFFFF'
    else if (dataMinutes <= twoHoursInMinutes * 2) return '#F1AA8C'
    else if (dataMinutes <= twoHoursInMinutes * 4) return '#F1D591'
    else if (dataMinutes <= twoHoursInMinutes * 6) return '#F0ED92'
    else if (dataMinutes <= twoHoursInMinutes * 8) return '#D8EA9A'
    else if (dataMinutes <= twoHoursInMinutes * 10) return '#C7E59E'
    else return '#8ED3AA'
  }

  const RowIndex = () => {
    return (
      <div
        css={{
          width: '36px',
          minWidth: '36px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: tableHeader ? color.grey_600 : 'black',
        }}
      >
        {tableHeader ? '#' : index !== undefined ? index + 1 : ''}
      </div>
    )
  }

  const CheckBox = () => {
    return (
      <div
        css={{
          width: '36px',
          minWidth: '36px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {RIF(
          tableHeader,
          <div
            onClick={handleClickHeaderSelectBtn}
            css={{
              cursor: 'pointer',
              width: '16px',
              height: '16px',
            }}
          >
            {RIF(isHeaderSelectBtnClicked, <img src={CheckAllIcon} width={16} />)}
            {RIF(!isHeaderSelectBtnClicked, <img src={CheckboxDefaultIcon} width={16} />)}
          </div>,
        )}
        {RIF(
          !tableHeader,
          <Checkbox
            name={participant?.insignia}
            onChange={handleClickSelect}
            value={participantSelected}
            data-testid='test_checkbox'
          />,
        )}
      </div>
    )
  }

  const ParticipantInsignia = () => {
    return (
      <div
        css={{
          width: '144px',
          minWidth: '144px',
          display: 'flex',
          alignItems: 'center',
          padding: '0 12px',
        }}
      >
        <span
          css={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: tableHeader ? color.grey_600 : 'black',
          }}
        >
          {tableHeader ? 'ID' : participantName}
        </span>
      </div>
    )
  }

  const NotificationIconButton = () => {
    return (
      <div
        css={{
          width: '40px',
          minWidth: '40px',
          display: canUpdateParticipant ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {RIF(tableHeader, <img src={MessageGreyIcon} width='20' />)}
        {RIF(
          !tableHeader,
          participant?.activated ? (
            <NotificationButton
              enable={!!participant?.pushDeviceId}
              onClick={() => {
                setSelectedList([participant])
                setDisplayPopupSendNotification?.(true)
              }}
              data-testid='test_notificationButton'
              index={index as number}
            />
          ) : (
            <span css={{color: color.grey_400}}>--</span>
          ),
        )}
      </div>
    )
  }

  const LastSeenInfo = () => {
    return (
      <div
        css={{
          borderRight: `2px solid ${color.grey_100}`,
          width: '144px',
          minWidth: '144px',
          display: 'flex',
          alignItems: 'center',
          padding: '0 12px',
        }}
      >
        {RIF(
          tableHeader,
          <div css={{display: 'flex', alignItems: 'center', gap: '8px'}}>
            <span css={{color: color.grey_600}}>Last Seen</span>
            <Tooltip
              css={{marginLeft: '8px'}}
              content="This denotes the most recent time that the participant's Labfront Companion app was connected to the internet."
            />
          </div>,
        )}
        {RIF(
          !tableHeader,
          <div css={{color: participantActivated ? color.black : color.grey_400}}>
            {participantActivated ? (lastSeen as string) : 'Not joined yet'}
          </div>,
        )}
      </div>
    )
  }

  const [tagColumnWidth, setTagColumnWidth] = useState(0)
  const tagRef = useRef(null)
  const Tags = () => {
    return (
      <div
        ref={tagRef}
        css={{
          display: 'flex',
          width: '10%',
          minWidth: '10%',
          borderRight: `1px solid ${color.border.light}`,
          position: 'relative',
          padding: '0 12px',
        }}
      >
        {RIF(
          tableHeader,
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <span>Tag</span>
            <Tooltip content='You can attach tags to participants for grouping' />
          </div>,
        )}
        {RIF(
          !tableHeader && participant?.tagList?.length,
          <div
            css={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '4px',
            }}
          >
            {participant?.tagList?.map((tag) => (
              <Tag
                {...{
                  key: tag.id,
                  tag,
                }}
              />
            ))}
          </div>,
        )}
      </div>
    )
  }

  const getElementBackgrondColor = (digestDay: DigestDay, index: number): string => {
    if (selectedAdherenceType === AdherenceType.GarminDirectBbiDigest) {
      if (!digestDay.garminDirectBbiDigest) {
        return '#FAFAFA'
      }
      const dataMinutes = (digestDay.garminDirectBbiDigest?.wearTime ?? 0) / 60000
      return getBackgroundColorByDataMinutes(dataMinutes)
    } else if (selectedAdherenceType === AdherenceType.GarminConnectWearTime) {
      if (!digestDay.garminConnectWearTime) {
        return '#FAFAFA'
      }
      const dataMinutes = digestDay.garminConnectWearTime ?? 0
      return getBackgroundColorByDataMinutes(dataMinutes)
    }
    return (highLightBox === digestDay?._id ? '#3455F414' : index % 2 !== 0 && '#FCFCFC') as string
  }

  const todayYYMMDD = +(timeConvert({time: new Date(), type: TimeConvertType.formatToYYMMDD}) as string)

  interface TaskDotProps {
    IconContent?: string
    task?: any
    text?: string
    done: boolean | string | undefined
  }

  const TaskDot = (props: TaskDotProps) => {
    const {color} = selectTheme()
    const {IconContent, task, text, done} = props
    const IconColor =
      text === 'Garmin Connect App'
        ? color.black
        : text === 'Labfront Companion App'
        ? color.primary
        : text === 'Dexcom App'
        ? color.dexcomGreen
        : task?.color

    return (
      <div
        css={{
          width: '13px',
          height: '13px',
          borderRadius: '50%',
          margin: '2px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: done && IconColor,
          border: done ? 'none' : `1px dashed ${color.border._160}`,
        }}
      >
        {RIF(
          text === 'Garmin Connect App',
          <>
            {RIF(done, <img src={GarminGIcon} width='13' />)}
            {RIF(!done, <img src={GarminGGreyIcon} width='13' />)}
          </>,
        )}
        {RIF(
          text === 'Labfront Companion App',
          <>
            {RIF(done, <img src={GarminLIcon} width='13' />)}
            {RIF(!done, <img src={GarminLGreyIcon} width='13' />)}
          </>,
        )}
        {RIF(
          text !== 'Garmin Connect App' && text !== 'Labfront Companion App',
          <span
            css={{
              color: done ? color.white : color.grey_160,
              WebkitTransform: 'scale(0.7)',
              lineHeight: '13px',
              width: '13px',
              textAlign: 'center',
            }}
          >
            {IconContent}
          </span>,
        )}
      </div>
    )
  }

  interface GetViewByTaskProps {
    digestDay: DigestDay
  }

  const GetViewByTask = (props: GetViewByTaskProps) => {
    const {digestDay} = props
    return (
      <>
        {RIF(
          viewTagList?.some((obj) => obj.name === 'Garmin Connect App'),
          <TaskDot
            {...{
              text: 'Garmin Connect App',
              done: digestDay.garminConnectSynced,
            }}
          />,
        )}
        {RIF(
          viewTagList?.some((obj) => obj.name === 'Labfront Companion App'),
          <TaskDot
            {...{
              text: 'Labfront Companion App',
              done:
                (digestDay?.task && Object.keys(digestDay?.task)?.find((value) => value === garminDirect?.id)) || false,
            }}
          />,
        )}
        {RIF(
          viewTagList?.some((obj) => obj.name === 'Dexcom App'),
          <TaskDot
            {...{
              text: 'Dexcom App',
              IconContent: 'D',
              done: digestDay?.dexcomSynced || false,
            }}
          />,
        )}
        {RIF(
          viewTagList,
          <>
            {RIF(
              viewTagList && viewTagList?.length,
              viewTagList
                ?.filter((obj) => !['Garmin Connect App', 'Labfront Companion App', 'Dexcom App'].includes(obj.name))
                .map((obj) => (
                  <TaskDot
                    {...{
                      key: t.uuid(),
                      task: obj,
                      IconContent: obj.index + 1,
                      done:
                        (digestDay?.task && Object.keys(digestDay?.task)?.find((value) => value === obj?.id)) || false,
                    }}
                  />
                )),
            )}
          </>,
        )}
      </>
    )
  }

  const dataTypeToView = dataTypeConvertTable(viewDatatype)

  interface GetViewByDeviceDataTypeProps {
    digestDay: DigestDay
  }
  const GetViewByDeviceDataType = (props: GetViewByDeviceDataTypeProps) => {
    const {digestDay} = props
    const viewDataTypeCollected = dataTypeToView ? digestDay?.garminDirectAdherence?.[dataTypeToView] : false
    return (
      <div css={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {RIF(
          viewDataTypeCollected,
          <>
            <img src={CheckBlackIcon} width='13' css={{marginRight: '5px'}} />
            <span>collected</span>
          </>,
        )}
        {RIF(!viewDataTypeCollected, <div css={{color: color.grey_160}}>----</div>)}
      </div>
    )
  }

  const getTaskCompletionElement = (digestDay: DigestDay) => {
    if (viewBy === 'Tasks') {
      return <GetViewByTask digestDay={digestDay} />
    } else if (viewBy === 'DeviceDataType') {
      return <GetViewByDeviceDataType digestDay={digestDay} />
    } else {
      return <></>
    }
  }

  const noWearTimeDataEntry = <div css={{width: '100%', color: '#D6D7DA', textAlign: 'center'}}> No Data</div>

  const getGarminDirectBbiDigestElement = (digestDay: DigestDay) => {
    if (digestDay.garminDirectBbiDigest) {
      return (
        <div css={{width: '100%', color: color.black, textAlign: 'center'}}>
          {
            timeConvert({
              time: digestDay.garminDirectBbiDigest?.wearTime
                ? Math.floor(digestDay.garminDirectBbiDigest?.wearTime / 60000)
                : 0,
              type: TimeConvertType.formatMinutes,
            }) as string
          }
        </div>
      )
    }
    return noWearTimeDataEntry
  }

  const getGarminConnectWearTimeElement = (digestDay: DigestDay) => {
    if (digestDay.garminConnectWearTime) {
      return (
        <div css={{width: '100%', color: color.black, textAlign: 'center'}}>
          {
            timeConvert({
              time: digestDay.garminConnectWearTime,
              type: TimeConvertType.formatMinutes,
            }) as string
          }
        </div>
      )
    }
    return noWearTimeDataEntry
  }

  interface AdherenceEntryProps {
    digestDay: DigestDay
  }

  const AdherenceEntry = (props: AdherenceEntryProps) => {
    const {digestDay} = props
    const isActive =
      (participantActivated &&
        digestDay?.yymmddIndex <= todayYYMMDD &&
        !timeConvert({
          type: TimeConvertType.compareIsBeforeDate,
          time: dateBeforeParticipantActivatedDate,
          arg: digestDay?.dayUnixTimestamp,
        })) ||
      digestDay.garminConnectWearTime ||
      digestDay.garminConnectSynced

    return (
      <>
        {/* if not join or future date */}
        {RIF(
          !isActive,
          <div
            css={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${DisableBoxIcon})`,
              backgroundPosition: '0 0',
              backgroundSize: 'contain',
            }}
          />,
        )}

        {/* activated date */}
        {RIF(
          isActive,
          <div
            css={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 5px',
              flexWrap: 'wrap',
              borderRadius: '5px',
              border: `2px solid ${
                sidebarContent?.participantId === adherence?.participantId &&
                sidebarContent?.yymmddIndex === digestDay?.yymmddIndex
                  ? color.primary
                  : 'transparent'
              }`,
              boxSizing: 'border-box',
              ':hover': {
                border: `2px solid ${color.primary}`,
              },
            }}
          >
            {RIF(selectedAdherenceType === AdherenceType.TaskCompletion, getTaskCompletionElement(digestDay))}
            {RIF(
              selectedAdherenceType === AdherenceType.GarminDirectBbiDigest,
              getGarminDirectBbiDigestElement(digestDay),
            )}
            {RIF(
              selectedAdherenceType === AdherenceType.GarminConnectWearTime,
              getGarminConnectWearTimeElement(digestDay),
            )}
          </div>,
        )}
      </>
    )
  }

  const DigestDayElements = () => {
    return (
      <>
        {/* if is header, use 'week' to render or use 'adherence' will get null */}
        {RIF(
          tableHeader,
          week?.map((element, index) => (
            <div
              key={t.uuid()}
              css={{
                borderRight: index === 6 ? 'none' : `1px solid ${color.grey_100}`,
                width: '10%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: index % 2 !== 0 ? '#FCFCFC' : 'transparent',
                cursor: 'auto',
              }}
            >
              <div
                css={{
                  background: element === todayWeekFormat ? color.primary : 'transparent',
                  color: element === todayWeekFormat ? color.white : color.grey_600,
                  borderRadius: '999px',
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {week[index]}
              </div>
            </div>
          )),
        )}

        {RIF(
          !tableHeader,
          digestDayList?.map((digestDay: DigestDay, index: number) => {
            return (
              <div
                key={`dd-${index}`}
                onClick={() => handleClick(digestDay)}
                css={{
                  borderRight: index === 6 ? 'none' : `1px solid`,
                  borderColor: color.grey_100,
                  width: '10%',
                  display: 'inline-flex',
                  cursor: 'pointer',
                  backgroundColor: getElementBackgrondColor(digestDay, index),
                  ':hover': {
                    backgroundColor: color.surface.hover.white,
                  },
                }}
              >
                <AdherenceEntry digestDay={digestDay} />
              </div>
            )
          }),
        )}
      </>
    )
  }

  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        backgroundColor: 'transparent',
        zIndex: 5,
        borderBottom: `${tableHeader ? '2px' : '1px'} solid ${color.grey_100}`,
        // height: tableHeader ? '56px' : viewTagList?.length > 7 ? 'auto' : '40px',
        minHeight: tableHeader ? '56px' : '40px',
        // minHeight: tableHeader ? '56px' : '40px',
        ':hover': {
          backgroundColor: 'rgba(156, 158, 174, 0.05)',
        },
      }}
    >
      <RowIndex />
      {RIF(canUpdateParticipant, <CheckBox />)}
      <ParticipantInsignia />
      <NotificationIconButton />
      <LastSeenInfo />
      <Tags />
      <DigestDayElements />
    </div>
  )
}
