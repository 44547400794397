import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {selectTheme} from '../../store'
import {
  DefaultTaskScheduleSettingsTextStyle,
  TaskScheduleSettingsRelativeDayInput,
  TaskScheduleSettingsWeekdaysInput,
  TaskScheduleSettingsSetExpirationDateButton,
  TaskScheduleSettingsRemoveExpirationDateButton,
} from './task_schedule_settings_input'
import {ScheduleFormValidationErrorMessages} from '../../model/schedule'
import {useMount} from 'react-use'
import {registerName} from '../../lib/form'
import {isNil} from 'lodash'

interface TaskScheduleSettingsRecurringSpecificDaysOfTheWeekRelativeForm {
  startDay: number
  endDay?: number
  weekdays: number
}

export interface TaskScheduleSettingsRecurringSpecificDaysOfTheWeekRelativeFormProps {
  values: Partial<TaskScheduleSettingsRecurringSpecificDaysOfTheWeekRelativeForm>
  onValidated: (form: TaskScheduleSettingsRecurringSpecificDaysOfTheWeekRelativeForm) => void
  onError: () => void
}

export const TaskScheduleSettingsRecurringSpecificDaysOfTheWeekRelative = (
  props: TaskScheduleSettingsRecurringSpecificDaysOfTheWeekRelativeFormProps,
) => {
  const {values, onValidated, onError} = props

  const {color, fontSize, fontWeight} = selectTheme()

  const textStyle = DefaultTaskScheduleSettingsTextStyle({color, fontSize, fontWeight})

  const defaultEndDay = values.endDay ?? 7
  const [cachedEndDay, setCachedEndDay] = useState<number>(defaultEndDay)

  const {
    register,
    control,
    formState: {isValid, isValidating, errors},
    trigger,
    setValue,
    getValues,
  } = useForm<TaskScheduleSettingsRecurringSpecificDaysOfTheWeekRelativeForm>({
    mode: 'onChange',
    defaultValues: {
      startDay: values.startDay ?? 1,
      endDay: values.endDay,
      weekdays: values.weekdays ?? 1,
    },
  })

  const [showSetExpirationDateButton, setShowSetExpirationDateButton] = useState(isNil(values.endDay))

  const [latestErrorMessage, setLatestErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    for (const error of Object.values(errors)) {
      if (error?.message) {
        onError()
        setLatestErrorMessage(error.message)
        return
      }
    }
    setLatestErrorMessage(null)
  }, [errors.startDay, errors.endDay, errors.weekdays])

  useMount(() => {
    trigger()
  })

  useEffect(() => {
    if (isValid && !isValidating) {
      const form = getValues()
      onValidated(form)
    }
  }, [isValid, isValidating])

  return (
    <div css={{display: 'flex', flexDirection: 'column', gap: 16}}>
      <TaskScheduleSettingsWeekdaysInput
        name='weekdays'
        control={control}
        failedValidation={!!errors.weekdays}
        rules={{
          required: {
            value: true,
            message: ScheduleFormValidationErrorMessages.required('Weekdays'),
          },
          min: {
            value: 1,
            message: ScheduleFormValidationErrorMessages.noWeekdays('Weekdays'),
          },
        }}
      />
      <div>
        <span css={{...textStyle}}>The task will appear on specific day(s) of the week from</span>
        <TaskScheduleSettingsRelativeDayInput
          register={registerName(register, 'startDay')}
          failedValidation={!!errors.startDay || !!errors.endDay}
          {...{
            required: {
              value: true,
              message: ScheduleFormValidationErrorMessages.required('Start Day'),
            },
            min: {
              value: 1,
              message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('Start Day'),
            },
            validate: {
              lessThanOrEqual: (value) => {
                const endDay = getValues('endDay')
                if (value && endDay && value > endDay) {
                  return ScheduleFormValidationErrorMessages.shouldBeLessThanOrEqual('Start Day', 'End Day')
                }
                return true
              },
            },
            deps: ['endDay'],
          }}
        />
        {showSetExpirationDateButton && (
          <>
            <span css={{...textStyle}}>and continue indefinitely.</span>
            <TaskScheduleSettingsSetExpirationDateButton
              onClick={() => {
                setValue('endDay', cachedEndDay)
                setShowSetExpirationDateButton(false)
                trigger()
              }}
            />
          </>
        )}
        {!showSetExpirationDateButton && (
          <>
            <span css={{...textStyle}}>to</span>
            <TaskScheduleSettingsRelativeDayInput
              register={registerName(register, 'endDay')}
              failedValidation={!!errors.endDay || !!errors.startDay}
              {...{
                min: {
                  value: 1,
                  message: ScheduleFormValidationErrorMessages.shouldBePositiveNumber('End Day'),
                },
                validate: {
                  greaterThanOrEqual: (value) => {
                    const startDay = getValues('startDay')
                    if (value && startDay && value < startDay) {
                      return ScheduleFormValidationErrorMessages.shouldBeGreaterThanOrEqual('End Day', 'Start Day')
                    }
                    return true
                  },
                },
                deps: ['startDay'],
              }}
            />
            <TaskScheduleSettingsRemoveExpirationDateButton
              onClick={() => {
                setCachedEndDay(getValues('endDay') ?? defaultEndDay)
                setValue('endDay', undefined)
                setShowSetExpirationDateButton(true)
                trigger(['endDay', 'startDay'])
              }}
            />
          </>
        )}
      </div>
      {latestErrorMessage && <div css={{...textStyle, color: color.warning}}>{latestErrorMessage}</div>}
    </div>
  )
}
