import {selectTheme} from '../store'
import {css} from '@emotion/react'

export const calculateInputCSS = (props: any) => {
  const {color, pad} = selectTheme()
  return css`
    width: 100%;
    padding: ${pad.mediumSmall};
    border-radius: ${pad.small};
    background-color: ${props.readOnly ? color.background : color.white};
    border: 1px solid ${props.hasError ? color.attention : color.grey_160};
    box-sizing: border-box;
    resize: none;

    &:hover {
      border: 1px solid ${color.grey_400};
    }

    &:active {
      border: 1px solid ${props.hasError ? color.attention : color.grey_400};
      outline: none;
    }

    &:focus {
      border: 1px solid ${props.hasError ? color.attention : color.grey_400};
      box-shadow: none;
      outline: none;
    }

    &::placeholder {
      color: ${color.grey_300};
    }
  `
}
