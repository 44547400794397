// ratios generated using minor-scale on https://type-scale.com
// 1rem = 16px , 1em = 14px
export type FontSize = typeof fontSize
const fontSize = Object.freeze({
  base: '1em', // 14px
  h1: '1.383em',
  h2: '1.296em',
  h3: '1.4286em', // =~20px
  h4: '1.2857em', // =~18px
  h5: '1.1429em', // =~16px
  h6: '1.0000em', // =~14px
  h7: '0.8571em', // =~12px
  h8: '0.7143em', // =~10px
})

export type FontWeight = typeof fontWeight
const fontWeight = Object.freeze({
  thin: 200,
  normal: 300,
  regular: 400,
  medium: 500,
  bold: 600,
  thick: 700,
})

enum Grey {
  white = '#FFFFFF',
  _100 = '#FAFAFA',
  _200 = '#F6F6F9',
  _300 = '#EFF0F4',
  _400 = '#DDDEE3',
  _500 = '#C0C2CC',
  _600 = '#999DB0',
  _700 = '#60647D',
  _800 = '#2B304A',
  _1000 = '#10132B',
}

enum Red {
  _100 = '#FCF5F5',
  _200 = '#F5D4D5',
  _300 = '#CC2C2F',
  _400 = '#8F1E20',
  _500 = '#521112',
}

enum Orange {
  _100 = '#FDF3F0',
  _200 = '#FADDD3',
  _300 = '#F1906E',
  _400 = '#C4522A',
  _500 = '#5F1900',
}

enum Yellow {
  _100 = '#FDF7ED',
  _200 = '#F9E9CA',
  _300 = '#ECB751',
  _400 = '#C98B13',
  _500 = '#875B05',
}

enum Brand {
  _100 = '#F4F6FF',
  _200 = '#E1E5FA',
  _300 = '#3455F4',
  _400 = '#0628CC',
  _500 = '#001580',
}

enum Green {
  _100 = '#EFF8F3',
  _200 = '#D0EBDB',
  _300 = '#65BD8A',
  _400 = '#216F42',
  _500 = '#07331A',
}

enum Purple {
  _100 = '#F9F6FF',
  _200 = '#EEE6FF',
  _300 = '#C8AEFF',
  _400 = '#7A52CD',
  _500 = '#282233',
}

enum Blue {
  _100 = '#EBF5FF',
  _200 = '#D1E9FF',
  _300 = '#8EC7FC',
  _400 = '#1B7CD5',
  _500 = '#01396C',
}

export type Color = typeof color
const color = Object.freeze({
  primary: '#3455F4',
  black: '#1F1F1F',
  white: '#FFFFFF',
  transparent: '#00000000',

  grey_600: '#676973',
  grey_400: '#9A9BA2',
  grey_300: '#B3B4B9',
  grey_160: '#D6D7DA',
  grey_100: '#E6E6E8',
  grey_80: '#EBEBEC',
  grey_70: '#EDEEEF',
  grey_60: '#F0F0F1',
  grey_50: '#F2F3F3',
  grey_20: '#FAFAFA',

  attention: '#F1906E',
  warning: '#EB7257',
  upgrade: '#FDB90B',
  highlightGreen: '#5DAE7F',

  disabled: '#C2C3C7',
  background: '#F7F8F8',
  hover: '#F5F5F6',
  lightboxBackground: '#00000040',
  popupBackground: '#00000040',
  backgroundBlue: 'rgba(239, 241, 254, 1)',
  eletricBlue: 'rgba(52, 85, 244, 1)',

  // TODO: check figma, after ACC implementation should be removed
  dexcomGreen: '#63A844',
  lightYellow: '#FFF9EB',
  textIcon: {
    light: '#9A9BA2',
    title: Grey._1000,
    secondary: Grey._700,
    hint: Grey._700,
    greyIcon: Grey._600,
    link: Brand._300,
    white: Grey.white,
    warning: Red._300,
    attention: Yellow._400,
    success: Green._400,
  },
  border: {
    black: Grey._1000,
    dark: Grey._600,
    default: Grey._400,
    light: Grey._300,
    disabled: Grey._300,
    blue: Brand._300,
    blueLight: Brand._200,
    blueDark: '#C2CCFC',
    _80: '#EBEBEC',
    _100: '#E6E6E8',
    _160: '#D6D7DA',
    _400: '#737582',
  },
  state: {
    default: Brand._300,
    warning: Red._300,
    disabled: Grey._500,
    success: Green._300,
  },
  surface: {
    popup: '#00000040',
    grey: {
      background: '#F7F8F8',
      light: '#F7F8F8',
      dark: '#F2F3F3',
    },
    orange: {
      light: '#FEF6F3',
      background: '#EE9A6B',
    },
    white: Grey.white,
    default: Grey._100,
    nested: Grey._300,
    disabled: Grey._500,
    dark: Grey._1000,
    warning: Red._100,
    attention: Yellow._100,
    upgrade: Yellow._300,
    blue: {
      light: Brand._100,
      dark: Brand._200,
      brand: Brand._300,
    },
    hover: {
      warning: Red._400,
      blue: Brand._400,
      upgrade: Yellow._400,
      hoverGreyBg: Grey._300,
      hoverWhiteBg: Grey._200,
      white: '#F6F6F6',
    },
  },
  task: {
    yellow: Yellow._300,
    green: Green._300,
    blue: Blue._300,
    purple: Purple._300,
    red: Red._300,
    orange: Orange._300,
    grey: Grey._700,
  },
  tag: {
    blue: {
      light: Blue._100,
      dark: Blue._400,
    },
    yellow: {
      light: Yellow._100,
      dark: Yellow._400,
    },
    grey: {
      light: Grey._300,
      dark: Grey._700,
    },
    green: {
      light: Green._100,
      dark: Green._400,
    },
    purple: {
      light: Purple._100,
      dark: Purple._400,
    },
    orange: {
      light: Orange._100,
      dark: Orange._400,
    },
    red: {
      light: Red._100,
      dark: Red._400,
    },
  },
})

export type TagColor = typeof tagColor
const tagColor = Object.freeze({
  Green: '#48A770',
  Yellow: '#ECAE0D',
  Purple: '#A57BFF',
  Blue: '#5CAEFB',
  Red: '#EC5151',
  Grey: '#808397',
  Orange: '#ED9442',
})

const tagBackgroundColorOpacity = 0.1

export type ColorTranslator = typeof colorTranslator
export const colorTranslator = (index: number) => {
  return taskColorList[index % 7]
}

export type TaskColorList = typeof taskColorList
const taskColorList = Object.freeze([
  '#65BD8A', // taskGreen
  '#ECB751', // taskYellow
  '#C8AEFF', // taskPurple
  '#F1906E', // taskOrange
  '#8EC7FC', // taskBlue
  '#EC5151', // taskRed
  '#9C9EAE', // taskGrey
])

export type Pad = typeof pad
const pad = Object.freeze({
  xs: '.25rem',
  small: '.3125rem', // 5px
  mediumSmall: '.625rem', // 10px
  medium: '.875rem', // 14px
  slightlyLarger: '1rem', // 16px
  large: '1.15rem', // 20px
  widthPadding: '1.5625rem', // 25px
  xl: '1.875rem', // 30px
})

export type BorderRadius = typeof borderRadius
const borderRadius = Object.freeze({
  default: '5px',
  popup: '16px',
})

export const create_theme_init_state = () => {
  return {
    pad,
    color,
    fontSize,
    fontWeight,
    taskColorList,
    tagColor,
    tagBackgroundColorOpacity,
    colorTranslator,
    borderRadius,
  }
}
