import {selectTheme} from '../../../store/state/theme'
import {defaultSvgProps, SvgProps} from '../type/props'

// a hourglass icon
export const WindowIcon = (props?: SvgProps) => {
  const {color} = selectTheme()
  const {width, height, fill, flexShrink} = defaultSvgProps(props, {
    width: 16,
    height: 16,
    fill: color.textIcon.title,
    flexShrink: 0,
  })

  return (
    <svg
      width={width}
      height={height}
      style={{flexShrink}}
      viewBox='0 0 8 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 0.333374V4.33337H0.00666682L0 4.34004L2.66667 7.00004L0 9.66671L0.00666682 9.67337H0V13.6667H8V9.67337H7.99333L8 9.66671L5.33333 7.00004L8 4.34004L7.99333 4.33337H8V0.333374H0ZM6.66667 10V12.3334H1.33333V10L4 7.33337L6.66667 10ZM4 6.66671L1.33333 4.00004V1.66671H6.66667V4.00004L4 6.66671Z'
        fill={fill}
      />
    </svg>
  )
}
