import {selectTheme} from '../../store'
import {RIF} from '../../lib'
import {CSSProperties} from 'react'

export interface DropdownMenuProps {
  dropped: boolean
  options: ({
    text: string
    img?: any
    onClick: () => void
  } | null)[]
  parentCSS?: CSSProperties
}

export const DropdownMenu = (props: DropdownMenuProps) => {
  const {color} = selectTheme()
  const {dropped, options, parentCSS} = props
  const visibleOptions = options.flatMap((option) => (option ? [option] : []))

  return (
    <div
      css={{
        minWidth: '160px',
        background: color.white,
        border: `1px solid ${color.grey_160}`,
        borderRadius: '5px',
        display: `${dropped ? 'flex' : 'none'}`,
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: 'auto',
        padding: '8px 0',
        boxShadow: '0px 4px 10px 1px #68686840',
        zIndex: 999,
        position: 'absolute',
        ...parentCSS,
      }}
    >
      {visibleOptions.map((option, index) => (
        <DropdownSelection
          onClick={option.onClick}
          {...{
            key: index,
            img: option.img,
            text: option.text,
          }}
        />
      ))}
    </div>
  )
}

const DropdownSelection = (props: {key: number; img?: any; text: string; css?: any; onClick: () => void}) => {
  const {fontWeight, color} = selectTheme()
  const {img, text} = props
  return (
    <div
      {...props}
      css={{
        cursor: 'pointer',
        display: 'flex',
        padding: '8px 16px',
        alignItems: 'center',
        minWidth: 'fit-content',
        ':hover': {
          background: 'rgba(156, 158, 174, 0.05)',
        },
      }}
    >
      {RIF(img, <img src={img} width='24' css={{marginRight: '7px'}} />)}
      <span
        css={{
          fontWeight: fontWeight.bold,
          color: color.black,
          cursor: 'pointer',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </span>
    </div>
  )
}
