import {isNil} from 'lodash'
import {Nullish} from 'utility-types'
import {useEffect, useMemo, useState} from 'react'
import {useToggle} from 'react-use'

import {IProjectScheduleConfig, ProjectScheduleFrequency} from '../../shared/db'
import {createDispatchActions, selectTheme} from '../../store'
import {darken, debug, RequestResult} from '../../lib'
import {ProjectScheduleConfigHelper, ProjectScheduleSettingType} from '../../model'
import {ChevronDownIcon} from '../../lib/svg/icon/chevron_down'
import {CloseIcon} from '../../lib/svg/icon/close'
import {RadioButtonIcon} from '../../lib/svg/icon/radio'

export interface EditProjectSchedulePopupProps {
  projectId: string
  projectScheduleSettingType: ProjectScheduleSettingType | Nullish
  scheduleConfig: IProjectScheduleConfig | Nullish
  onSaveComplete: () => void
  onClose: () => void
}

type EditableScheduleConfig = Partial<
  Pick<IProjectScheduleConfig, 'frequency' | 'reference' | 'absoluteReferenceYymmdd'>
>

enum EditProjectScheduleType {
  Daily,
  Weekly,
  EventTracker,
  Legacy,
}

const EditProjectScheduleLabel: Readonly<Record<EditProjectScheduleType, string>> = {
  [EditProjectScheduleType.Daily]: 'Daily Basis',
  [EditProjectScheduleType.Weekly]: 'Weekly Basis (Coming Soon)',
  [EditProjectScheduleType.EventTracker]: 'Event Tracking (Coming Soon)',
  [EditProjectScheduleType.Legacy]: 'No Schedule (for old projects)',
} as const

const EditProjectScheduleDescription: Readonly<Record<EditProjectScheduleType, string>> = {
  [EditProjectScheduleType.Daily]:
    'The task will appear in your daily task list and must be completed on the same day.',
  [EditProjectScheduleType.Weekly]:
    'The task will appear in your weekly task list and must be completed within the week.',
  [EditProjectScheduleType.EventTracker]:
    "The task won't be tied to a specific day or week. You can complete it anytime.",
  [EditProjectScheduleType.Legacy]: 'This is used for projects created before April 2025.',
} as const

const EditProjectScheduleEnabled: Readonly<Record<EditProjectScheduleType, boolean>> = {
  [EditProjectScheduleType.Daily]: true,
  [EditProjectScheduleType.Weekly]: false,
  [EditProjectScheduleType.EventTracker]: false,
  [EditProjectScheduleType.Legacy]: true,
} as const

const parseEditProjectScheduleType = (args: {
  projectScheduleSettingType: ProjectScheduleSettingType | Nullish
  scheduleConfig: IProjectScheduleConfig | Nullish
}): EditProjectScheduleType | null => {
  const {projectScheduleSettingType, scheduleConfig} = args
  if (isNil(projectScheduleSettingType)) {
    return null
  }
  if (projectScheduleSettingType === ProjectScheduleSettingType.Legacy) {
    return EditProjectScheduleType.Legacy
  }
  if (projectScheduleSettingType === ProjectScheduleSettingType.EventTracker) {
    return EditProjectScheduleType.EventTracker
  }
  const frequency = scheduleConfig?.frequency
  if (isNil(frequency)) {
    console.error('unexpected nil frequency')
    return null
  }
  switch (frequency) {
    case ProjectScheduleFrequency.Daily:
      return EditProjectScheduleType.Daily
    case ProjectScheduleFrequency.Weekly:
      return EditProjectScheduleType.Weekly
  }
}

const resolveScheduleEdits = (args: {selectedScheduleType: EditProjectScheduleType}): EditableScheduleConfig => {
  const {selectedScheduleType} = args
  switch (selectedScheduleType) {
    case EditProjectScheduleType.Daily:
      return {
        frequency: ProjectScheduleFrequency.Daily,
        reference: null,
        absoluteReferenceYymmdd: null,
      }
    case EditProjectScheduleType.Weekly:
      // TODO: handle reference and reference date
      return {
        frequency: ProjectScheduleFrequency.Weekly,
        reference: null,
        absoluteReferenceYymmdd: null,
      }
    case EditProjectScheduleType.EventTracker:
      return {
        frequency: null,
        reference: null,
        absoluteReferenceYymmdd: null,
      }
    case EditProjectScheduleType.Legacy:
      return {}
  }
}

export const EditProjectSchedulePopup = (props: EditProjectSchedulePopupProps) => {
  const {projectId, projectScheduleSettingType, scheduleConfig, onSaveComplete, onClose} = props

  const {color, fontSize, fontWeight} = selectTheme()
  const {
    doREQUEST_PROJECT_SCHEDULE_CONFIG_CREATE,
    doREQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE,
    doREQUEST_PROJECT_SCHEDULE_CONFIG_DELETE,
  }: any = createDispatchActions()

  const defaultScheduleType =
    parseEditProjectScheduleType({projectScheduleSettingType, scheduleConfig}) ?? EditProjectScheduleType.Daily

  const [selectedScheduleType, setSelectedScheduleType] = useState<EditProjectScheduleType>(defaultScheduleType)
  const [editedScheduleConfig, setEditedScheduleConfig] = useState<EditableScheduleConfig>(scheduleConfig ?? {})
  const [showLegacyOption, toggleShowLegacyOption] = useToggle(defaultScheduleType === EditProjectScheduleType.Legacy)

  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  useEffect(() => {
    const edits = resolveScheduleEdits({selectedScheduleType})
    setEditedScheduleConfig(edits)
  }, [selectedScheduleType])

  useEffect(() => {
    if (!requestResult?.success) {
      debug(requestResult)
      return
    }
    onSaveComplete()
  }, [requestResult])

  const validationResult = useMemo(
    () => ProjectScheduleConfigHelper.validate(editedScheduleConfig),
    [editedScheduleConfig],
  )

  const resolveRequest = (projectScheduleSettingType: ProjectScheduleSettingType): (() => void) => {
    switch (projectScheduleSettingType) {
      case ProjectScheduleSettingType.Legacy:
        if (scheduleConfig) {
          return () =>
            doREQUEST_PROJECT_SCHEDULE_CONFIG_DELETE({
              setRequestResult,
              payload: {projectId},
            })
        } else {
          return () => onSaveComplete()
        }
      case ProjectScheduleSettingType.EventTracker:
      case ProjectScheduleSettingType.Schedule: {
        const {frequency, reference, absoluteReferenceYymmdd} = editedScheduleConfig
        const payload = {
          projectId,
          frequency,
          reference,
          absoluteReferenceYymmdd,
        }
        if (scheduleConfig) {
          return () =>
            doREQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE({
              setRequestResult,
              payload,
            })
        } else {
          return () =>
            doREQUEST_PROJECT_SCHEDULE_CONFIG_CREATE({
              setRequestResult,
              payload,
            })
        }
      }
    }
  }

  const handleSubmit = () => {
    if (!validationResult.isValid) {
      debug('save not enabled, cannot handle submit')
      return
    }
    const request = resolveRequest(validationResult.projectScheduleSettingType)
    return request()
  }

  const selectionCard = (args: {
    scheduleType: EditProjectScheduleType
    selected: boolean
    onClick: (scheduleType: EditProjectScheduleType) => void
  }) => {
    const {scheduleType, selected, onClick} = args
    const label = EditProjectScheduleLabel[scheduleType]
    const description = EditProjectScheduleDescription[scheduleType]
    const isEnabled = EditProjectScheduleEnabled[scheduleType]

    return (
      <div
        key={scheduleType}
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '100%',
          padding: '16px',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderRadius: '5px',
          borderColor: isEnabled ? (selected ? color.border.black : color.border.default) : color.border.disabled,
          cursor: isEnabled ? 'pointer' : 'not-allowed',
        }}
        onClick={() => {
          if (isEnabled) {
            onClick(scheduleType)
          }
        }}
      >
        <div
          css={{
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p
            css={{
              color: isEnabled ? color.textIcon.title : color.state.disabled,
              fontSize: fontSize.h6,
              fontWeight: fontWeight.bold,
            }}
          >
            {label}
          </p>
          {selected && <RadioButtonIcon foregroundColor={isEnabled ? color.textIcon.title : color.state.disabled} />}
        </div>
        <p
          css={{
            color: isEnabled ? color.textIcon.secondary : color.state.disabled,
            fontSize: fontSize.h7,
            fontWeight: fontWeight.medium,
          }}
        >
          {description}
        </p>
      </div>
    )
  }

  const legacyToggle = () => {
    return (
      <button
        type='button'
        css={{
          all: 'unset',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          cursor: 'pointer',
        }}
        onClick={() => toggleShowLegacyOption()}
      >
        <span
          css={{
            color: color.textIcon.secondary,
            fontSize: fontSize.h7,
            fontWeight: fontWeight.bold,
            textDecorationLine: 'underline',
            textDecorationColor: color.textIcon.secondary,
            textDecorationThickness: 'from-font',
          }}
        >
          for projects created before April 2025
        </span>
        <ChevronDownIcon />
      </button>
    )
  }

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'scroll',
        top: 0,
        left: 0,
        zIndex: 103,
      }}
    >
      <div
        css={{
          width: '50%',
          background: color.white,
          borderRadius: '5px',
          boxShadow: '0px 4px 10px 0px #0000001A',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        {/* header */}
        <div
          css={{
            width: '100%',
            padding: '24px 24px 8px 24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <div
            css={{
              width: '100%',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span
              css={{
                color: color.black,
                fontSize: fontSize.h5,
                fontWeight: fontWeight.thick,
              }}
            >
              Setup Project Schedule
            </span>
            <button type='button' css={{all: 'unset', cursor: 'pointer'}} onClick={() => onClose()}>
              <CloseIcon />
            </button>
          </div>
          <p css={{color: color.textIcon.secondary, fontSize: fontSize.h6, fontWeight: fontWeight.bold}}>
            Choose how you want to schedule this task. This will determine when it appears in your task list and when it
            needs to be completed.
          </p>
        </div>

        {/* cards */}
        <div
          css={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '0 24px',
          }}
        >
          {[EditProjectScheduleType.Daily, EditProjectScheduleType.Weekly, EditProjectScheduleType.EventTracker].map(
            (scheduleType) =>
              selectionCard({
                scheduleType,
                selected: selectedScheduleType === scheduleType,
                onClick: setSelectedScheduleType,
              }),
          )}
        </div>

        {/* legacy card */}
        <div
          css={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '0 24px',
          }}
        >
          {legacyToggle()}
          {showLegacyOption &&
            selectionCard({
              scheduleType: EditProjectScheduleType.Legacy,
              selected: selectedScheduleType === EditProjectScheduleType.Legacy,
              onClick: setSelectedScheduleType,
            })}
        </div>

        {/* footer */}
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 8,
            backgroundColor: color.white,
            boxShadow: '0px 4px 10px 0px #0000001A',
            padding: '16px 24px',
            marginTop: 8,
          }}
        >
          <button
            onClick={() => onClose()}
            css={{
              padding: '8px 16px',
              borderRadius: 4,
              border: 'none',
              backgroundColor: color.surface.default,
              color: color.textIcon.secondary,
              cursor: 'pointer',
              fontSize: fontSize.h6,
              fontWeight: fontWeight.thick,
              '&:hover': {
                backgroundColor: darken(color.surface.default, 10),
              },
              '&:active': {
                backgroundColor: darken(color.surface.default, 20),
              },
              transition: 'all 0.2s ease-in-out',
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => handleSubmit()}
            css={{
              padding: '8px 16px',
              borderRadius: 4,
              border: 'none',
              backgroundColor: color.state.default,
              color: color.white,
              cursor: 'pointer',
              fontSize: fontSize.h6,
              fontWeight: fontWeight.thick,
              '&:hover': {
                backgroundColor: validationResult.isValid ? darken(color.state.default, 10) : color.state.disabled,
              },
              '&:active': {
                backgroundColor: validationResult.isValid ? darken(color.state.default, 20) : color.state.disabled,
              },
              '&:disabled': {
                backgroundColor: color.state.disabled,
                cursor: 'not-allowed',
              },
              transition: 'all 0.2s ease-in-out',
            }}
            disabled={!validationResult.isValid}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
