import v from 'joi'
import {isEmpty, isNil, isString, mapValues, padStart, pick, uniq} from 'lodash'
import {Nullish} from 'utility-types'

import {
  EmaType,
  IProjectScheduleConfig,
  ITaskSchedule,
  ProjectScheduleFrequency,
  ProjectScheduleReference,
  ScheduleDate,
  ScheduleParser,
  ScheduleTime,
  ScheduleType,
  ScheduleTypeParsed,
  TaskScheduleDateType,
  TaskScheduleScheduleType,
  TaskScheduleTaskSubTypeRecurring,
  TaskScheduleTaskType,
} from '../shared/db'
import {differenceInDays} from 'date-fns'
import {softTry} from '../engine'

export enum ProjectScheduleSettingType {
  Legacy = 'legacy',
  Schedule = 'schedule',
  EventTracker = 'event_tracker',
}

type ProjectScheduleConfigValidateResult =
  | {
      isValid: true
      projectScheduleSettingType: ProjectScheduleSettingType
    }
  | {
      isValid: false
    }

export class ProjectScheduleConfigHelper {
  // only basic checks, not full validation
  static parseType(scheduleConfig: IProjectScheduleConfig | Nullish): ProjectScheduleSettingType {
    if (isNil(scheduleConfig)) return ProjectScheduleSettingType.Legacy
    if (isNil(scheduleConfig.frequency)) return ProjectScheduleSettingType.EventTracker
    return ProjectScheduleSettingType.Schedule
  }

  static validate(scheduleConfig: Partial<IProjectScheduleConfig> | Nullish): ProjectScheduleConfigValidateResult {
    if (isNil(scheduleConfig) || isEmpty(scheduleConfig))
      return {
        isValid: true,
        projectScheduleSettingType: ProjectScheduleSettingType.Legacy,
      }

    const {frequency, reference, absoluteReferenceYymmdd} = scheduleConfig

    if (isNil(frequency)) {
      if (isNil(reference) && isNil(absoluteReferenceYymmdd)) {
        return {
          isValid: true,
          projectScheduleSettingType: ProjectScheduleSettingType.EventTracker,
        }
      }
      return {
        isValid: false,
      }
    }

    const projectScheduleSettingType = ProjectScheduleSettingType.Schedule
    switch (frequency) {
      case ProjectScheduleFrequency.Weekly:
        if (isNil(reference)) {
          return {isValid: false}
        }
        switch (reference) {
          case ProjectScheduleReference.Participant:
            return isNil(absoluteReferenceYymmdd) ? {isValid: true, projectScheduleSettingType} : {isValid: false}
          case ProjectScheduleReference.Absolute:
            return ScheduleDate.isYymmdd(absoluteReferenceYymmdd ?? undefined)
              ? {isValid: true, projectScheduleSettingType}
              : {isValid: false}
        }
        break
      case ProjectScheduleFrequency.Daily:
        return isNil(reference) && isNil(absoluteReferenceYymmdd)
          ? {isValid: true, projectScheduleSettingType}
          : {isValid: false}
    }
  }
}

export interface TempTaskSchedule {
  tempId: string
  createdAt: Date
  values: Partial<ITaskSchedule>
}

type TaskScheduleSchemaMapType = v.StrictSchemaMap<Omit<ITaskSchedule, 'createdAt' | 'updatedAt' | 'count'>>

export const getTaskScheduleSchemaMap = (args?: {
  required?: (keyof TaskScheduleSchemaMapType)[]
}): TaskScheduleSchemaMapType => {
  const schemaMap: TaskScheduleSchemaMapType = {
    id: v.string().uuid().optional(),
    taskId: v.string().uuid().optional(),
    inactive: v.bool().optional(),
    type: v
      .string()
      .valid(...Object.values(ScheduleType))
      .optional(),
    scheduleYear: v.number().integer().min(0).optional(),
    scheduleMonth: v.number().integer().min(0).optional(),
    scheduleDay: v.number().integer().min(0).optional(),
    scheduleHour: v.number().integer().min(0).optional(),
    scheduleMinute: v.number().integer().min(0).optional(),
    scheduleSecond: v.number().integer().min(0).optional(),
    scheduleWeekdays: v.number().integer().min(1).max(1234567).optional(),
    relativeStartDayOrYymmdd: v.number().integer().positive().optional(),
    relativeEndDayOrYymmdd: v.number().integer().positive().optional(),
    startTime: v.string().regex(ScheduleTime.REGEX).optional(),
    endTime: v.string().regex(ScheduleTime.REGEX).optional(),
    emaType: v
      .string()
      .allow(...Object.values(EmaType))
      .optional(),
    emaOcurrences: v.number().integer().positive().optional(),
    emaIntervalSeconds: v.number().integer().positive().optional(),
    emaMinimumGapSeconds: v.number().integer().positive().optional(),
    emaExpirationSeconds: v.number().integer().positive().optional(),
  }

  if (args?.required?.length) {
    const requiredKeys = args.required
    return mapValues(schemaMap, (v, k) =>
      requiredKeys.includes(k as keyof TaskScheduleSchemaMapType) ? v.required() : v,
    ) as TaskScheduleSchemaMapType
  }
  return schemaMap
}

export enum ScheduleFormScheduleType {
  FixedSchedule = 'fixedSchedule',
  SemiRandomizedEMA = 'semiRandomizedEMA',
  FullyRandomizedEMA = 'fullyRandomizedEMA',
}

export enum ScheduleFormScheduleSubTypeFixedSchedule {
  SpecificTime = 'specificTime',
  Interval = 'interval',
}

export type ScheduleFormScheduleTypeParsed = {
  dateType: TaskScheduleDateType
} & (
  | {
      taskType: TaskScheduleTaskType.OneTimeOnly
      scheduleType: ScheduleFormScheduleType.FixedSchedule | ScheduleFormScheduleType.FullyRandomizedEMA
    }
  | ({
      taskType: TaskScheduleTaskType.Recurring
      taskSubType:
        | TaskScheduleTaskSubTypeRecurring.Daily
        | TaskScheduleTaskSubTypeRecurring.EveryXDays
        | TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek
    } & (
      | {
          scheduleType: ScheduleFormScheduleType.FixedSchedule
          scheduleSubType: ScheduleFormScheduleSubTypeFixedSchedule
        }
      | {
          scheduleType: ScheduleFormScheduleType.SemiRandomizedEMA | ScheduleFormScheduleType.FullyRandomizedEMA
        }
    ))
  | ({
      taskType: TaskScheduleTaskType.Recurring
      taskSubType: TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay
    } & (
      | {
          scheduleType: ScheduleFormScheduleType.FixedSchedule
          scheduleSubType: ScheduleFormScheduleSubTypeFixedSchedule.Interval
        }
      | {
          scheduleType: ScheduleFormScheduleType.SemiRandomizedEMA | ScheduleFormScheduleType.FullyRandomizedEMA
        }
    ))
)

export class ScheduleFormHelper {
  private constructor(private readonly parser: ScheduleParser) {}

  static fromParser(parser: ScheduleParser): ScheduleFormHelper {
    return new ScheduleFormHelper(parser)
  }

  static fromSchedule(schedule: Partial<ITaskSchedule>): ScheduleFormHelper {
    const parser = new ScheduleParser(schedule)
    return this.fromParser(parser)
  }

  static toScheduleType(
    scheduleType: ScheduleFormScheduleType,
    scheduleSubType?: ScheduleFormScheduleSubTypeFixedSchedule,
  ): TaskScheduleScheduleType {
    if (scheduleSubType === ScheduleFormScheduleSubTypeFixedSchedule.Interval) {
      return TaskScheduleScheduleType.FixedEMA
    }
    switch (scheduleType) {
      case ScheduleFormScheduleType.FixedSchedule:
        return TaskScheduleScheduleType.FixedSchedule
      case ScheduleFormScheduleType.SemiRandomizedEMA:
        return TaskScheduleScheduleType.SemiRandomizedEMA
      case ScheduleFormScheduleType.FullyRandomizedEMA:
        return TaskScheduleScheduleType.FullyRandomizedEMA
    }
  }

  tryParse(): ScheduleFormScheduleTypeParsed {
    const result = this.parser.tryParse()
    return this.convertParsed(result)
  }

  softTryParse() {
    return softTry(() => this.tryParse())
  }

  private convertParsed(parsed: ScheduleTypeParsed): ScheduleFormScheduleTypeParsed {
    switch (parsed.taskType) {
      case TaskScheduleTaskType.OneTimeOnly:
        switch (parsed.scheduleType) {
          case TaskScheduleScheduleType.FixedSchedule:
            return {
              ...parsed,
              scheduleType: ScheduleFormScheduleType.FixedSchedule,
            }
          case TaskScheduleScheduleType.FullyRandomizedEMA:
            return {
              ...parsed,
              scheduleType: ScheduleFormScheduleType.FullyRandomizedEMA,
            }
        }
        break
      case TaskScheduleTaskType.Recurring:
        switch (parsed.taskSubType) {
          case TaskScheduleTaskSubTypeRecurring.Daily:
          case TaskScheduleTaskSubTypeRecurring.EveryXDays:
          case TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek:
            switch (parsed.scheduleType) {
              case TaskScheduleScheduleType.FixedSchedule:
                return {
                  ...parsed,
                  scheduleType: ScheduleFormScheduleType.FixedSchedule,
                  scheduleSubType: ScheduleFormScheduleSubTypeFixedSchedule.SpecificTime,
                }
              case TaskScheduleScheduleType.SemiRandomizedEMA:
                return {
                  ...parsed,
                  scheduleType: ScheduleFormScheduleType.SemiRandomizedEMA,
                }
              case TaskScheduleScheduleType.FullyRandomizedEMA:
                return {
                  ...parsed,
                  scheduleType: ScheduleFormScheduleType.FullyRandomizedEMA,
                }
              case TaskScheduleScheduleType.FixedEMA:
                return {
                  ...parsed,
                  scheduleType: ScheduleFormScheduleType.FixedSchedule,
                  scheduleSubType: ScheduleFormScheduleSubTypeFixedSchedule.Interval,
                }
            }
            break
          case TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay:
            switch (parsed.scheduleType) {
              case TaskScheduleScheduleType.FixedEMA:
                return {
                  ...parsed,
                  scheduleType: ScheduleFormScheduleType.FixedSchedule,
                  scheduleSubType: ScheduleFormScheduleSubTypeFixedSchedule.Interval,
                }
              case TaskScheduleScheduleType.SemiRandomizedEMA:
                return {
                  ...parsed,
                  scheduleType: ScheduleFormScheduleType.SemiRandomizedEMA,
                }
              case TaskScheduleScheduleType.FullyRandomizedEMA:
                return {
                  ...parsed,
                  scheduleType: ScheduleFormScheduleType.FullyRandomizedEMA,
                }
            }
        }
    }
  }
}

export interface ScheduleDescription {
  scheduleTaskType: TaskScheduleTaskType.OneTimeOnly | TaskScheduleTaskType.Recurring
  scheduleTaskTypeDescription: string
  dateDescription: string
  timeDescription?: string
  emaScheduleType?: TaskScheduleScheduleType.SemiRandomizedEMA | TaskScheduleScheduleType.FullyRandomizedEMA
  emaScheduleTypeDescription?: string
  emaFlexibilityDescription?: {
    prefixText: string
    valueText: string
  }
  emaExpirationDescription?: {
    prefixText: string
    valueText: string
  }
}

export class ScheduleDescriptionCreator {
  private constructor(private readonly schedule: Partial<ITaskSchedule>, private readonly parsed: ScheduleTypeParsed) {}

  static fromSchedule(schedule: Partial<ITaskSchedule>, throws = false): ScheduleDescriptionCreator | undefined {
    const parser = new ScheduleParser(schedule)
    const parsed = softTry(() => parser.tryParse())
    if (!parsed.success) {
      if (throws) {
        throw parsed.error
      }
      return
    }
    return new ScheduleDescriptionCreator(schedule, parsed.value)
  }

  create(): ScheduleDescription {
    return {
      scheduleTaskType: this.parsed.taskType,
      scheduleTaskTypeDescription: this.createScheduleTypeDescription(),
      emaFlexibilityDescription: this.createEmaFlexibilityDescription(),
      emaExpirationDescription: this.createEmaExpirationDescription(),
      dateDescription: this.createDateDescription(),
      timeDescription: this.createTimeDescription(),
      ...this.createEmaDescription(),
    }
  }

  private createScheduleTypeDescription(): string {
    switch (this.parsed.taskType) {
      case TaskScheduleTaskType.OneTimeOnly:
        return 'One-Time Only'
      case TaskScheduleTaskType.Recurring:
        switch (this.parsed.taskSubType) {
          case TaskScheduleTaskSubTypeRecurring.Daily:
            return 'Recurring Daily'
          case TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek:
            return 'Recurring on specific day(s) of the week'
          case TaskScheduleTaskSubTypeRecurring.EveryXDays:
            return `Recurring every ${this.schedule.scheduleDay ?? -1} days`
          case TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay:
            return 'Recurring multiple times in a day'
        }
    }
  }

  private createDateDescription(): string {
    switch (this.parsed.taskType) {
      case TaskScheduleTaskType.OneTimeOnly:
        return ((): string => {
          switch (this.parsed.scheduleType) {
            case TaskScheduleScheduleType.FixedSchedule: {
              const {startDate, endDate} = this.getPeriod()
              const {startTime, endTime} = this.schedule
              return `${startDate} ${startTime} ${endDate ? `- ${endDate} ${endTime}` : 'until completed'}`
            }
            case TaskScheduleScheduleType.FullyRandomizedEMA:
              return this.scheduleDateComponentsToDateString() ?? 'Day -1'
          }
        })()
      case TaskScheduleTaskType.Recurring:
        return ((): string => {
          switch (this.parsed.taskSubType) {
            case TaskScheduleTaskSubTypeRecurring.Daily: {
              const {startDate, endDate} = this.getPeriod()
              return `Everyday from ${startDate} ${endDate ? `to ${endDate}` : 'and continues indefinitely'}`
            }
            case TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek: {
              const {startDate, endDate} = this.getPeriod()
              const weekdays = ScheduleWeekdaysHelper.getWeekdaysString(this.schedule.scheduleWeekdays ?? 0)
              return `Every ${weekdays.join(', ')} from ${startDate} ${
                endDate ? `to ${endDate}` : 'and continues indefinitely'
              }`
            }
            case TaskScheduleTaskSubTypeRecurring.EveryXDays: {
              const {startDate, endDate} = this.getPeriod()
              return `Every ${this.schedule.scheduleDay ?? -1} days from ${startDate} ${
                endDate ? `to ${endDate}` : 'and continues indefinitely'
              }`
            }
            case TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay:
              return this.scheduleDateComponentsToDateString() ?? 'Day -1'
          }
        })()
    }
  }

  private createTimeDescription(): string | undefined {
    switch (this.parsed.taskType) {
      case TaskScheduleTaskType.OneTimeOnly:
        return ((): string | undefined => {
          switch (this.parsed.scheduleType) {
            case TaskScheduleScheduleType.FullyRandomizedEMA:
              return `Randomly appears between ${this.schedule.startTime} and ${this.schedule.endTime}`
            case TaskScheduleScheduleType.FixedSchedule:
              return
          }
        })()
      case TaskScheduleTaskType.Recurring:
        return ((): string => {
          switch (this.parsed.scheduleType) {
            case TaskScheduleScheduleType.FixedSchedule:
              return `Appears at ${this.schedule.startTime} and expires at ${this.schedule.endTime}`
            case TaskScheduleScheduleType.FixedEMA:
              return `Appears every ${this.secondsToString(this.schedule.emaIntervalSeconds ?? -1)} between ${
                this.schedule.startTime
              } and ${this.schedule.endTime}`
            case TaskScheduleScheduleType.SemiRandomizedEMA: {
              const intervalString = this.schedule.emaIntervalSeconds
                ? this.secondsToString(this.schedule.emaIntervalSeconds)
                : '-1 hour'
              return `Appears approximately every ${intervalString} between ${this.schedule.startTime} and ${this.schedule.endTime}`
            }
            case TaskScheduleScheduleType.FullyRandomizedEMA: {
              const ocurrences = this.schedule.emaOcurrences ?? -1
              const ocurrencesString = `${ocurrences} time${ocurrences > 1 ? 's' : ''}`
              return `Appears ${ocurrencesString} randomly between ${this.schedule.startTime} and ${this.schedule.endTime}`
            }
          }
        })()
    }
  }

  private createEmaDescription(): Pick<ScheduleDescription, 'emaScheduleType' | 'emaScheduleTypeDescription'> {
    switch (this.parsed.scheduleType) {
      case TaskScheduleScheduleType.SemiRandomizedEMA:
        return {
          emaScheduleType: TaskScheduleScheduleType.SemiRandomizedEMA,
          emaScheduleTypeDescription: 'Semi-Random',
        }
      case TaskScheduleScheduleType.FullyRandomizedEMA:
        return {
          emaScheduleType: TaskScheduleScheduleType.FullyRandomizedEMA,
          emaScheduleTypeDescription: 'Fully-Random',
        }
      case TaskScheduleScheduleType.FixedEMA:
      case TaskScheduleScheduleType.FixedSchedule:
        return {
          emaScheduleType: undefined,
          emaScheduleTypeDescription: undefined,
        }
    }
  }

  private createEmaFlexibilityDescription(): ScheduleDescription['emaFlexibilityDescription'] | undefined {
    const {emaIntervalSeconds, emaMinimumGapSeconds} = this.schedule
    if (!emaMinimumGapSeconds) return

    if (emaIntervalSeconds) {
      return {
        prefixText: `Flexibility window of +/-`,
        valueText: this.secondsToString((emaIntervalSeconds - emaMinimumGapSeconds) / 2),
      }
    }
    return {
      prefixText: `Minimum Interval of`,
      valueText: this.secondsToString(emaMinimumGapSeconds),
    }
  }

  private createEmaExpirationDescription(): ScheduleDescription['emaExpirationDescription'] | undefined {
    const {emaExpirationSeconds} = this.schedule
    if (!emaExpirationSeconds) return
    return {
      prefixText: `The task remains visible for`,
      valueText: this.secondsToString(emaExpirationSeconds),
    }
  }

  private scheduleDateComponentsToDateString(): string | undefined {
    const {scheduleYear, scheduleMonth, scheduleDay} = this.schedule
    if (scheduleYear && scheduleMonth) {
      return this.dateComponentsToString(scheduleYear, scheduleMonth, scheduleDay)
    }
    if (scheduleDay) {
      return this.dayToString(scheduleDay)
    }
  }

  private getPeriod(): {startDate: string; endDate?: string} {
    const startDate = this.dayToString(this.schedule.relativeStartDayOrYymmdd ?? -1)
    const endDate = this.schedule.relativeEndDayOrYymmdd
      ? this.dayToString(this.schedule.relativeEndDayOrYymmdd)
      : undefined
    return {startDate, endDate}
  }

  private dayToString(relativeDayOrYymmdd: number): string {
    if (ScheduleDate.isYymmdd(relativeDayOrYymmdd)) {
      const parsedDate = ScheduleDate.fromYymmdd(relativeDayOrYymmdd)
      return this.dateComponentsToString(parsedDate.year, parsedDate.month, parsedDate.day)
    }
    return `Day ${relativeDayOrYymmdd}`
  }

  private dateComponentsToString(year: number, month: number, day?: number): string {
    let dateString = `${year}/${month.toString().padStart(2, '0')}`
    if (day) {
      dateString += `/${day.toString().padStart(2, '0')}`
    }
    return dateString
  }

  private secondsToString(seconds: number): string {
    const days = Math.floor(seconds / 86400)
    const hours = Math.floor((seconds % 86400) / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const parts = []
    if (days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`)
    if (hours > 0) parts.push(`${hours} hr${hours > 1 ? 's' : ''}`)
    if (minutes > 0) parts.push(`${minutes} min${minutes > 1 ? 's' : ''}`)
    return parts.join(' ')
  }
}

export class ScheduleModifier<T extends Partial<ITaskSchedule>> {
  constructor(private readonly schedule: T, private readonly validate = false) {}

  tryValidate() {
    return new ScheduleModifier({...this.schedule}, true)
  }

  modify(): T {
    if (this.validate) {
      console.log('validating schedule', this.schedule)
      const parser = new ScheduleParser({...this.schedule})
      parser.tryParse()
    }
    return {
      ...this.schedule,
    }
  }

  updateType(criteria: {taskType: TaskScheduleTaskType; dateType: TaskScheduleDateType}): ScheduleModifier<T> {
    const type = (() => {
      switch (criteria.taskType) {
        case TaskScheduleTaskType.OneTimeOnly:
          switch (criteria.dateType) {
            case TaskScheduleDateType.Absolute:
              return ScheduleType.Absolute
            case TaskScheduleDateType.Relative:
              return ScheduleType.Relative
          }
          break
        case TaskScheduleTaskType.Recurring:
          return ScheduleType.Recurring
      }
    })()

    return new ScheduleModifier({...this.schedule, type})
  }

  updateEmaType(scheduleType: TaskScheduleScheduleType): ScheduleModifier<T> {
    const emaType = (() => {
      switch (scheduleType) {
        case TaskScheduleScheduleType.FixedEMA:
          return EmaType.Fixed
        case TaskScheduleScheduleType.SemiRandomizedEMA:
          return EmaType.SemiRandom
        case TaskScheduleScheduleType.FullyRandomizedEMA:
          return EmaType.Random
        case TaskScheduleScheduleType.FixedSchedule:
          return
      }
    })()
    if (emaType) {
      return new ScheduleModifier({...this.schedule, emaType})
    }
    return this
  }

  withOnlyValidKeys(criteria: {
    taskType: TaskScheduleTaskType
    taskSubType: TaskScheduleTaskSubTypeRecurring
    scheduleType: TaskScheduleScheduleType
    dateType: TaskScheduleDateType
  }): ScheduleModifier<T> {
    const keys: (keyof ITaskSchedule)[] = (() => {
      switch (criteria.taskType) {
        case TaskScheduleTaskType.OneTimeOnly:
          return this.getOneTimeOnlyKeys(criteria.scheduleType, criteria.dateType)
        case TaskScheduleTaskType.Recurring:
          return this.getRecurringKeys(criteria.taskSubType, criteria.scheduleType, criteria.dateType)
      }
    })()

    return new ScheduleModifier(pick(this.schedule, ...keys) as T)
  }

  withId(id?: string): ScheduleModifier<T> {
    if (id) {
      return new ScheduleModifier({...this.schedule, id})
    }
    return this
  }

  withTaskId(taskId?: string): ScheduleModifier<T> {
    if (taskId) {
      return new ScheduleModifier({...this.schedule, taskId})
    }
    return this
  }

  private getOneTimeOnlyKeys(
    scheduleType: TaskScheduleScheduleType,
    dateType: TaskScheduleDateType,
  ): (keyof ITaskSchedule)[] {
    switch (scheduleType) {
      case TaskScheduleScheduleType.FixedSchedule:
        return ['type', 'relativeStartDayOrYymmdd', 'relativeEndDayOrYymmdd', 'startTime', 'endTime']
      case TaskScheduleScheduleType.FullyRandomizedEMA:
        switch (dateType) {
          case TaskScheduleDateType.Absolute:
            return [
              'type',
              'scheduleYear',
              'scheduleMonth',
              'scheduleDay',
              'startTime',
              'endTime',
              'emaType',
              'emaExpirationSeconds',
            ]
          case TaskScheduleDateType.Relative:
            return ['type', 'scheduleDay', 'startTime', 'endTime', 'emaType', 'emaExpirationSeconds']
        }
        break
      default:
        return []
    }
  }

  private getRecurringKeys(
    recurringTaskSubType: TaskScheduleTaskSubTypeRecurring,
    scheduleType: TaskScheduleScheduleType,
    dateType: TaskScheduleDateType,
  ): (keyof ITaskSchedule)[] {
    switch (recurringTaskSubType) {
      case TaskScheduleTaskSubTypeRecurring.Daily:
        return this.getRecurringDailyKeys(scheduleType)
      case TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek:
        return this.getRecurringSpecificDaysOfTheWeekKeys(scheduleType)
      case TaskScheduleTaskSubTypeRecurring.EveryXDays:
        return this.getRecurringEveryXDaysKeys(scheduleType)
      case TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay:
        return this.getRecurringMultipleTimesInOneDayKeys(scheduleType, dateType)
    }
  }

  private getRecurringDailyKeys(scheduleType: TaskScheduleScheduleType): (keyof ITaskSchedule)[] {
    switch (scheduleType) {
      case TaskScheduleScheduleType.FixedSchedule:
        return ['type', 'relativeStartDayOrYymmdd', 'relativeEndDayOrYymmdd', 'startTime', 'endTime']
      case TaskScheduleScheduleType.FixedEMA:
        return [
          'type',
          'relativeStartDayOrYymmdd',
          'relativeEndDayOrYymmdd',
          'startTime',
          'endTime',
          'emaType',
          'emaIntervalSeconds',
          'emaExpirationSeconds',
        ]
      case TaskScheduleScheduleType.SemiRandomizedEMA:
        return [
          'type',
          'relativeStartDayOrYymmdd',
          'relativeEndDayOrYymmdd',
          'startTime',
          'endTime',
          'emaType',
          'emaIntervalSeconds',
          'emaMinimumGapSeconds',
          'emaExpirationSeconds',
        ]
      case TaskScheduleScheduleType.FullyRandomizedEMA:
        return [
          'type',
          'relativeStartDayOrYymmdd',
          'relativeEndDayOrYymmdd',
          'startTime',
          'endTime',
          'emaType',
          'emaOcurrences',
          'emaMinimumGapSeconds',
          'emaExpirationSeconds',
        ]
    }
  }

  private getRecurringSpecificDaysOfTheWeekKeys(scheduleType: TaskScheduleScheduleType): (keyof ITaskSchedule)[] {
    switch (scheduleType) {
      case TaskScheduleScheduleType.FixedSchedule:
        return [
          'type',
          'scheduleWeekdays',
          'relativeStartDayOrYymmdd',
          'relativeEndDayOrYymmdd',
          'startTime',
          'endTime',
        ]
      case TaskScheduleScheduleType.FixedEMA:
        return [
          'type',
          'scheduleWeekdays',
          'relativeStartDayOrYymmdd',
          'relativeEndDayOrYymmdd',
          'startTime',
          'endTime',
          'emaType',
          'emaIntervalSeconds',
          'emaExpirationSeconds',
        ]
      case TaskScheduleScheduleType.SemiRandomizedEMA:
        return [
          'type',
          'scheduleWeekdays',
          'relativeStartDayOrYymmdd',
          'relativeEndDayOrYymmdd',
          'startTime',
          'endTime',
          'emaType',
          'emaIntervalSeconds',
          'emaMinimumGapSeconds',
          'emaExpirationSeconds',
        ]
      case TaskScheduleScheduleType.FullyRandomizedEMA:
        return [
          'type',
          'scheduleWeekdays',
          'relativeStartDayOrYymmdd',
          'relativeEndDayOrYymmdd',
          'startTime',
          'endTime',
          'emaType',
          'emaOcurrences',
          'emaMinimumGapSeconds',
          'emaExpirationSeconds',
        ]
    }
  }

  private getRecurringEveryXDaysKeys(scheduleType: TaskScheduleScheduleType): (keyof ITaskSchedule)[] {
    switch (scheduleType) {
      case TaskScheduleScheduleType.FixedSchedule:
        return ['type', 'scheduleDay', 'relativeStartDayOrYymmdd', 'relativeEndDayOrYymmdd', 'startTime', 'endTime']
      case TaskScheduleScheduleType.FixedEMA:
        return [
          'type',
          'scheduleDay',
          'relativeStartDayOrYymmdd',
          'relativeEndDayOrYymmdd',
          'startTime',
          'endTime',
          'emaType',
          'emaIntervalSeconds',
          'emaExpirationSeconds',
        ]
      case TaskScheduleScheduleType.SemiRandomizedEMA:
        return [
          'type',
          'scheduleDay',
          'relativeStartDayOrYymmdd',
          'relativeEndDayOrYymmdd',
          'startTime',
          'endTime',
          'emaType',
          'emaIntervalSeconds',
          'emaMinimumGapSeconds',
          'emaExpirationSeconds',
        ]
      case TaskScheduleScheduleType.FullyRandomizedEMA:
        return [
          'type',
          'scheduleDay',
          'relativeStartDayOrYymmdd',
          'relativeEndDayOrYymmdd',
          'startTime',
          'endTime',
          'emaType',
          'emaOcurrences',
          'emaMinimumGapSeconds',
          'emaExpirationSeconds',
        ]
    }
  }

  private getRecurringMultipleTimesInOneDayKeys(
    scheduleType: TaskScheduleScheduleType,
    dateType: TaskScheduleDateType,
  ): (keyof ITaskSchedule)[] {
    switch (scheduleType) {
      case TaskScheduleScheduleType.FixedSchedule:
        return []
      case TaskScheduleScheduleType.FixedEMA:
        switch (dateType) {
          case TaskScheduleDateType.Absolute:
            return [
              'type',
              'scheduleYear',
              'scheduleMonth',
              'scheduleDay',
              'startTime',
              'endTime',
              'emaType',
              'emaIntervalSeconds',
              'emaExpirationSeconds',
            ]
          case TaskScheduleDateType.Relative:
            return [
              'type',
              'scheduleDay',
              'startTime',
              'endTime',
              'emaType',
              'emaIntervalSeconds',
              'emaExpirationSeconds',
            ]
        }
        break
      case TaskScheduleScheduleType.SemiRandomizedEMA:
        switch (dateType) {
          case TaskScheduleDateType.Absolute:
            return [
              'type',
              'scheduleYear',
              'scheduleMonth',
              'scheduleDay',
              'startTime',
              'endTime',
              'emaType',
              'emaIntervalSeconds',
              'emaMinimumGapSeconds',
              'emaExpirationSeconds',
            ]
          case TaskScheduleDateType.Relative:
            return [
              'type',
              'scheduleDay',
              'startTime',
              'endTime',
              'emaType',
              'emaIntervalSeconds',
              'emaMinimumGapSeconds',
              'emaExpirationSeconds',
            ]
        }
        break
      case TaskScheduleScheduleType.FullyRandomizedEMA:
        switch (dateType) {
          case TaskScheduleDateType.Absolute:
            return [
              'type',
              'scheduleYear',
              'scheduleMonth',
              'scheduleDay',
              'startTime',
              'endTime',
              'emaType',
              'emaOcurrences',
              'emaMinimumGapSeconds',
              'emaExpirationSeconds',
            ]
          case TaskScheduleDateType.Relative:
            return [
              'type',
              'scheduleDay',
              'startTime',
              'endTime',
              'emaType',
              'emaOcurrences',
              'emaMinimumGapSeconds',
              'emaExpirationSeconds',
            ]
        }
    }
  }
}

export class ScheduleFormValueHelper {
  static getSecondsInterval(form: {startTime: string | ScheduleTime; endTime: string | ScheduleTime}): number {
    const startTime = isString(form.startTime) ? ScheduleTime.parse(form.startTime) : form.startTime
    const endTime = isString(form.endTime) ? ScheduleTime.parse(form.endTime) : form.endTime
    return ScheduleTime.getSecondsInterval(startTime, endTime)
  }

  static emaMinimumGapSecondsToEmaFlexibilityWindowSeconds(
    form: Required<Pick<ITaskSchedule, 'emaIntervalSeconds' | 'emaMinimumGapSeconds'>>,
  ): number {
    return (form.emaIntervalSeconds - form.emaMinimumGapSeconds) / 2
  }

  static emaFlexibilityWindowSecondsToEmaMinimumGapSeconds(
    form: {emaFlexibilityWindowSeconds: number} & Required<Pick<ITaskSchedule, 'emaIntervalSeconds'>>,
  ): number {
    return form.emaIntervalSeconds - 2 * form.emaFlexibilityWindowSeconds
  }
}

export type ScheduleFormValidatorResult =
  | {success: true}
  | {
      success: false
      errorMessage: string
      error?: unknown
    }

export class ScheduleFormValidator {
  static validateRelativeDateTimeInterval(form: {
    relativeStartDayOrYymmdd: number
    relativeEndDayOrYymmdd?: number
    startTime: string | ScheduleTime
    endTime?: string | ScheduleTime
  }): ScheduleFormValidatorResult {
    try {
      const startTime = isString(form.startTime) ? ScheduleTime.parse(form.startTime) : form.startTime
      const start = ScheduleDate.fromRelativeDay(form.relativeStartDayOrYymmdd).withTime(startTime)
      if (form.relativeEndDayOrYymmdd && form.endTime) {
        const endTime = isString(form.endTime) ? ScheduleTime.parse(form.endTime) : form.endTime
        const end = ScheduleDate.fromRelativeDay(form.relativeEndDayOrYymmdd).withTime(endTime)
        ScheduleDate.checkValidPeriod(start, end)
      }

      return {success: true}
    } catch (error) {
      return {success: false, errorMessage: 'End time should be later than start time', error}
    }
  }

  static validateTimeInterval(form: {
    startTime: string | ScheduleTime
    endTime: string | ScheduleTime
  }): ScheduleFormValidatorResult {
    try {
      const startTime = isString(form.startTime) ? ScheduleTime.parse(form.startTime) : form.startTime
      const endTime = isString(form.endTime) ? ScheduleTime.parse(form.endTime) : form.endTime
      ScheduleTime.checkValidInterval(startTime, endTime)
      return {success: true}
    } catch (error) {
      return {success: false, errorMessage: 'End time should be later than start time', error}
    }
  }

  static validateEmaIntervalSeconds(form: {
    startTime: string | ScheduleTime
    endTime: string | ScheduleTime
    emaIntervalSeconds: number
  }): ScheduleFormValidatorResult {
    try {
      const startTime = isString(form.startTime) ? ScheduleTime.parse(form.startTime) : form.startTime
      const endTime = isString(form.endTime) ? ScheduleTime.parse(form.endTime) : form.endTime
      if (form.emaIntervalSeconds > ScheduleFormValueHelper.getSecondsInterval({startTime, endTime})) {
        return {success: false, errorMessage: 'Impossible interval'}
      }
      return {success: true}
    } catch (error) {
      return {success: false, errorMessage: 'Impossible interval', error}
    }
  }

  static validateFullyRandomizedEmaMinimumGap(form: {
    startTime: string | ScheduleTime
    endTime: string | ScheduleTime
    emaOcurrences: number
    emaMinimumGapSeconds: number
  }): ScheduleFormValidatorResult {
    try {
      const startTime = isString(form.startTime) ? ScheduleTime.parse(form.startTime) : form.startTime
      const endTime = isString(form.endTime) ? ScheduleTime.parse(form.endTime) : form.endTime
      if (
        form.emaMinimumGapSeconds >
        ScheduleFormValueHelper.getSecondsInterval({startTime, endTime}) / form.emaOcurrences
      ) {
        return {success: false, errorMessage: 'Impossible minimum interval between tasks'}
      }
      return {success: true}
    } catch (error) {
      return {success: false, errorMessage: 'Impossible minimum interval between tasks', error}
    }
  }

  static validateEveryXDays(
    form: Required<Pick<ITaskSchedule, 'scheduleDay' | 'relativeStartDayOrYymmdd' | 'relativeEndDayOrYymmdd'>>,
  ): ScheduleFormValidatorResult {
    const days = (() => {
      if (ScheduleDate.isYymmdd(form.relativeStartDayOrYymmdd) && ScheduleDate.isYymmdd(form.relativeEndDayOrYymmdd)) {
        const start = ScheduleDate.fromYymmdd(form.relativeStartDayOrYymmdd)
        const end = ScheduleDate.fromYymmdd(form.relativeEndDayOrYymmdd)
        return differenceInDays(end.toDate(), start.toDate())
      } else {
        return form.relativeEndDayOrYymmdd - form.relativeStartDayOrYymmdd
      }
    })()
    if (form.scheduleDay > days + 1) {
      return {success: false, errorMessage: 'Impossible day interval'}
    }
    return {success: true}
  }
}

export type Weekday = 1 | 2 | 3 | 4 | 5 | 6 | 7

export const WeekdayDescriptionMap: ReadonlyMap<Weekday, string> = new Map([
  [1, 'Mon'],
  [2, 'Tue'],
  [3, 'Wed'],
  [4, 'Thu'],
  [5, 'Fri'],
  [6, 'Sat'],
  [7, 'Sun'],
])

export const WeekdayStringShortMap: ReadonlyMap<Weekday, string> = new Map([
  [1, 'Mon'],
  [2, 'Tue'],
  [3, 'Wed'],
  [4, 'Thu'],
  [5, 'Fri'],
  [6, 'Sat'],
  [7, 'Sun'],
])

export class ScheduleWeekdaysHelper {
  static splitWeekdays(weekdays: number): Weekday[] {
    const doSplit = (value: number, acc: Weekday[] = []): Weekday[] => {
      if (value === 0) return acc
      const remainder = value % 10
      const quotient = Math.floor(value / 10)
      if (this.isValidWeekday(remainder)) {
        return doSplit(quotient, [remainder, ...acc])
      }
      return doSplit(quotient, acc)
    }
    return doSplit(weekdays)
  }

  static joinWeekdays(weekdays: Weekday[]): number {
    return uniq(weekdays)
      .sort((a, b) => a - b)
      .reduce((acc, value) => acc * 10 + value, 0)
  }

  static getWeekdaysString(weekdays: number): string[] {
    return this.splitWeekdays(weekdays).map((weekday) => this.getWeekdayString(weekday))
  }

  static getWeekdayString(weekday: number): string {
    return WeekdayDescriptionMap.get(weekday as Weekday) ?? ''
  }

  static getWeekdayStringShort(weekday: number): string {
    return WeekdayStringShortMap.get(weekday as Weekday) ?? ''
  }

  static isValidWeekday(weekday: number): weekday is Weekday {
    return weekday >= 1 && weekday <= 7
  }
}

export class ScheduleTimeHelper {
  static HOUR_REGEX = /^(0?[0-9]|1[0-9]|2[0-3])$/
  static MINUTE_REGEX = /^(0?[0-9]|[1-5][0-9])$/

  static format(hours: number, minutes: number) {
    return `${this.pad(hours)}:${this.pad(minutes)}`
  }

  static formatString(hours: string, minutes: string) {
    return `${this.padString(hours)}:${this.padString(minutes)}`
  }

  static pad(value: number): string {
    return padStart(value.toString(), 2, '0')
  }

  static padString(value: string): string {
    return padStart(value, 2, '0')
  }

  static getHoursFromTotalSeconds(totalSeconds: number): number {
    return Math.floor(totalSeconds / 3600)
  }

  static getMinutesFromTotalSeconds(totalSeconds: number): number {
    return Math.floor((totalSeconds % 3600) / 60)
  }

  static getHoursAndMinutesFromTotalSeconds(totalSeconds: number): {hours: number; minutes: number} {
    return {
      hours: this.getHoursFromTotalSeconds(totalSeconds),
      minutes: this.getMinutesFromTotalSeconds(totalSeconds),
    }
  }

  static getTotalSecondsFromHoursAndMinutes(hours: number, minutes: number): number {
    return hours * 3600 + minutes * 60
  }

  static getTotalSecondsFromHoursAndMinutesString(hours: string, minutes: string): number {
    return this.getTotalSecondsFromHoursAndMinutes(parseInt(hours), parseInt(minutes))
  }
}

export const ScheduleFormValidationErrorMessages = {
  required: (field: string) => `${field} is required`,
  shouldBeNumber: (field: string) => `${field} must be a valid number`,
  shouldBeNonNegativeNumber: (field: string) => `${field} must be a non-negative number`,
  shouldBePositiveNumber: (field: string) => `${field} must be a positive number`,
  shouldBeGreaterThan: (fieldA: string, fieldB: string) => `${fieldA} must be greater than ${fieldB}`,
  shouldBeGreaterThanOrEqual: (fieldA: string, fieldB: string) =>
    `${fieldA} must be greater than or equal to ${fieldB}`,
  shouldBeLessThan: (fieldA: string, fieldB: string) => `${fieldA} must be less than ${fieldB}`,
  shouldBeLessThanOrEqual: (fieldA: string, fieldB: string) => `${fieldA} must be less than or equal to ${fieldB}`,
  timeInvalid: (field: string) => `${field} must be a valid time`,
  hourInvalid: (field: string) => `${field} must be between 0 and 23`,
  minuteInvalid: (field: string) => `${field} must be between 0 and 59`,
  durationHourInvalid: (field: string) => `${field} must be greater or equal to 0`,
  durationMinuteInvalid: (field: string) => `${field} must be between 0 and 59`,
  impossibleEveryXDays: (field: string) => `${field} must not be greater than the day interval`,
  impossibleEmaInterval: (field: string) => `${field} must not be greater than the time interval`,
  impossibleEmaMinimumGap: (field: string) => `${field} must not be greater than the time interval between ocurrences`,
  impossibleEmaFlexibilityWindow: (field: string) => `${field} must not be greater than half of the time interval`,
  emaFlexibilityWindowEqualsToFixedEma: (field: string) =>
    `${field} settings equal to fixed ema settings, use fixed ema instead`,
  emaFlexibilityWindowEqualsToFullyRandomizedEma: (field: string) =>
    `${field} settings equal to fully randomized ema settings, use fully randomized ema instead`,
  noWeekdays: (field: string) => `${field} must have at least one weekday selected`,
}
