import {useMount} from 'react-use'
import {useEffect, useState} from 'react'
import {isEmpty} from 'lodash'

import {darken} from '../../lib'
import {
  ITaskSchedule,
  TaskScheduleDateType,
  TaskScheduleTaskSubTypeRecurring,
  TaskScheduleTaskType,
} from '../../shared/db'
import {
  ScheduleModifier,
  ScheduleFormHelper,
  ScheduleFormScheduleSubTypeFixedSchedule,
  ScheduleFormScheduleType,
  TempTaskSchedule,
} from '../../model/schedule'
import {TaskScheduleTypeSelectionCard} from './task_schedule_type_selection_card'
import {TaskScheduleSectionHeader} from './task_schedule_section_header'
import {TaskScheduleSettingsOneTimeOnlyFixedScheduleRelative} from './task_schedule_settings_one_time_only_fixed_schedule'
import {TaskScheduleSettingsOneTimeOnlyFullyRandomizedEMARelative} from './task_schedule_settings_one_time_only_fully_randomized_ema'
import {TaskScheduleSettingsRecurringFixedSchedule} from './task_schedule_settings_recurring_fixed_schedule'
import {TaskScheduleSettingsRecurringFixedEMA} from './task_schedule_settings_recurring_fixed_ema'
import {TaskScheduleSettingsRecurringSemiRandomizedEMA} from './task_schedule_settings_recurring_semi_randomized_ema'
import {TaskScheduleSettingsRecurringFullyRandomizedEMA} from './task_schedule_settings_recurring_fully_randomized_ema'
import {TaskScheduleSettingsRecurringDailyRelative} from './task_schedule_settings_recurring_daily'
import {TaskScheduleSettingsRecurringSpecificDaysOfTheWeekRelative} from './task_schedule_settings_recurring_specific_days_of_the_week'
import {TaskScheduleSettingsRecurringEveryXDaysRelative} from './task_schedule_settings_recurring_every_x_days'
import {TaskScheduleSettingsRecurringMultipleTimesInOneDayRelative} from './task_schedule_settings_recurring_multiple_times_in_one_day'
import {selectTheme} from '../../store'
import {CloseNewIcon} from '../../asset/image'

export interface TaskScheduleEditPopProps {
  schedule: TempTaskSchedule
  isEdit?: boolean
  onClickSave: (schedule: TempTaskSchedule) => void
  onClickCancel: () => void
}

// TODO: implement absolute date switch
export const TaskScheduleEditPop = (props: TaskScheduleEditPopProps) => {
  const {isEdit, onClickSave, onClickCancel} = props

  const {fontWeight, fontSize} = selectTheme()

  const [schedule, setSchedule] = useState(props.schedule)
  const [taskType, setTaskType] = useState(TaskScheduleTaskType.OneTimeOnly)
  const [recurringTaskSubType, setRecurringTaskSubType] = useState(TaskScheduleTaskSubTypeRecurring.Daily)
  const [scheduleType, setScheduleType] = useState(ScheduleFormScheduleType.FixedSchedule)
  const [fixedScheduleSubType, setFixedScheduleSubType] = useState(
    ScheduleFormScheduleSubTypeFixedSchedule.SpecificTime,
  )
  const [scheduleDateType, setScheduleDateType] = useState(TaskScheduleDateType.Relative)
  const [validValues, setValidValues] = useState<TempTaskSchedule['values'] | null>(null)

  const {color} = selectTheme()

  const onError = () => {
    setValidValues(null)
  }

  const onSubmit = () => {
    if (!isEmpty(validValues)) {
      onClickSave({
        ...schedule,
        values: validValues,
      })
    }
  }

  const updateValues = (updates: Partial<ITaskSchedule>) => {
    setSchedule({
      ...schedule,
      values: {
        ...schedule.values,
        ...updates,
      },
    })
  }

  useMount(() => {
    const {values} = props.schedule
    if (!isEmpty(values)) {
      const result = ScheduleFormHelper.fromSchedule(values).softTryParse()
      if (result.success) {
        const {value: parsed} = result

        setTaskType(parsed.taskType)
        setScheduleType(parsed.scheduleType)
        // TODO: accept absolute
        // setScheduleDateType(result.dateType)
        setScheduleDateType(TaskScheduleDateType.Relative)
        if ('taskSubType' in parsed) setRecurringTaskSubType(parsed.taskSubType)
        if ('scheduleSubType' in parsed) setFixedScheduleSubType(parsed.scheduleSubType)
      } else {
        console.error(result.error)
      }
    }
  })

  // coerce schedule types to applicable ones, ensuring options always selected
  useEffect(() => {
    if (taskType === TaskScheduleTaskType.OneTimeOnly) {
      if (scheduleType === ScheduleFormScheduleType.SemiRandomizedEMA) {
        setScheduleType(ScheduleFormScheduleType.FullyRandomizedEMA)
      }
    } else if (taskType === TaskScheduleTaskType.Recurring) {
      if (recurringTaskSubType === TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay) {
        if (
          scheduleType === ScheduleFormScheduleType.FixedSchedule &&
          fixedScheduleSubType === ScheduleFormScheduleSubTypeFixedSchedule.SpecificTime
        ) {
          setFixedScheduleSubType(ScheduleFormScheduleSubTypeFixedSchedule.Interval)
        }
      }
    }
  }, [taskType, recurringTaskSubType])

  useEffect(() => {
    const {values} = schedule
    const originalScheduleType = ScheduleFormHelper.toScheduleType(scheduleType, fixedScheduleSubType)
    try {
      const modifiedValues = new ScheduleModifier(values)
        .updateType({taskType, dateType: scheduleDateType})
        .updateEmaType(originalScheduleType)
        .withOnlyValidKeys({
          taskType,
          taskSubType: recurringTaskSubType,
          scheduleType: originalScheduleType,
          dateType: scheduleDateType,
        })
        .withId(values.id)
        .withTaskId(values.taskId)
        .tryValidate()
        .modify()
      setValidValues(modifiedValues)
    } catch (error) {
      console.error(error)
      setValidValues(null)
    }
  }, [schedule])

  const titleAndTopControl = () => {
    return (
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          marginTop: 8,
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            css={{
              fontWeight: fontWeight.thick,
              fontSize: fontSize.h3,
            }}
          >
            Add Schedule
          </div>
          <img onClick={() => onClickCancel()} src={CloseNewIcon} width={20} height={20} />
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <span
              css={{
                wordWrap: 'break-word',
                fontWeight: fontWeight.medium,
                fontSize: fontSize.h6,
                color: color.textIcon.secondary,
              }}
            >
              Note: The task duration is currently determined by Participant's Joined Date.
            </span>
            {/** TODO: add ? button */}
          </div>
          {/** TODO: add switch to absolute button */}
        </div>
      </div>
    )
  }

  const taskTypeSelectionSection = () => {
    return (
      <div
        css={{
          display: 'flex',
          gap: 8,
        }}
      >
        <TaskScheduleTypeSelectionCard
          title='One-Time Only'
          description="The task occurs just once, on a specific date or day relative to the participant's start date."
          selected={taskType === TaskScheduleTaskType.OneTimeOnly}
          onSelection={() => setTaskType(TaskScheduleTaskType.OneTimeOnly)}
        />
        <TaskScheduleTypeSelectionCard
          title='Recurring'
          description='The task repeats on a regular schedule, following the specified recurrence pattern.'
          selected={taskType === TaskScheduleTaskType.Recurring}
          onSelection={() => setTaskType(TaskScheduleTaskType.Recurring)}
        />
      </div>
    )
  }

  const radioButton = (text: string, checked: boolean, onChange: () => void) => {
    return (
      <label css={{cursor: 'pointer'}}>
        <input type='radio' checked={checked} onChange={onChange} />
        <span css={{marginLeft: 8}}>{text}</span>
      </label>
    )
  }

  const recurringSubTypeSelectionSection = () => {
    const subTypeRadioButton = (subType: TaskScheduleTaskSubTypeRecurring, text: string) => {
      return radioButton(text, recurringTaskSubType === subType, () => setRecurringTaskSubType(subType))
    }

    return (
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {subTypeRadioButton(TaskScheduleTaskSubTypeRecurring.Daily, 'Daily')}
        {subTypeRadioButton(TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek, 'Specific day(s) of the week')}
        {subTypeRadioButton(TaskScheduleTaskSubTypeRecurring.EveryXDays, 'Every x days')}
        {subTypeRadioButton(TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay, 'Multiple times in one day')}
      </div>
    )
  }

  const recurringSubTypeSection = () => {
    switch (recurringTaskSubType) {
      case TaskScheduleTaskSubTypeRecurring.Daily:
        return (
          <TaskScheduleSettingsRecurringDailyRelative
            {...{
              values: {
                startDay: schedule.values.relativeStartDayOrYymmdd,
                endDay: schedule.values.relativeEndDayOrYymmdd,
              },
              onValidated: ({startDay, endDay}) =>
                updateValues({relativeStartDayOrYymmdd: startDay, relativeEndDayOrYymmdd: endDay}),
              onError,
            }}
          />
        )
      case TaskScheduleTaskSubTypeRecurring.SpecificDaysOfTheWeek:
        return (
          <TaskScheduleSettingsRecurringSpecificDaysOfTheWeekRelative
            {...{
              values: {
                startDay: schedule.values.relativeStartDayOrYymmdd,
                endDay: schedule.values.relativeEndDayOrYymmdd,
                weekdays: schedule.values.scheduleWeekdays,
              },
              onValidated: ({startDay, endDay, weekdays}) =>
                updateValues({
                  relativeStartDayOrYymmdd: startDay,
                  relativeEndDayOrYymmdd: endDay,
                  scheduleWeekdays: weekdays,
                }),
              onError,
            }}
          />
        )
      case TaskScheduleTaskSubTypeRecurring.EveryXDays:
        return (
          <TaskScheduleSettingsRecurringEveryXDaysRelative
            {...{
              values: {
                startDay: schedule.values.relativeStartDayOrYymmdd,
                endDay: schedule.values.relativeEndDayOrYymmdd,
                everyXDays: schedule.values.scheduleDay,
              },
              onValidated: ({startDay, endDay, everyXDays}) =>
                updateValues({
                  relativeStartDayOrYymmdd: startDay,
                  relativeEndDayOrYymmdd: endDay,
                  scheduleDay: everyXDays,
                }),
              onError,
            }}
          />
        )
      case TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay:
        return (
          <TaskScheduleSettingsRecurringMultipleTimesInOneDayRelative
            {...{
              values: {day: schedule.values.scheduleDay},
              onValidated: ({day}) => updateValues({scheduleDay: day}),
              onError,
            }}
          />
        )
    }
  }

  const scheduleTypeSelectionSection = () => {
    return (
      <div
        css={{
          display: 'flex',
          gap: 8,
        }}
      >
        <TaskScheduleTypeSelectionCard
          title='Fixed Schedule'
          description='The task is scheduled to occur at specific, consistent times each day, ensuring a predictable routine.'
          selected={scheduleType === ScheduleFormScheduleType.FixedSchedule}
          onSelection={() => setScheduleType(ScheduleFormScheduleType.FixedSchedule)}
        />
        {taskType === TaskScheduleTaskType.Recurring && (
          <TaskScheduleTypeSelectionCard
            title='Semi Randomized'
            description='The task appears around specific intervals, e.g., every 2 hours with some flexibility.'
            selected={scheduleType === ScheduleFormScheduleType.SemiRandomizedEMA}
            onSelection={() => setScheduleType(ScheduleFormScheduleType.SemiRandomizedEMA)}
          />
        )}
        <TaskScheduleTypeSelectionCard
          title='Fully Randomized'
          description='The task appears at completely random times, but you can set a minimum interval between occurrences.'
          selected={scheduleType === ScheduleFormScheduleType.FullyRandomizedEMA}
          onSelection={() => setScheduleType(ScheduleFormScheduleType.FullyRandomizedEMA)}
        />
      </div>
    )
  }

  const recurringFixedScheduleSubTypeSelection = () => {
    const subTypeRadioButton = (subType: ScheduleFormScheduleSubTypeFixedSchedule, text: string) => {
      return radioButton(text, fixedScheduleSubType === subType, () => setFixedScheduleSubType(subType))
    }

    return (
      <div css={{display: 'flex', flexDirection: 'column', gap: 24}}>
        {recurringTaskSubType !== TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay && (
          <div
            css={{
              display: 'inline-flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 12,
              width: 'fit-content',
            }}
          >
            <span css={{fontWeight: fontWeight.medium, fontSize: fontSize.h6}}>Set by: </span>
            {subTypeRadioButton(ScheduleFormScheduleSubTypeFixedSchedule.SpecificTime, 'Specific time')}
            {subTypeRadioButton(ScheduleFormScheduleSubTypeFixedSchedule.Interval, 'Interval')}
          </div>
        )}
        {fixedScheduleSubType === ScheduleFormScheduleSubTypeFixedSchedule.SpecificTime &&
          recurringTaskSubType !== TaskScheduleTaskSubTypeRecurring.MultipleTimesInOneDay && (
            <TaskScheduleSettingsRecurringFixedSchedule
              {...{
                values: {
                  startTime: schedule.values.startTime,
                  endTime: schedule.values.endTime,
                },
                onValidated: ({startTime, endTime}) => updateValues({startTime, endTime}),
                onError,
              }}
            />
          )}
        {fixedScheduleSubType === ScheduleFormScheduleSubTypeFixedSchedule.Interval && (
          <TaskScheduleSettingsRecurringFixedEMA
            {...{
              values: {
                startTime: schedule.values.startTime,
                endTime: schedule.values.endTime,
                emaIntervalSeconds: schedule.values.emaIntervalSeconds,
                emaExpirationSeconds: schedule.values.emaExpirationSeconds,
              },
              onValidated: ({startTime, endTime, emaIntervalSeconds, emaExpirationSeconds}) =>
                updateValues({startTime, endTime, emaIntervalSeconds, emaExpirationSeconds}),
              onError,
            }}
          />
        )}
      </div>
    )
  }

  const scheduleTypeSection = () => {
    switch (taskType) {
      case TaskScheduleTaskType.OneTimeOnly:
        switch (scheduleType) {
          case ScheduleFormScheduleType.FixedSchedule:
            return (
              <TaskScheduleSettingsOneTimeOnlyFixedScheduleRelative
                {...{
                  values: {
                    startDay: schedule.values.relativeStartDayOrYymmdd,
                    startTime: schedule.values.startTime,
                    endDay: schedule.values.relativeEndDayOrYymmdd,
                    endTime: schedule.values.endTime,
                  },
                  onValidated: ({startDay, startTime, endDay, endTime}) =>
                    updateValues({
                      relativeStartDayOrYymmdd: startDay,
                      startTime,
                      relativeEndDayOrYymmdd: endDay,
                      endTime,
                    }),
                  onError,
                }}
              />
            )
          case ScheduleFormScheduleType.FullyRandomizedEMA:
            return (
              <TaskScheduleSettingsOneTimeOnlyFullyRandomizedEMARelative
                {...{
                  values: {
                    day: schedule.values.scheduleDay,
                    startTime: schedule.values.startTime,
                    endTime: schedule.values.endTime,
                    emaExpirationSeconds: schedule.values.emaExpirationSeconds,
                  },
                  onValidated: ({day, startTime, endTime, emaExpirationSeconds}) =>
                    updateValues({
                      scheduleDay: day,
                      startTime,
                      endTime,
                      emaExpirationSeconds,
                    }),
                  onError,
                }}
              />
            )
          default:
            // selection not applicable
            return <div></div>
        }
      case TaskScheduleTaskType.Recurring:
        switch (scheduleType) {
          case ScheduleFormScheduleType.FixedSchedule:
            return recurringFixedScheduleSubTypeSelection()
          case ScheduleFormScheduleType.SemiRandomizedEMA:
            return (
              <TaskScheduleSettingsRecurringSemiRandomizedEMA
                {...{
                  values: {
                    startTime: schedule.values.startTime,
                    endTime: schedule.values.endTime,
                    emaIntervalSeconds: schedule.values.emaIntervalSeconds,
                    emaMinimumGapSeconds: schedule.values.emaMinimumGapSeconds,
                    emaExpirationSeconds: schedule.values.emaExpirationSeconds,
                  },
                  onValidated: ({startTime, endTime, emaIntervalSeconds, emaMinimumGapSeconds, emaExpirationSeconds}) =>
                    updateValues({
                      startTime,
                      endTime,
                      emaIntervalSeconds,
                      emaMinimumGapSeconds,
                      emaExpirationSeconds,
                    }),
                  onError,
                }}
              />
            )
          case ScheduleFormScheduleType.FullyRandomizedEMA:
            return (
              <TaskScheduleSettingsRecurringFullyRandomizedEMA
                {...{
                  values: {
                    startTime: schedule.values.startTime,
                    endTime: schedule.values.endTime,
                    emaOcurrences: schedule.values.emaOcurrences,
                    emaMinimumGapSeconds: schedule.values.emaMinimumGapSeconds,
                    emaExpirationSeconds: schedule.values.emaExpirationSeconds,
                  },
                  onValidated: ({startTime, endTime, emaOcurrences, emaMinimumGapSeconds, emaExpirationSeconds}) =>
                    updateValues({
                      startTime,
                      endTime,
                      emaOcurrences,
                      emaMinimumGapSeconds,
                      emaExpirationSeconds,
                    }),
                  onError,
                }}
              />
            )
        }
    }
  }

  return (
    <div
      id='editPopBackgroundId'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto',
        top: 0,
        left: 0,
        zIndex: 103,
      }}
    >
      <div
        css={{
          width: '60%',
          maxHeight: '90%',
          background: color.white,
          borderRadius: '5px',
          boxShadow: '0px 4px 10px 0px #0000001A',
          overflowY: 'auto',
          margin: '0 auto',
        }}
      >
        <div css={{display: 'flex', flexDirection: 'column', gap: 24, padding: 16}}>
          {titleAndTopControl()}
          <div css={{display: 'flex', flexDirection: 'column', gap: 48}}>
            <div css={{display: 'flex', flexDirection: 'column', gap: 16}}>
              <TaskScheduleSectionHeader
                title='Task Type'
                description='Choose whether this task occurs only once or recurs over time.'
              />
              {taskTypeSelectionSection()}
              {taskType === TaskScheduleTaskType.Recurring && (
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                    borderRadius: 8,
                    backgroundColor: color.surface.default,
                    padding: 16,
                  }}
                >
                  {recurringSubTypeSelectionSection()}
                  {recurringSubTypeSection()}
                </div>
              )}
            </div>
            <div css={{display: 'flex', flexDirection: 'column', gap: 16}}>
              <TaskScheduleSectionHeader
                title='Schedule Type'
                description='Choose how the task should appear within the selected time range.'
              />
              {scheduleTypeSelectionSection()}
              {/** TODO: implement ema sample graph */}
              <div css={{borderRadius: 8, backgroundColor: color.surface.default, padding: 16}}>
                {scheduleTypeSection()}
              </div>
            </div>
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 8,
            backgroundColor: color.white,
            boxShadow: '0px 4px 10px 0px #0000001A',
            padding: '16px 24px',
            marginTop: 8,
          }}
        >
          <button
            onClick={() => onClickCancel()}
            css={{
              padding: '8px 16px',
              borderRadius: 4,
              border: 'none',
              backgroundColor: color.surface.default,
              color: color.textIcon.secondary,
              cursor: 'pointer',
              fontSize: fontSize.h6,
              fontWeight: fontWeight.thick,
              '&:hover': {
                backgroundColor: darken(color.surface.default, 10),
              },
              '&:active': {
                backgroundColor: darken(color.surface.default, 20),
              },
              transition: 'all 0.2s ease-in-out',
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => onSubmit()}
            css={{
              padding: '8px 16px',
              borderRadius: 4,
              border: 'none',
              backgroundColor: color.state.default,
              color: color.white,
              cursor: 'pointer',
              fontSize: fontSize.h6,
              fontWeight: fontWeight.thick,
              '&:hover': {
                backgroundColor: validValues ? darken(color.state.default, 10) : color.state.disabled,
              },
              '&:active': {
                backgroundColor: validValues ? darken(color.state.default, 20) : color.state.disabled,
              },
              '&:disabled': {
                backgroundColor: color.state.disabled,
                cursor: 'not-allowed',
              },
              transition: 'all 0.2s ease-in-out',
            }}
            disabled={!validValues}
          >
            {isEdit ? 'Update Schedule' : 'Add Schedule'}
          </button>
        </div>
      </div>
    </div>
  )
}
