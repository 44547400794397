import {selectTheme} from '../../store'

export interface TaskScheduleSectionHeaderProps {
  title: string
  description: string
}

export const TaskScheduleSectionHeader = (props: TaskScheduleSectionHeaderProps) => {
  const {title, description} = props

  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      <div
        css={{
          fontSize: fontSize.h5,
          fontWeight: fontWeight.bold,
        }}
      >
        {title}
      </div>
      <div
        css={{
          fontSize: fontSize.h6,
          fontWeight: fontWeight.medium,
          color: color.textIcon.secondary,
        }}
      >
        {description}
      </div>
    </div>
  )
}
