import {
  RIF,
  _,
} from '../../lib'
import {
  selectTheme, 
} from '../../store'
import {
  Question,
} from '../../model'
import {QuestionnaireInput, QuestionnaireInputSize, ButtonText} from '..'
import {CancelBlackIcon} from '../../asset/image'

interface QuestionnaireOptionProps {
  isEditing: boolean
  index: number
  option?: {
    name: string
  }
  editingQuestion: Question
  setEditingQuestion: (arg: Question) => void
  isOtherOption: boolean
}

export const QuestionnaireOption = (props: QuestionnaireOptionProps) => {
  const {pad, color, fontWeight} = selectTheme()
  const {
    isEditing, 
    index, 
    option, 
    editingQuestion, 
    setEditingQuestion,
    isOtherOption
  } = props

  const handleChangeOfOption = (value: string) => {
    const questionCopy = JSON.parse(JSON.stringify(editingQuestion))
    questionCopy.inputConfig.options[index].name = value
    console.log(questionCopy)
    setEditingQuestion(questionCopy)
  }

  const removeOption = (i: number) => {
    const questionCopy = JSON.parse(JSON.stringify(editingQuestion))
    if (isOtherOption) {
      questionCopy.inputConfig.allowOther = false
    } else {
      questionCopy.inputConfig.options.splice(i, 1)
    }
    console.log(questionCopy)
    setEditingQuestion(questionCopy)
  }

  return (
    <div
      key={index}
      css={{
        display: 'flex',
        alignItems: 'center',
        margin: `${pad.large} 0 0 2rem`,
        height: '43px',
      }}
    >
      <div
        css={{
          width: pad.slightlyLarger,
          height: pad.slightlyLarger,
          borderRadius: editingQuestion?.type === 'radio' ? '50%' : '0',
          border: `1px solid ${color.grey_400}`,
        }}
      />
      {RIF(
        isEditing,
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div css={{
            margin: `0 ${pad.mediumSmall}`
          }}>
            {RIF(
              !isOtherOption,
              <QuestionnaireInput
                size={QuestionnaireInputSize.small}
                defaultValue={option?.name}
                onChangeAction={handleChangeOfOption}
                placeholder={'New Option'}
                initialValue={'New Option'}
                maxLength={250}
              />
            )}
            {RIF(
              isOtherOption,
              <div
                css={{
                  height: '42px',
                  width: QuestionnaireInputSize.small,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'baseline',
                }}
              >
                <p
                  css={{
                    paddingLeft: '16px',
                    marginTop: 'auto',
                    color: "#60647D",
                    fontSize: '14px',
                    fontStyle: 'italic',
                    fontWeight: fontWeight.thick
                  }}>
                  Other
                </p>
                <div
                  css={{
                    height: '1px',
                    width: '100%',
                    background: 'repeating-linear-gradient(90deg,#9A9BA2 0 5px,#0000 0 7px)',
                    marginTop: 'auto'
                  }}
                ></div>
              </div>
            )}
          </div>
          {RIF(
            editingQuestion?.inputConfig.options?.length,
            <ButtonText onClick={() => removeOption(index)} children={<img src={CancelBlackIcon} width='12' />} />,
          )}
        </div>,
      )}
      {RIF(!isEditing, <div css={{marginLeft: '10px'}}>{
        isOtherOption ? "Other" : option?.name
      }</div>)}
    </div>
  )
}
